import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { BookingClient } from '../../../../models/booking/booking';
import { UsersService } from 'src/app/shared/api-services/users.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as RoutesUrl from 'src/app/shared/constant/app-routes';

@Component({
  selector: 'dynamic-table-mobile-bookings',
  templateUrl: './dynamic-table-mobile-bookings.component.html',
  styleUrls: ['./dynamic-table-mobile-bookings.component.scss']
})
export class DynamicTableMobileBookingsComponent {
  tenantId = this._tenatService.getTenantId();

  routesUrl = RoutesUrl;

  @Input() bookings: BookingClient[] = [];

  @Input() totalItems!: number;
  @Input() itemsPerPage!: number;
  @Input() maxPagesToShowPagination: number = 3;

  @Output() pageChanged = new EventEmitter<number>();

  currentPage!: number;

  constructor(
    private _tenatService: TenantService,
    private _usersService: UsersService,
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['bookings'] && changes['bookings'].currentValue) {
      this.setUserImages();
    }
  }

  private setUserImages() {
    if (this.bookings && this.bookings.length > 0) {
      this.bookings.forEach(booking => {
        if (booking.user) {
          booking.user.srcImage$ = this._usersService.getUserImage$(this.tenantId, booking.user.id, 20, 20);
        }


        if (booking.insertedByUser) {
          booking.insertedByUser.srcImage$ = this._usersService.getUserImage$(this.tenantId, booking.user.id, 20, 20);
        }
      });
    }
  }
}
