<div class="card-permission-modal">
    <header class="card-permission-modal-header">
        @if (this.modalStatus.isCreate) {
            <h4 class="card-permission-modal-title">
                {{ 'USER_GROUPS.PERMISSIONS.MODAL.NEW' | translate }}
            </h4>
        }
        @if (this.modalStatus.isPatch) {
            <h4 class="card-permission-modal-title">
                {{ 'USER_GROUPS.PERMISSIONS.MODAL.EDIT' | translate }}
            </h4>
        }
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content>
        <form [formGroup]="permissionForm">

            <div class="container-main-fields d-flex gap-3 flex-column">

                @if (this.showFilterPermission) {
                    <div class="form-group mb-2">
                        <label for="name" class="form-label">{{ 'USER_GROUPS.PERMISSIONS.MODAL.PERMISSION_FILTER_NAME_LABEL' | translate }}</label>
                        <dropdown-primary
                            class="w-100"
                            [selectedOption]="optionFilterPermissionSelected"
                            [options]="listFilterPermissionOptions"
                            (selectedOptionChange)="onSelectedFilter($event)"
                            [emitNgOnInit]="false"
                            [activeScrollbar]="true"
                            [iconClass]="'bi bi-chevron-down'"
                            [cssClass]="'btn-dropdown'">
                        </dropdown-primary>
                    </div>
                }

                <div class="form-group mb-2">
                    <label for="name" class="form-label">{{ 'USER_GROUPS.PERMISSIONS.MODAL.PERMISSION_NAME_LABEL' | translate }}</label>
                    <dropdown-primary-permission 
                        [permissionName]="permissionForm?.value?.name"
                        [permissionFilterName]="optionFilterPermissionSelected?.value"
                        [isDisabled]="this.modalStatus.isPatch"
                        (onSelectedEvent)="onPermissionSelected($event)">
                    </dropdown-primary-permission>
                    @if (permissionForm?.value?.name) {
                        <span class="form-text text-muted">
                            {{ permissionForm?.value?.name + '_DESCRIPTION' | translate }}
                        </span>
                    }
                </div>

                @if (this.showTypeSection) {
                    <permission-type-section-modal 
                        [permissionName]="permissionForm?.value?.name" 
                        [permission]="permission" 
                        (exprChange)="onMainSectionExprChange($event)">
                    </permission-type-section-modal>
                }

                @if (this.showTaskSection) {
                    <permission-task-section-modal 
                        [permissionName]="permissionForm?.value?.name" 
                        [permission]="permission" 
                        (exprChange)="onMainSectionExprChange($event)">
                    </permission-task-section-modal>
                }

                @if (this.showAssetSection) {
                    <permission-asset-section-modal 
                        [permissionName]="permissionForm?.value?.name" 
                        [permission]="permission" 
                        (exprChange)="onMainSectionExprChange($event)">
                    </permission-asset-section-modal>
                }

                @if (this.showBookingSection) {
                    <permission-booking-section-modal 
                        [permissionName]="permissionForm?.value?.name" 
                        [permission]="permission" 
                        (exprChange)="onMainSectionExprChange($event)">
                    </permission-booking-section-modal>
                }

                @if (this.showVehicleSection) {
                    <permission-vehicle-section-modal 
                        [permissionName]="permissionForm?.value?.name" 
                        [permission]="permission" 
                        (exprChange)="onMainSectionExprChange($event)">
                    </permission-vehicle-section-modal>
                }

                @if (this.showUserSection) {
                    <permission-user-section-modal 
                        [permissionName]="permissionForm?.value?.name" 
                        [permission]="permission" 
                        (exprChange)="onMainSectionExprChange($event)">
                    </permission-user-section-modal>
                }
                
                <div class="form-group">
                    <label for="description" class="form-label">{{ 'USER_GROUPS.PERMISSIONS.MODAL.DESCRIPTION_LABEL' | translate }}</label>
                    <textarea type="text" class="form-control" id="description" formControlName="description" aria-describedby="description-help"></textarea>
                    <span class="form-text text-muted">
                        {{ 'USER_GROUPS.PERMISSIONS.MODAL.DESCRIPTION_HELP' | translate }}
                    </span>
                </div>

                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="advanced" formControlName="advanced">
                    <label for="advanced" class="form-label m-0 p-0">{{ 'USER_GROUPS.PERMISSIONS.MODAL.ADVANCED_LABEL' | translate }}</label>
                </div>                    

                @if (this.permissionForm.value.advanced) {
                    <div class="form-group">
                        <label for="expr" class="form-label">{{ 'USER_GROUPS.PERMISSIONS.MODAL.EXPR_LABEL' | translate }}</label>
                        <textarea type="text" class="form-control" id="expr" formControlName="expr" aria-describedby="expr-help"></textarea>
                        <span class="form-text color-danger">
                            {{ 'USER_GROUPS.PERMISSIONS.MODAL.EXPR_HELP' | translate }}
                        </span>
                    </div>
                }

            </div>

            
        </form>
    </content>

    <footer class="d-flex gap-3 justify-content-center mt-4">
        <button type="button" class="btn-gs-secondary-light" (click)="this.bsModalRef.hide()">
            {{ 'ACTIONS.CANCEL' | translate }}
        </button>

        <button type="submit" class="btn-gs-primary" (click)="onSubmit()">
            {{ 'ACTIONS.SAVE' | translate }}
        </button>     
    </footer>
</div>