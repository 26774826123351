import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Vehicle } from 'src/app/shared/models/vehicle/vehicle';
import { VehicleRequest } from 'src/app/shared/models/vehicle/vehicle-request';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { DateUtilsService } from 'src/app/shared/utilities/date-utils.service';

@Component({
  selector: 'vehicle-suspension-program-modal',
  templateUrl: './vehicle-suspension-program-modal.component.html',
  styleUrls: ['./vehicle-suspension-program-modal.component.scss']
})
export class VehicleSuspensionProgramModalComponent {

  tenantId = this._tenantService.getTenantId();
  @Input() vehicle: Vehicle;
  vehicleRequest?: VehicleRequest;

  form: FormGroup;

  constructor(
    public bsModalRef: BsModalRef,
    private _tenantService: TenantService,
    private _vehiclesService: VehiclesService,
    private _dateUtilsService: DateUtilsService,
    private _communicationService: CommunicationService,
  ) {
    this.form = new FormGroup({
      disableFrom: new FormControl(null),
      disableFromTime: new FormControl(null),
      disableTo: new FormControl(null),
      disableToTime: new FormControl(null),
    });
  }

  ngOnInit(): void {
    this.setValues();
  }

  setValues() {
    if (this.vehicle) {
      let disableFrom = this.vehicle.disabledFrom ? new Date(this.vehicle.disabledFrom) : null;
      let disabledFromTime = disableFrom ? this._dateUtilsService.parseDateToTimeString(disableFrom) : null;
      let disableTo = this.vehicle.disabledTo ? new Date(this.vehicle.disabledTo) : null;
      let disabledToTime = disableTo ? this._dateUtilsService.parseDateToTimeString(disableTo) : null;

      this.form.patchValue({
        disableFrom: disableFrom,
        disableFromTime: disabledFromTime,
        disableTo: disableTo,
        disableToTime: disabledToTime,
      });
    }
  }

  onSubmit() {
    this.vehicleRequest = this._vehiclesService.mapVehicleToRequest(this.vehicle);

    if (this.form.valid) {
      if (this.form.value.disableFrom && this.form.value.disableFromTime) {
        let disableFrom = this._dateUtilsService.combineDateAndTime(this.form.value.disableFrom, this.form.value.disableFromTime);
        this.vehicleRequest.disabledFrom = disableFrom;
      } else {
        this.vehicleRequest.disabledFrom = null;
      }

      if (this.form.value.disableTo && this.form.value.disableToTime) {
        let disableTo = this._dateUtilsService.combineDateAndTime(this.form.value.disableTo, this.form.value.disableToTime);
        this.vehicleRequest.disabledTo = disableTo;
      } else {
        this.vehicleRequest.disabledTo = null;
      }

      this._vehiclesService.updateVehicle$(this.tenantId, this.vehicle.id, this.vehicleRequest).subscribe({
        next: (response) => {
          if (response) {
            this._communicationService.sendEvent();
            this.bsModalRef.hide();
          }
        }
      });
    }
  }
}
