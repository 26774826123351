import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DocumentResponse, StorageFile } from 'src/app/shared/models/storage';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';

@Component({
  selector: 'output-files',
  templateUrl: './output-files.component.html',
  styleUrls: ['./output-files.component.scss']
})
export class OutputFilesComponent {
  @Input() documents: DocumentResponse[];

  @Output() downloadDocument = new EventEmitter<StorageFile>();
  @Output() deleteDocument = new EventEmitter<StorageFile>();

  modalConfirmDelete?: BsModalRef;

  constructor(private _modalService: BsModalService) { }

  openModal(document: DocumentResponse) {
    this.modalConfirmDelete = this._modalService.show(ConfirmModalComponent, {
      class: 'modal-sm',
      animated: true,
      initialState: {
        data: {
          content: 'OUTPUT_UPLOAD_FILES.ALERT_DELETE_FILE'
        }
      }
    });

    this.modalConfirmDelete.content.onConfirm.subscribe((confirm: boolean) => {
      if (confirm) {
        this.deleteFile(document.id, document.name);
      }
    });
  }

  downloadFile(id: string, name: string) {
    this.downloadDocument.emit({ id, name });
  }

  deleteFile(id: string, name: string) {
    this.deleteDocument.emit({ id, name });
  }
}
