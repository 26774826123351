<div class="modal-gs p-3 d-flex flex-column gap-3">
    <header>
        <h4 class="modal-title fs-5 text-danger text-center">{{'MODAL_NO_PERMISSION.TITLE' | translate}}</h4>
    </header>

    <div class="modal-gs-body">
        <p class="text-center">{{'MODAL_NO_PERMISSION.DESCRIPTION' | translate}}</p>
    </div>

    <footer class="d-flex gap-2 align-items-center justify-content-center">
        <button type="button" class="btn-gs-danger" (click)="logout()">{{'LOGOUT' | translate}}</button>
    </footer>
</div>