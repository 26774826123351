<div class="d-flex gap-3 flex-column">

    <div class="accordion">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUserGroups" aria-expanded="true" aria-controls="collapseUserGroups">
                    {{ this.userGroupsSwitch.titleSwitch | translate }}
                </button>
            </h2>
            <div id="collapseUserGroups" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <list-switches 
                        [listSwitches]="this.userGroupsSwitch.listSwitch" 
                        (listSwitchesChange)="onSwitchUserGroupsSelected($event)">
                    </list-switches>                
                </div>
            </div>
        </div>
    </div>
    
    <div class="accordion">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHeadquarters" aria-expanded="true" aria-controls="collapseHeadquarters">
                    {{ this.headquartersSwitch.titleSwitch | translate }}
                </button>
            </h2>
            <div id="collapseHeadquarters" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <list-switches 
                        [listSwitches]="this.headquartersSwitch.listSwitch" 
                        (listSwitchesChange)="onSwitchHeadquartersSelected($event)">
                    </list-switches>                
                </div>
            </div>
        </div>
    </div>
    
    <div class="accordion">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTags" aria-expanded="true" aria-controls="collapseTags">
                    {{ this.tagsSwitch.titleSwitch | translate }}
                </button>
            </h2>
            <div id="collapseTags" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <list-switches 
                        [listSwitches]="this.tagsSwitch.listSwitch" 
                        (listSwitchesChange)="onSwitchTagsSelected($event)">
                    </list-switches>                
                </div>
            </div>
        </div>
    </div>

</div>