import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StorageFile } from 'src/app/shared/models/storage';
import { StorageUtilsService } from 'src/app/shared/utilities/storage-utils.servic';

@Component({
  selector: 'input-upload-files',
  templateUrl: './input-upload-files.component.html',
  styleUrls: ['./input-upload-files.component.scss']
})
export class InputUploadFilesComponent {

  selectedFile: File | null = null;
  fileId: string;

  @Input() tenantId: string;
  @Input() bucketName: string;

  @Output() fileInfo = new EventEmitter<StorageFile>();

  constructor(
    private _storageUtilsService: StorageUtilsService
  ) { }

  uploadStorageFile(event?: Event, file?: File | null) {
    if (event instanceof Event) {
      const target = event.target as HTMLInputElement;
      this.selectedFile = target.files?.[0] || null;
    } else {
      this.selectedFile = file;
    }

    if (this.selectedFile) {
      this._storageUtilsService.uploadFile$(this.selectedFile, this.bucketName)
        .subscribe({
          next: (storageFileResponse) => {
            if (storageFileResponse && storageFileResponse.status === 200) {
              this.fileId = storageFileResponse.body.id;
              this.fileInfo.emit({ id: this.fileId, name: this.selectedFile.name });
            }
          },
          error: (error) => {
            console.error('Errore durante l\'upload del file:', error);
          }
        });
    }
  }

  handleDrop(event: DragEvent) {
    this.preventDefault(event);

    if (event.dataTransfer && event.dataTransfer.items && event.dataTransfer.items[0]) {
      const file = event.dataTransfer.items[0].getAsFile();
      if (file) {
        this.uploadStorageFile(null, file);
      }
    }
  }

  preventDefault(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }
}