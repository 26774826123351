<div
  class="col"
  id="accordionExample"
  [ngClass]="{ card: !isMobile, accordion: isMobile }"
>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button
        class="accordion-button title"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
      >
        {{ "BOOKING" | translate }}
      </button>
    </h2>

    <div
      [id]="{ collapseOne: !isMobile }"
      class="accordion-body collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#accordionExample"
    >
      <div class="container-booking-information">
        <table class="table">
          <tbody>
            <tr>
              <td><label for="status">{{ "STATUS" | translate }}</label></td>
              <td>
                <dropdown-primary
                  [emitNgOnInit]="false"
                  [isDisabled]="isDisableStatus || !isFleetManager"
                  [options]="listStatus"
                  [selectedOption]="optionStatusBooking"
                  (selectedOptionChange)="onBookingStatusChange($event)"
                  [cssClass]="
                    dropdownApprovationCssClass + ' border-0 custom-badge-bold'
                  "
                  [iconClass]="iconForStatus"
                >
                </dropdown-primary>
              </td>
            </tr>
            <tr>
              <td><label for="startDate">{{ "START_DATE" | translate }}</label></td>
              <td><span>{{ booking?.startDate | date : "d MMM yyyy HH:mm" }}</span></td>
            </tr>
            <tr>
              <td><label for="endDate">{{ "END_DATE" | translate }}</label></td>
              <td><span>{{ booking?.endDate | date : "d MMM yyyy HH:mm" }}</span></td>
            </tr>
            <tr>
              <td><label for="bookingType">{{ "TYPOLOGY" | translate }}</label></td>
              <td><span>{{ booking?.bookingType?.name }}</span></td>
            </tr>
            <tr>
              <td><label for="vehicle">{{ "VEHICLE" | translate }}</label></td>
              <td><span>{{ booking?.vehicle?.licensePlate }}</span></td>
            </tr>
            <tr *ngIf="booking?.expectedMileage">
              <td><label for="expectedMileage">{{ "MILEAGE" | translate }}</label></td>
              <td><span>{{ booking?.expectedMileage | number: '1.0-0' }} {{'BOOKINGS.MILEAGE_UNIT' | translate}}</span></td>
            </tr>
            <tr *ngIf="booking?.expectedTime">
              <td><label for="expectedTime">{{ "BOOKINGS.TIME_ESTIMATE" | translate }}</label></td>
              <td><span>{{ booking?.expectedTime }} {{ 'MINUTES' | translate | lowercase }}</span></td>
            </tr>
            <tr>
              <td><label for="driver">{{ "BOOKINGS.DRIVER" | translate }}</label></td>
              <td><span>{{ booking?.user?.displayName }}</span></td>
            </tr>
            <tr>
              <td><label for="inserted-by-user">{{ "BOOKINGS.INSERTED_BY_USER" | translate }}</label></td>
              <td><span>{{ booking?.insertedByUser?.displayName }}</span></td>
            </tr>
          </tbody>
        </table>
      </div>

      @if (booking?.stages?.length > 0) {
        <div class="container-stages my-3">
          <h3 class="title py-2">{{ "BOOKINGS.STAGES" | translate }}</h3>
          <table>
            <tr *ngFor="let stage of booking?.stages; let index = index">
              <td>{{ index + 1 }}. {{ stage }}</td>
            </tr>
          </table>
        </div>
      }

      <div class="container-location-information">
        <h3 class="title py-2">{{ "LOCATION.NAME" | translate }}</h3>

        <div class="input-group">
          <label for="headerquarter">{{
            "HEADQUARTER.NAME" | translate
          }}</label>
          <span>{{ booking?.headquarter?.name }}</span>
        </div>

        <div class="input-group">
          <label for="location">{{ "LOCATION.NAME" | translate }}</label>
          <span>{{ booking?.location?.name }}</span>
        </div>
      </div>

      <div class="container-booking-extrafields" *ngIf="extraFields">
        <h3 class="title py-2">{{ "INFORMATION" | translate }}</h3>

        <table>
          <tr *ngFor="let key of objectKeys(extraFields)">
            <td class="span">{{ key }}</td>
            <td>{{ extraFields[key] }}</td>
          </tr>
        </table>
      </div>

      <button
        class="btn-gs-primary-noborder mt-3"
        role="button"
        (click)="openModalEditBooking()"
        [disabled]="!(booking?.status.split('_')[0] !== 'CLOSED')"
      >
        {{ "ACTIONS.EDIT" | translate }}
      </button>
    </div>
  </div>
</div>
