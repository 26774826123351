<div class="container-table">
    <div class="card border-0 p-3">

        <invoice-table-filter
            [titleTable]="titleTable"
            [hasManageInvoicePermission]="hasManageInvoicePermission"
            (filterTableHeaderChanged)="getDataForm($event)" 
            (exportEvent)="getExport()"
            (onMassiveAction)="onMassiveAction($event)">
        </invoice-table-filter>

        <dynamic-table
            [hasCheckbox]="hasManageInvoicePermission"
            [columns]="tableColumns" [rows]="tableRows" (filterTasks)="getTable()"
            [totalItems]="filtersPage.totalRows" [itemsPerPage]="filtersPage.itemsPerPage" 
            [maxPagesToShowPagination]="filtersPage.maxPagesToShowPagination" (pageChanged)="onPageChange($event)"
            [listRowToShowPerPage]="filtersPage.listRowToShowPerPage" 
            (pageSizeChanged)="onPageSizeChange($event)"
            (sortColumn)="onSortColumnHeader($event)" 
            (emitTableRows)="onMassiveSelections($event)">
        </dynamic-table>

    </div>
</div>