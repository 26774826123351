@if (options?.length > 0) {
    <div class="dropdown d-flex align-items-center">

        <button type="button" class="btn btn-actions" id="dropdownMenuTableActions" data-bs-toggle="dropdown" aria-expanded="false">        
            <i class="bi bi-three-dots"></i>
        </button>

        <ul class="dropdown-menu" aria-labelledby="dropdownMenuTableActions">
            <li *ngFor="let option of options">
                <a class="dropdown-item" (click)="selectOption(option)">{{option.text}}</a>
            </li>
        </ul>

    </div>
}