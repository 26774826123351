<form class="form-create-booking d-flex flex-column gap-3 px-3" [formGroup]="form">

    <div class="form-group">
        <label for="titleBooking" class="form-label">{{'TITLE' | translate}} <span *ngIf="bookingType?.titleRequired">*</span></label>
        <input type="text" class="form-control" [ngClass]="{'border-danger': form.get('titleBooking')?.hasError('invalid')}" placeholder="{{ 'BOOKINGS_MODAL.TITLE_DESCRIPTION' | translate}}" formControlName="titleBooking">
    </div>

    <div class="form-group">
        <label for="headquarter" class="form-label">{{'HEADQUARTER.NAME' | translate}} <span>*</span></label>
        <dropdown-primary-headquarter
            [headquarterId]="form.controls['headquarterId'].value"
            (onSelectedEvent)="onSelectedEvent($event, 'headquarterId')">                        
        </dropdown-primary-headquarter>
    </div>

    <div class="form-group">
        <label for="location" class="form-label">{{'LOCATION.NAME' | translate}} <span>*</span></label>
        <dropdown-primary-location
            [headquarterId]="form.controls['headquarterId'].value"
            [locationId]="form.controls['locationId'].value"
            [cssClass]="form.get('locationId')?.hasError('invalid') ? 'border-danger' : ''"
            (onSelectedEvent)="onSelectedEvent($event, 'locationId')">                        
        </dropdown-primary-location>
    </div>


    <div class="form-group">
        <label for="type-booking" class="form-label">{{'TYPOLOGY_OF_BOOKING' | translate}} <span>*</span></label>
        <dropdown-primary-booking-type
            [id]="form.value.bookingTypeId ?? null"
            [setFirstDefaultOption]="true"
            [cssClass]="form.get('bookingTypeId')?.hasError('invalid') ? 'border-danger' : ''"
            (onSelectedEvent)="onSelectedEvent($event, 'bookingTypeId')">
        </dropdown-primary-booking-type>
    </div>

    @if (canBookForOthers) {
        <div class="container-driver">
            <h3 class="title">{{ 'BOOKINGS.DRIVER' | translate}}</h3>
    
            <div class="form-group">
                <label for="driver" class="form-label">{{'BOOKINGS.DRIVER' | translate}} <span>*</span></label>
                <input 
                    type="search"
                    formControlName="displayNameDriver"
                    [typeahead]="driverNames$"
                    [typeaheadAsync]="true"
                    [typeaheadMinLength]="0"
                    (typeaheadOnSelect)="updateUserId($event)"
                    class="form-control"
                    [ngClass]="{'border-danger': form.get('displayNameDriver')?.hasError('invalid')}"> 
            </div>
        </div>
    }

    <div class="container-start-use-vehicle">
        <h3 class="title">{{'START_USE_VEHICLE' | translate}}</h3>
        <div class="d-flex gap-3 flex-wrap">
            <div class="form-group flex-1">
                <label for="startDateBooking" class="form-label">{{'START_DATE' | translate}} <span>*</span></label>
                <input-date-picker 
                    formControlName="startDateBooking"
                    [cssClass]="form.get('startDateBooking')?.hasError('invalid') ? 'border-danger' : ''">
                </input-date-picker>
            </div>
        
            <div class="form-group flex-1">
                <label for="startTimeBooking" class="form-label">{{'START_TIME' | translate}} <span>*</span></label>
                <input type="time" class="form-control" id="startTimeBooking" [placeholder]="'SELECT_TIME' | translate"
                    [ngClass]="{'border-danger': form.get('startDateBooking')?.hasError('invalid')}"
                    formControlName="startTimeBooking" >
            </div>
        </div>
        @if (bookingType?.closeUntakenAfterMinutes) {
            <span class="form-text text-danger">{{ 'BOOKINGS_MODAL.UNTAKEN_MESSAGE' | translate: { value: bookingType?.closeUntakenAfterMinutes } }}</span>
        }
    </div>

    <div class="container-end-use-vehicle">
        <h3 class="title">{{'END_USE_VEHICLE' | translate}}</h3>
        <div class="d-flex gap-3 flex-wrap">
            <div class="form-group flex-1">
                <label for="endDateBooking" class="form-label">{{'END_DATE' | translate}} <span>*</span></label>
                <input-date-picker 
                    formControlName="endDateBooking"
                    [cssClass]="form.get('endDateBooking')?.hasError('invalid') ? 'border-danger' : ''">
                </input-date-picker>
            </div>
        
            <div class="form-group flex-1">
                <label for="endTimeBooking" class="form-label">{{'END_TIME' | translate}} <span>*</span></label>
        
                <input type="time" class="form-control" id="endTimeBooking" [placeholder]="'SELECT_TIME' | translate"
                    [ngClass]="{'border-danger': form.get('endDateBooking')?.hasError('invalid')}"
                    formControlName="endTimeBooking" >
            </div>
        </div>
    </div>

    @if (fields?.length > 0) {
        <div class="container-information">
            <h3 class="title">{{'INFORMATION' | translate}}</h3>
            <formly-form #formField [form]="form" [fields]="fields"></formly-form>
        </div>
    }
</form>