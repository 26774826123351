import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DropdownOption } from '../dropdown.interface';
import { BookingService } from 'src/app/shared/api-services/booking.service';

@Component({
  selector: 'dropdown-primary-booking-type',
  template: `
    <dropdown-primary
      class="w-100"
      [isDisabled]="isDisable"
      [selectedOption]="optionSelected"
      [options]="listOptions"
      (selectedOptionChange)="onSelected($event)"
      [emitNgOnInit]="false"
      [activeScrollbar]="true"
      [iconClass]="'bi bi-chevron-down'"
      [cssClass]="'btn-dropdown ' + cssClass">
    </dropdown-primary>
  `,
})
export class DropdownPrimaryBookingTypeComponent {
  @Input() id: string;
  @Input() isDisable: boolean = false;
  @Input() cssClass: string;
  @Input() setFirstDefaultOption: boolean = false;

  tenantId: string = this._tenantService.getTenantId();

  defaultOption: DropdownOption = {
    value: '',
    text: this._translateService.instant('SELECT_OPTION')
  };

  optionSelected: DropdownOption;
  listOptions: DropdownOption[];

  @Output() onSelectedEvent = new EventEmitter<DropdownOption>();

  constructor(
    private _tenantService: TenantService,
    private _bookingService: BookingService,
    private _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.optionSelected = JSON.parse(JSON.stringify(this.defaultOption));
    this.getData();
  }

  getData() {
    this._bookingService.listBookingTypes$(this.tenantId).subscribe({
      next: response => {
        this.listOptions = response.items.map(item => {
          return {
            value: item.id,
            text: item.name,
            extraField: item
          }
        });

        if (this.id) {
          this.optionSelected = this.listOptions.find(option => option.value === this.id);
          this.onSelectedEvent.emit(this.optionSelected);
        } else if (this.setFirstDefaultOption) {
          this.optionSelected = JSON.parse(JSON.stringify(this.listOptions[0]));
          this.onSelectedEvent.emit(this.optionSelected);
        } else {
          this.optionSelected = JSON.parse(JSON.stringify(this.defaultOption));
        }
      }
    });
  }

  onSelected(optionSelected$: DropdownOption) {
    this.onSelectedEvent.emit(optionSelected$);
  }

  onReset() {
    this.optionSelected = JSON.parse(JSON.stringify(this.defaultOption));
  }
}
