<div class="card-filter-task p-3">
    <header class="d-flex justify-content-between align-items-center">
        <h4 class="card-person-modal-title">
            {{ 'FILTER.CREATE_FILTER' | translate }}
        </h4>
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content>
        <form class="d-flex flex-column gap-3" [formGroup]="form">

            <div class="form-group">
                <label for="task-type" class="form-label">{{'TYPOLOGY' | translate}}</label>
                <dropdown-primary-asset-type 
                    [id]="form.value.assetTypeId ?? null"
                    (onSelectedEvent)="onAssetTypeSelected($event)">                    
                </dropdown-primary-asset-type>
            </div>

        </form>
    </content>

    <footer class="d-flex gap-2 align-items-center justify-content-center mt-4">
        <button type="button" class="btn-gs-secondary" (click)="reset()">{{'ACTIONS.RESET' | translate}}</button>

        <button type="button" class="btn-gs-primary" (click)="apply()">{{'ACTIONS.APPLY' | translate}}</button>
    </footer>
</div>
