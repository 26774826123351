import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";

@Injectable()
export class SentryErrorHandler implements ErrorHandler {

  handleError(error: any) {
    if (error?.status !== 404 && error?.status !== 403 && error?.status !== 401) {
      Sentry.captureEvent(error.originalError || error);
      console.error(error);
    }
  }
}