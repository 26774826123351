@if (title) {
    <h6 class="title-switch mb-3">{{ title | translate }}</h6>
}

<div class="form-check form-switch">
    <input 
        class="form-check-input" 
        type="checkbox" 
        [id]="allId" 
        [checked]="isCheckedAll" 
        (change)="onAllSelected()">
    <label class="form-check-label" [for]="allId">{{ 'ALL' | translate }}</label>
</div>

@if (!isCheckedAll) {
    <div class="form-check form-switch" *ngFor="let switch of listSwitches">
        <input 
            class="form-check-input" 
            type="checkbox" 
            [id]="switch.id" 
            [checked]="switch.checked" 
            (change)="onSwitchChange(switch)">
        <label class="form-check-label" [for]="switch.id">{{ switch.text | translate }}</label>
    </div>
}