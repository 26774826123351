import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, switchMap, catchError, of, throwError } from 'rxjs';
import { AuthenticationService } from 'src/app/core/services/authentication.service';

@Injectable()
export class VectoreAuthInterceptor implements HttpInterceptor {
  constructor(
    private _authenticationService: AuthenticationService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isApiRequest(request)) {
      return this._authenticationService.getAccessTokenOidc().pipe(
        switchMap(token => this.handleToken(request, token, next)),
        catchError(error => this.handleError(error))
      );
    }
    return next.handle(request);
  }

  private isApiRequest(request: HttpRequest<any>): boolean {
    const url = request.url;
    return url.includes('api') && !['idp', 'token', 'refresh-token'].some(segment => url.includes(segment));
  }

  private handleToken(request: HttpRequest<any>, tokenFromOidc: string, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = tokenFromOidc || this._authenticationService.getAccessTokenVectoreAuth() || this._authenticationService.getVectoreTokenDirect();
    if (token) {
      request = this.cloneRequestWithToken(request, token);
    }
    return next.handle(request);
  }

  private cloneRequestWithToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handleError(error: any): Observable<never> {
    if (error.status !== 401 && error.status !== 403 && error.status !== 404) {
      console.error('Errore nell\'intercettore:', error);
    }
    return throwError(() => error);
  }
}
