<div class="modal-confirm p-4 text-center">
    @if (data.title) {
        <h4 [ngClass]="data.titleCssClass">{{data.title | translate}}</h4>
    }
    @if (data.content) {
        <p [ngClass]="data.contentCssClass">{{data.content | translate}}</p>
    }

    <div class="d-flex justify-content-center gap-2 align-items-center">
        <button type="button" [ngClass]="data.btnCssClassNo ?? 'btn btn-default'" (click)="confirmModal.hide()">
            {{'ACTIONS.NO' | translate}}
        </button>

        <button type="button" [ngClass]="data.btnCssClassYes ?? 'btn-gs-primary'" (click)="emitTrue()">
            {{'ACTIONS.YES' | translate}}
        </button>
    </div>
</div>