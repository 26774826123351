import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { PermissionSwitch } from '../permission-modal.component';
import * as PERMISSIONS from 'src/app/shared/constant/permissions';
import { PermissionModalUtilsService } from '../permission-modal-utils.service';
import { Permission } from 'src/app/shared/models/permission/permission';
import { SharedModule } from 'src/app/shared/shared.module';
import { ListSwitch } from '../../../list-switches/list-switches.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'permission-type-section-modal',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './permission-type-section-modal.component.html',
  styleUrl: './permission-type-section-modal.component.scss'
})
export class PermissionTypeSectionModalComponent implements OnInit, OnChanges, OnDestroy {

  @Input() permissionName: string;
  @Input() permission: Permission;
  permissionSwitch: PermissionSwitch = {
    showSwitch: false,
    listSwitch: [],
  }
  expr: string;

  @Output() exprChange = new EventEmitter<string>();
  permissionsConst = PERMISSIONS;

  private _destroy = new Subject<void>();

  constructor(
    private _permissionModalUtilsService: PermissionModalUtilsService
  ) { }

  ngOnInit(): void {
    this.changeView();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['permission'] || changes['permissionName']) {
      this.changeView();
    }
  }

  private changeView() {
    switch (this.permissionName) {
      case this.permissionsConst.CAN_READ_ASSET_TYPES:
        this.permissionSwitch.showSwitch = true;
        this.permissionSwitch.titleSwitch = 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_ASSET_TYPES';
        this._permissionModalUtilsService.getAssetTypes$(this.permission).pipe(
          takeUntil(this._destroy)
        ).subscribe(assetTypes => {
          this.permissionSwitch.listSwitch = assetTypes;
        });
        break;
      case this.permissionsConst.CAN_READ_TASK_TYPES:
        this.permissionSwitch.showSwitch = true;
        this.permissionSwitch.titleSwitch = 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_TASK_TYPES';
        this._permissionModalUtilsService.getTaskTypes$(this.permission).pipe(
          takeUntil(this._destroy)
        ).subscribe(taskTypes => {
          this.permissionSwitch.listSwitch = taskTypes;
        });
        break;
      case this.permissionsConst.CAN_READ_BOOKING_TYPES:
        this.permissionSwitch.showSwitch = true;
        this.permissionSwitch.titleSwitch = 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_BOOKING_TYPES';
        this._permissionModalUtilsService.getBookingTypes$(this.permission).pipe(
          takeUntil(this._destroy)
        ).subscribe(bookingTypes => {
          this.permissionSwitch.listSwitch = bookingTypes;
        });
        break;
      case this.permissionsConst.CAN_READ_VEHICLE_TYPES:
        this.permissionSwitch.showSwitch = true;
        this.permissionSwitch.titleSwitch = 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_VEHICLE_TYPES';
        this._permissionModalUtilsService.getVehicleTypes$(this.permission).pipe(
          takeUntil(this._destroy)
        ).subscribe(vehicleTypes => {
          this.permissionSwitch.listSwitch = vehicleTypes;
        });
        break;
      case this.permissionsConst.CAN_READ_HEADQUARTERS:
      case this.permissionsConst.CAN_MANAGE_HEADQUARTERS:
        this.permissionSwitch.showSwitch = true;
        this.permissionSwitch.titleSwitch = 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_HEADQUARTERS_INSIDE';
        this._permissionModalUtilsService.getHeadquarters$(this.permission).pipe(
          takeUntil(this._destroy)
        ).subscribe(headquarters => {
          this.permissionSwitch.listSwitch = headquarters;
        });
        break;
      case this.permissionsConst.CAN_READ_LOCATIONS:
      case this.permissionsConst.CAN_MANAGE_LOCATIONS:
        this.permissionSwitch.showSwitch = true;
        this.permissionSwitch.titleSwitch = 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_LOCATIONS_INSIDE';
        this._permissionModalUtilsService.getLocations$(this.permission).pipe(
          takeUntil(this._destroy)
        ).subscribe(locations => {
          this.permissionSwitch.listSwitch = locations;
        });
        break;
      case this.permissionsConst.CAN_READ_TAGS:
      case this.permissionsConst.CAN_MANAGE_TAGS:
        this.permissionSwitch.showSwitch = true;
        this.permissionSwitch.titleSwitch = 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_TAGS';
        this._permissionModalUtilsService.getTags$(this.permission).pipe(
          takeUntil(this._destroy)
        ).subscribe(tags => {
          this.permissionSwitch.listSwitch = tags;
        });
        break;
    }
  }

  onSwitchSelected(event: ListSwitch[]) {
    let checkIfAllSelected = event.every(sw => sw.checked);
    if (checkIfAllSelected) {
      this.expr = '';
      this.exprChange.emit(this.expr);
      return;
    }

    let ids = event.filter(sw => sw.checked).map(sw => sw.id);
    this.expr = this._permissionModalUtilsService.createExpressionWithEq('Id', ids);
    this.exprChange.emit(this.expr);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
