import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

export interface NominatimResponse {
  lat: string;
  lon: string;
  display_name: string;
  importance: number;
  class: string;
  address: {
    road?: string;
    square?: string;
    neighbourhood?: string;
    suburb?: string;
    village?: string;
    town?: string;
    municipality?: string;
    city?: string;
    province?: string;
    state?: string;
    country?: string;
    country_code?: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class NominatimService {
  private nominatimUrl = 'https://nominatim.vectore.it/search';
  
  constructor(private http: HttpClient) {}

  /**
   * Crea un display name più corto utilizzando solo le componenti principali dell'indirizzo
   */
  private createShortDisplayName(address: NominatimResponse['address']): string {
    const components = [];
    
    // Aggiungi prima il componente più specifico disponibile
    const roadComponent = address.road || address.square;
    if (roadComponent) {
      components.push(roadComponent);
    }
    
    // Aggiungi la città/paese usando il primo disponibile
    const cityComponent = address.city || address.town || address.village || address.municipality;
    if (cityComponent) {
      components.push(cityComponent);
    }
    
    // Aggiungi la provincia/stato se disponibile
    if (address.province || address.state) {
      components.push(address.province || address.state);
    }
    
    // Aggiungi sempre il paese
    if (address.country) {
      components.push(address.country);
    }
    
    return components.join(', ');
  }

  searchLocation(query: string, limit: number = 5): Observable<NominatimResponse[]> {
    const params = new HttpParams()
      .set('q', query)
      .set('limit', String(limit))
      .set('format', 'json')
      .set('addressdetails', '1');
      
    return this.http.get<NominatimResponse[]>(`${this.nominatimUrl}?${params.toString()}`).pipe(
      map((locations: NominatimResponse[]) => 
        locations
          .sort((a, b) => b.importance - a.importance)
          .filter((location, index, self) =>
            index === self.findIndex((t) => t.display_name === location.display_name)
          )
          .map(location => ({
            ...location,
            display_name: this.createShortDisplayName(location.address)
          }))
      )
    );
  }
}