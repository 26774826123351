import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { MakesService } from 'src/app/shared/api-services/makes.service';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { HttpResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Model } from 'src/app/shared/models/make/make';

@Component({
  selector: 'model-modal',
  templateUrl: './model-modal.component.html'
})
export class ModelModalComponent {
  tenantId: string = this._tenantService.getTenantId();

  model: Model;

  form: FormGroup;

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  dropdownsDisable = {
    make: false,
  }

  confirmModal: BsModalRef;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _makesService: MakesService,
    private _communicationService: CommunicationService,
    private _modalService: BsModalService,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
    public bsModalRef: BsModalRef,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      makeId: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit() {
    this.form.get('name')?.disable();

    if (this.model?.id) {
      this.getModel();
    }
  }

  getModel() {
    this._makesService.getModel$(this.tenantId, this.model.id)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (response) => {
          this.model = response;
          this.form.patchValue({
            name: response.name
          });
          this.modalStatus.isCreate = false;
          this.modalStatus.isPatch = true;

          this.dropdownsDisable.make = true;
        }
      });
  }

  onMakeSelected($event: DropdownOption) {
    this.form.get('name')?.enable();
    this.form.patchValue({
      makeId: $event.value
    });
  }

  createModel() {
    let request = this.createRequest();
    this._makesService.createModel$(this.tenantId, request)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.id) {
            this._communicationService.sendEvent();
            this.bsModalRef.hide();
          }
        }
      });
  }

  updateModel() {
    let request = this.createRequest();
    this._makesService.updateModel$(this.tenantId, this.model.id, request)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.id) {
            this._communicationService.sendEvent();
            this.bsModalRef.hide();
          }
        }
      });
  }

  private createRequest() {
    if (!this.form.value.makeId) {
      this._toastrService.error(this._translateService.instant('MODELS.MESSAGES.REQUIRED_MAKE'));
      return null;
    }

    return {
      name: this.form.value.name,
      makeId: this.form.value.makeId,
    };
  }

  deleteModel() {
    this.confirmModal = this._modalService.show(ConfirmModalComponent, {
      class: 'modal-sm',
      animated: true,
      initialState: {
        data: {
          content: 'MESSAGES.CONFIRM_DELETE'
        }
      }
    });

    this.confirmModal.content.onConfirm
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this._makesService.deleteModel$(this.tenantId, this.model.id)),
      )
      .subscribe({
        next: (response: HttpResponse<Object>) => {
          if (response.status === 204) {
            this._communicationService.sendEvent();
            this.bsModalRef.hide();
          }
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
