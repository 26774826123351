<div class="modal-confirm p-4">
    
    <h4 class="text-center mb-3">{{'BOOKING_CLOSE_USAGE.TITLE' | translate}}</h4>

    <p class="text-center">{{'BOOKING_CLOSE_USAGE.CONTENT' | translate}}</p>

    @if (!bookingUsage?.endMileage) {
        <div class="border rounded p-3" [formGroup]="form">
            <div class="form-group">
                <label for="endMileage">
                    {{'BOOKING_CLOSE_USAGE.END_MILEAGE' | translate}}
                    <i class="bi bi-exclamation-triangle text-danger"></i>
                </label>

                <div class="input-group mb-1">
                    <input type="number" formControlName="endMileage" class="form-control" id="endMileage" aria-describedby="basic-addon3"
                        [ngClass]="{'is-invalid' : form.get('endMileage').hasError('invalid')}">
        
                    <span class="input-group-text" id="basic-addon3">Km</span>
                </div>

                <span class="form-text color-danger">
                    {{'BOOKING_CLOSE_USAGE.CONTENT_ALERT' | translate}}
                </span>
            </div>
        </div>
    }

    <div class="d-flex justify-content-center gap-2 align-items-center mt-4">
        <button type="button" class="btn btn-default" (click)="confirmModal.hide()">
            {{'ACTIONS.NO' | translate}}
        </button>

        <button type="button" class="btn-gs-primary" (click)="confirm()">
            {{'ACTIONS.YES' | translate}}
        </button>
    </div>
</div>