import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Location } from 'src/app/shared/models/location/location';
import { HeadquartersService } from 'src/app/shared/api-services/headquarters.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { HttpResponse } from '@angular/common/http';
import { CreateLocationRequest, UpdateLocationRequest } from 'src/app/shared/models/location/location-request';

@Component({
  selector: 'location-modal',
  templateUrl: './location-modal.component.html',
})
export class LocationModalComponent {
  tenantId: string = this._tenantService.getTenantId();

  location: Location;

  form: FormGroup;

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  confirmModal: BsModalRef;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _headquarterService: HeadquartersService,
    private _communicationService: CommunicationService,
    private _modalService: BsModalService,
    public bsModalRef: BsModalRef,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      headquarterId: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit() {
    this.form.get('name')?.disable();

    if (this.location?.id) {
      this.getLocation();
    }
  }

  getLocation() {
    this._headquarterService.getLocation$(this.location.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.location = response;
        this.form.patchValue({
          name: response.name,
        });

        this.modalStatus.isPatch = true;
        this.modalStatus.isCreate = false;
      });
  }

  onHeadquarterSelected($event: DropdownOption) {
    this.form.get('name')?.enable();
    this.form.patchValue({
      headquarterId: $event.value
    });
  }

  createLocation() {
    let request: CreateLocationRequest = {
      name: this.form.value.name,
      headquarterId: this.form.value.headquarterId
    }

    this._headquarterService.createLocation$(this.tenantId, request)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response.id) {
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
      });
  }

  updateLocation() {
    let request: UpdateLocationRequest = {
      name: this.form.value.name
    }
    this._headquarterService.updateLocation$(this.tenantId, this.location.id, request)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response.id) {
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
      });
  }

  deleteLocation() {
    this.confirmModal = this._modalService.show(ConfirmModalComponent, {
      class: 'modal-sm',
      animated: true,
      initialState: {
        data: {
          content: 'MESSAGES.CONFIRM_DELETE'
        }
      }
    });

    this.confirmModal.content.onConfirm.pipe(
      takeUntil(this.destroy$),
      switchMap(() => this._headquarterService.deleteLocation$(this.tenantId, this.location.id)),
    )
      .subscribe((response: HttpResponse<Object>) => {
        if (response.status === 204) {
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
      });
  }
}
