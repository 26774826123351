import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { ModalUtilsService } from 'src/app/shared/utilities/modal-utils.service';
import { FilterModalService, GenericFilter } from '../../../modals/filter-modal/filter-modal.service';
import { ImportRowsFilter } from 'src/app/shared/models/import/import-filter';
import { filterImportFileModal } from '../../../modals/import-file-filter-modal/import-file-filter-modal.component';
import { CentralQueryParamsService } from 'src/app/core/services/central-query-params.service';

@Component({
  selector: 'import-management-data-filter',
  templateUrl: './import-management-data-filter.component.html',
  styleUrls: ['./import-management-data-filter.component.scss']
})
export class ImportManagementDataFilterComponent implements OnInit, OnDestroy {
  @Input() titleTable: string;
  @Input() buttonImport: ButtonImport;

  importRowsFilter: ImportRowsFilter;

  filterForm: FormGroup = new FormGroup({
    headerToFilter: new FormControl(),
    inputFilter: new FormControl(),
    startDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/)),
    endDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/))
  });

  @Output() onFilterModalHeaderChanged = new EventEmitter<ImportRowsFilter>();
  @Output() exportEvent = new EventEmitter<any>();

  @Output() onImportValidated = new EventEmitter();
  @Output() onImportUpdate = new EventEmitter();

  private _destroy = new Subject<void>();

  constructor(
    private _modalUtilsService: ModalUtilsService,
    private _filterModalService: FilterModalService,
    private _centralQueryParamsService: CentralQueryParamsService,
  ) { }

  ngOnInit(): void {
  }

  onValidateImport() {
    this.onImportValidated.emit();
  }

  onUpdateTable() {
    this.onImportUpdate.emit();
  }

  onFilterClick() {
    this._modalUtilsService.openImportManagementDataFilterModal().pipe(
      takeUntil(this._destroy)
    ).subscribe();
  }

  removeAllFilters() {
    this.importRowsFilter = null;
    this.onFilterModalHeaderChanged.emit(this.importRowsFilter);
    let genericFilter: GenericFilter = {
      statusImportFile: null
    };
    this._filterModalService.applyFilter(filterImportFileModal, genericFilter);
  }

  removeStatusFilter() {
    this.importRowsFilter = null;
    this.onFilterModalHeaderChanged.emit(this.importRowsFilter);
    let genericFilter: GenericFilter = {
      statusImportFile: null
    };
    this._filterModalService.applyFilter(filterImportFileModal, genericFilter);
  }

  onClickExport() {
    this.exportEvent.emit();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}

export interface ButtonImport {
  toImportRows: number;
  show: boolean;
  isLoading: boolean;
}