import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DropdownOption } from '../dropdown.interface';
import { UserGroupsService } from 'src/app/shared/api-services/user-groups.service';

@Component({
  selector: 'dropdown-primary-permission',
  template: `
    <dropdown-primary
      class="w-100"
      [selectedOption]="optionSelected"
      [options]="listOptionsFiltered"
      [isDisabled]="isDisabled"
      (selectedOptionChange)="onSelected($event)"
      [emitNgOnInit]="false"
      [activeScrollbar]="true"
      [iconClass]="'bi bi-chevron-down'"
      [cssClass]="'btn-dropdown'">
    </dropdown-primary>
  `,
})
export class DropdownPrimaryPermissionComponent {
  @Input() permissionName: string;
  @Input() permissionFilterName: string;
  @Input() isDisabled: boolean = false;
  tenantId: string = this._tenantService.getTenantId();

  defaultOption: DropdownOption = {
    value: '',
    text: this._translateService.instant('USER_GROUPS.PERMISSIONS.MODAL.SELECT_PERMISSION_NAME')
  };

  optionSelected: DropdownOption;
  listOptions: DropdownOption[];
  listOptionsFiltered: DropdownOption[];

  @Output() onSelectedEvent = new EventEmitter<DropdownOption>();

  constructor(
    private _tenantService: TenantService,
    private _userGroupsService: UserGroupsService,
    private _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.optionSelected = this.defaultOption;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (!this.listOptions) {
      this.getData();
    }

    if (simpleChanges['permissionFilterName']) {
      if (this.listOptions && this.permissionFilterName !== 'all') {
        this.listOptionsFiltered = this.listOptions.filter(
          option => option.value.toLowerCase().includes(this.permissionFilterName.toLowerCase())
        );
      }
      if (this.listOptions && this.permissionFilterName === 'all') {
        this.listOptionsFiltered = JSON.parse(JSON.stringify(this.listOptions));
      }
    }
  }

  getData() {
    this._userGroupsService.listPermissionNames$(this.tenantId).subscribe({
      next: response => {
        this.listOptions = response.map(permission => {
          return {
            value: permission.name,
            text: permission.name
          }
        });
        this.listOptionsFiltered = JSON.parse(JSON.stringify(this.listOptions));

        if (this.permissionName) {
          this.optionSelected = this.listOptions.find(
            option => option.value === this.permissionName
          );
          this.onSelectedEvent.emit(this.optionSelected);
        } else {
          this.optionSelected = this.defaultOption;
        }
      }
    });
  }

  onSelected(optionSelected$: DropdownOption) {
    this.onSelectedEvent.emit(optionSelected$);
  }
}
