import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { Observable, shareReplay, tap } from 'rxjs';
import { Booking, BookingType, BookingUsage } from '../models/booking/booking';
import { BookingFieldsResponse, BookingTypesResponse, BookingResponse, BookingDataResponse, BookingUsageResponse, ReservationResponse, VehicleBookingResponse } from '../models/booking/booking-response';
import { CommonService } from './common.service';
import { MappingModel } from '../models/user/users';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BookingRequest, BookingUsageRequest } from '../models/booking/booking-request';
import { BookingDataFilter, BookingFilter, BookingsFilter, BookingTypeFilter, BookingUsageFilter, BookingVehicleFilter } from '../models/booking/booking-filter';
import { TenantService } from './tenant.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private _tenantId: string;

  constructor(
    private _tenantService: TenantService,
    private _http: HttpClient,
    private _commonService: CommonService,
    private _translateService: TranslateService,
    private _toasterService: ToastrService,
  ) {
    this._tenantId = this._tenantService.getTenantId();
  }

  listBookingTypes$(tenantId: string, params?: BookingTypeFilter): Observable<BookingTypesResponse> {
    return this._http.get<BookingTypesResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/booking-types`, { params: this._commonService.buildParams(params) });
  }

  getBoookingType$(bookingTypeId: string): Observable<BookingType> {
    return this._http.get<BookingType>(`${VECTORE_API_BASE_URL}/${this._tenantId}/booking-types/${bookingTypeId}`);
  }

  getBookingFields$(params?: any): Observable<BookingFieldsResponse> {
    return this._http.get<BookingFieldsResponse>(`${VECTORE_API_BASE_URL}/${this._tenantId}/booking-fields`, { params: this._commonService.buildParams(params) });
  }

  createBooking$(booking: BookingRequest): Observable<Booking> {
    return this._http.post<Booking>(`${VECTORE_API_BASE_URL}/${this._tenantId}/bookings`, booking)
      .pipe(
        tap(response => {
          if (response.id) {
            this._toasterService.success(this._translateService.instant('BOOKINGS.MESSAGES.SUCCESS_CREATE_BOOKING'));
          }
        }),
        tap({
          error: error => {
            this._toasterService.error(this._translateService.instant(`BOOKINGS.ERROR_CODE.${error.error.errorCode}`));
            this._commonService.handleError(error)
          }
        })
      );
  }

  listBookings$(tenantId: string, params?: BookingsFilter): Observable<BookingResponse> {
    return this._http.get<BookingResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getBooking$(bookingId: string, params?: BookingFilter): Observable<Booking> {
    return this._http.get<Booking>(`${VECTORE_API_BASE_URL}/${this._tenantId}/bookings/${bookingId}`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  patchBooking$(bookingId: string, booking: BookingRequest): Observable<Booking> {
    return this._http.patch<Booking>(`${VECTORE_API_BASE_URL}/${this._tenantId}/bookings/${bookingId}`, booking)
      .pipe(
        tap(response => {
          if (response.id) {
            this._toasterService.success(this._translateService.instant('BOOKINGS.MESSAGES.SUCCESS_EDIT_BOOKING'));
          }
        }),
        tap({
          error: error => {
            this._toasterService.error(this._translateService.instant(`BOOKINGS.ERROR_CODE.${error.error.errorCode}`));
            this._commonService.handleError(error)
          }
        })
      );
  }

  deleteBooking$(bookingId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${this._tenantId}/bookings/${bookingId}`, { observe: 'response' })
      .pipe(
        tap({
          error: error => {
            this._toasterService.error(this._translateService.instant(`BOOKINGS.ERROR_CODE.${error.error.errorCode}`));
            this._commonService.handleError(error)
          }
        })
      );
  }

  approveBooking$(tenantId: string, bookingId: string): Observable<HttpResponse<Object>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/approve`, null, { observe: 'response' })
      .pipe(
        tap({
          error: error => {
            this._toasterService.error(this._translateService.instant(`BOOKINGS.ERROR_CODE.${error.error.errorCode}`));
            this._commonService.handleError(error)
          }
        })
      );
  }

  unapproveBooking$(tenantId: string, bookingId: string): Observable<HttpResponse<Object>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/unapprove`, null, { observe: 'response' })
      .pipe(
        tap({
          error: error => {
            this._toasterService.error(this._translateService.instant(`BOOKINGS.ERROR_CODE.${error.error.errorCode}`));
            this._commonService.handleError(error)
          }
        })
      );
  }

  closeBooking$(tenantId: string, bookingId: string): Observable<Booking> {
    return this._http.post<Booking>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/close`, null)
      .pipe(
        tap(response => {
          if (response.id) {
            this._toasterService.success(this._translateService.instant('BOOKINGS.MESSAGES.SUCCESS_BOOKING_CLOSED'));
          }
        }),
        tap({
          error: error => {
            this._toasterService.error(this._translateService.instant(`BOOKINGS.ERROR_CODE.${error.error.errorCode}`));
            this._commonService.handleError(error)
          }
        })
      );
  }

  undeleteBooking$(tenantId: string, bookingId: string): Observable<HttpResponse<Object>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/undelete`, null, { observe: 'response' })
      .pipe(
        tap({
          error: error => {
            this._toasterService.error(this._translateService.instant(`BOOKINGS.ERROR_CODE.${error.error.errorCode}`));
            this._commonService.handleError(error)
          }
        })
      );
  }

  exportBookings$(tenantId: string, exportData: MappingModel, params?: BookingsFilter): Observable<HttpResponse<Blob>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${tenantId}/export/bookings`, exportData,
      { observe: 'response', responseType: 'blob', params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getVehiclesAvailableForBooking$(bookingId: string, params?: BookingVehicleFilter): Observable<VehicleBookingResponse> {
    return this._http.get<VehicleBookingResponse>(`${VECTORE_API_BASE_URL}/${this._tenantId}/bookings/${bookingId}/vehicles`,
      { params: this._commonService.buildParams(params) });
  }

  createBookingUsage$(tenantId: string, bookingId: string, bookingUsageRequest: BookingUsageRequest): Observable<BookingUsage> {
    return this._http.post<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/usages`, bookingUsageRequest)
      .pipe(
        tap(response => {
          if (response.id) {
            this._toasterService.success(this._translateService.instant("BOOKINGS.MESSAGES.BOOKING_USAGE_CREATED"));
          }
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listBookingUsages$(tenantId: string, bookingId: string, params?: BookingUsageFilter): Observable<BookingUsageResponse> {
    return this._http.get<BookingUsageResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/usages`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getBookingUsage$(tenantId: string, bookingId: string, bookingUsageId: string): Observable<BookingUsage> {
    return this._http.get<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/usages/${bookingUsageId}`);
  }

  patchBookingUsage$(tenantId: string, bookingId: string, bookingUsageId: string, bookingUsageRequest: BookingUsageRequest): Observable<BookingUsage> {
    return this._http.patch<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/usages/${bookingUsageId}`, bookingUsageRequest)
      .pipe(
        tap(response => {
          if (response.id) {
            this._toasterService.success(this._translateService.instant("BOOKINGS.MESSAGES.BOOKING_USAGE_MILEAGE_UPDATED"));
          }
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  closeBookingUsage$(tenantId: string, bookingId: string, bookingUsageId: string): Observable<BookingUsage> {
    return this._http.post<BookingUsage>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/usages/${bookingUsageId}/close`, null)
      .pipe(
        tap(response => {
          if (response.id) {
            this._toasterService.success(this._translateService.instant("BOOKINGS.MESSAGES.BOOKING_USAGE_CLOSED"));
          }
        }),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getReservations$(tenantId: string, bookingId: string, params?: any): Observable<ReservationResponse> {
    return this._http.get<ReservationResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/bookings/${bookingId}/reservations`,
      { params: this._commonService.buildParams(params) })
      .pipe(
        shareReplay(1),
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getBookingsData$(tenantId: string, params?: BookingDataFilter): Observable<BookingDataResponse> {
    return this._http.get<BookingDataResponse>(`${VECTORE_API_BASE_URL}/${tenantId}/booking-data`, { params: this._commonService.buildParams(params) });
  }
}
