import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { InputOption } from 'src/app/shared/components/buttons/button-choose/button-choose.component';
import { VehicleBooking } from 'src/app/shared/models/booking/booking';

@Component({
  selector: 'booking-modal-vehicles-to-show',
  templateUrl: './booking-modal-vehicles-to-show.component.html',
  styleUrls: ['./booking-modal-vehicles-to-show.component.scss']
})
export class BookingModalVehiclesToShowComponent {
  @Input() vehiclesToShow: VehicleBooking[] = [];
  @Output() onChangeEmitVehicleChecked = new EventEmitter<VehicleBooking[]>;

  constructor(
    private _vehiclesService: VehiclesService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.vehiclesToShow = this.vehiclesToShow?.map((vehicle: VehicleBooking) => {
      return {
        ...vehicle,
        srcImage$: this._vehiclesService.getVehicleImage$(vehicle.vehicle.id)
      }
    });
    if (this.vehiclesToShow?.length > 0) {
      setTimeout(() => {
        this.focusOnVehicle(this.checkIfVehicleIsChecked());
      }, 100);
    }
  }

  focusOnVehicle(vehicleId: string) {
    const element = document.querySelector(`[id="${vehicleId}"]`) as HTMLElement;
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  checkIfVehicleIsChecked() {
    return this.vehiclesToShow?.find((vehicle: VehicleBooking) => vehicle.vehicle.isChecked)?.vehicle.id;
  }

  changeVehicleChecked($inputOption: InputOption) {
    this.vehiclesToShow.forEach((vehicle: VehicleBooking) => {
      vehicle.vehicle.isChecked = false;
    });

    this.vehiclesToShow.find((vehicle: VehicleBooking) => vehicle.vehicle.id === $inputOption.id).vehicle.isChecked = true;
    this.onChangeEmitVehicleChecked.emit(this.vehiclesToShow);
  }
}
