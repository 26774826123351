<div class="modal-gs p-3 d-flex flex-column gap-3">
    <header class="d-flex align-items-center justify-content-center gap-2">
        @if (data.exclamationIcon) {
            <i class="bi bi-exclamation-triangle text-danger fs-5"></i>
        }
        <h4 class="modal-title fs-5 text-danger">{{data.title | translate}}</h4>
    </header>

    <div class="modal-gs-body">
        <p class="text-center">{{data.description | translate}}</p>
    </div>
</div>