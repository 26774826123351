<div class="card-booking-modal-step3 p-3">
  <div class="accordion mb-4">
    <div class="accordion-item">
      <h2 class="accordion-header" id="headingOne">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          {{booking?.title}}
        </button>
      </h2>
    </div>
    <div id="collapseOne" class="accordion-collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body">
            <table class="table">
                <tbody>
                    <tr>
                        <td class="text-muted">{{'TYPE' | translate}}</td>
                        <td>{{booking?.bookingType?.name}}</td>
                    </tr>
                    <tr>
                        <td class="text-muted">{{'START_DATE' | translate}}</td>
                        <td>{{booking?.startDate | date: 'dd MMM yyyy HH:mm'}}</td>
                    </tr>
                    <tr>
                        <td class="text-muted">{{'END_DATE' | translate}}</td>
                        <td>{{booking?.endDate | date: 'dd MMM yyyy HH:mm'}}</td>
                    </tr>
                    @if (booking?.expectedMileage) {
                        <tr>
                            <td class="text-muted">{{'BOOKINGS.DISTANCE' | translate}}</td>
                            <td>{{booking?.expectedMileage | number: '1.0-0'}} km</td>
                        </tr>
                    }
                    @if (booking?.expectedTime) {
                        <tr>
                            <td class="text-muted">{{'BOOKINGS.TIME_ESTIMATE' | translate}}</td>
                            <td>{{booking?.expectedTime}} {{'MINUTES' | translate | lowercase}}</td>
                        </tr>
                    }
                    @if (booking?.stages?.length > 0) {
                        <tr>
                            <td class="text-muted">{{'BOOKINGS.DESTINATION' | translate}}</td>
                            <td>{{ booking?.stages?.[booking?.stages?.length - 1] }}</td>
                        </tr>
                    }
                    <tr>
                        <td class="text-muted">{{'BOOKINGS.DRIVER' | translate}}</td>
                        <td class="d-flex align-items-center gap-1">
                            <div class="avatar-placeholder-xs person-placeholder">
                                <img *ngIf="imgSrc| async as imageUrl" [src]="imageUrl" alt="User Image">
                            </div>
                            {{booking?.user?.displayName}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>

  @if (bookingType?.enableAlternatives) {
    <div class="d-flex text-center w-100 flex-1 mt-4 pt-4">        
        <div class="d-flex flex-column align-items-center justify-content-center flex-1">
            <i class="bg-icon-primary-light bi-car-front-fill default-icon p-1"></i>
            <header class="fs-rem-125 fw-500 mb-2 mt-1" [innerHTML]="'BOOKINGS_MODAL.VEHICLE_SELECTED_BY_VECTORE' | translate | dynamicHighlight:'color-primary fs-rem-125 fw-500'"></header>
            <p class="text-muted fs-rem-085">{{'BOOKINGS_MODAL.VEHICLE_SELECTED_BY_VECTORE_INFO' | translate}}</p>
        </div>
    </div>
  } @else {
    @if (vehiclesToShow?.length > 0) {
        <booking-modal-vehicles-to-show 
            class="mt-4"
            [vehiclesToShow]="vehiclesToShow" 
            (onChangeEmitVehicleChecked)="onChangeVehicleChecked($event)">
        </booking-modal-vehicles-to-show>
    } @else {
      <div class="container-no-vehicles text-center py-4">
        <svg width="64" height="40" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6612_3364)">
            <rect width="64" height="40" fill="white" fill-opacity="0.01"/>
            <path d="M32 39.7031C49.6731 39.7031 64 36.5923 64 32.755C64 28.9177 49.6731 25.807 32 25.807C14.3269 25.807 0 28.9177 0 32.755C0 36.5923 14.3269 39.7031 32 39.7031Z" fill="#F5F5F5"/>
            <path d="M55 13.6653L44.854 2.24866C44.367 1.47048 43.656 1 42.907 1H21.093C20.344 1 19.633 1.47048 19.146 2.24767L9 13.6663V22.8367H55V13.6653Z" stroke="#CED4DA"/>
            <path d="M41.613 16.8128C41.613 15.2197 42.607 13.9046 43.84 13.9036H55V31.9059C55 34.0132 53.68 35.7402 52.05 35.7402H11.95C10.32 35.7402 9 34.0122 9 31.9059V13.9036H20.16C21.393 13.9036 22.387 15.2167 22.387 16.8098V16.8317C22.387 18.4248 23.392 19.7111 24.624 19.7111H39.376C40.608 19.7111 41.613 18.4128 41.613 16.8198V16.8128Z" fill="#FAFAFA" stroke="#CED4DA"/>
            </g>
            <defs>
            <clipPath id="clip0_6612_3364">
            <rect width="64" height="40" fill="white"/>
            </clipPath>
            </defs>
        </svg>
        <h5 class="no-vehicles-title">{{'NO_VEHICLES_FOUND_TITLE' | translate}}</h5>
        <p class="no-vehicles-content text-muted my-3">{{'NO_VEHICLES_FOUND_CONTENT' | translate}}</p>
        <button class="btn-outline-gs-primary" (click)="deleteBooking()">{{'BUTTONS.CANCEL_BOOKING' | translate}}</button>
      </div>
    }
  }
</div>