import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { DropdownEventsService } from 'src/app/shared/utilities/dropdown-events.service';
import { TableColumn } from '../dynamic-table/dynamic-table-desktop/table-column.interface';
import { TableRow } from '../dynamic-table/dynamic-table-desktop/table-row.interface';
import { FiltersPage } from 'src/app/shared/models/filter-page';
import { ImportFile, ImportFileClient } from "src/app/shared/models/import/import";
import { ImportService } from "src/app/shared/api-services/import.service";
import { ImportUtilsService } from "src/app/shared/utilities/import-utils.servic";
import { ImportsFilter } from "src/app/shared/models/import/import-filter";
import { ActivatedRoute } from "@angular/router";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { ImportStatus } from 'src/app/shared/constant/status-import';
import { ModalUtilsService } from 'src/app/shared/utilities/modal-utils.service';


@Component({
  selector: 'import-file-table',
  templateUrl: './import-file-table.component.html'
})
export class ImportFileTableComponent implements OnInit, OnDestroy {
  tableColumns: TableColumn[];
  tableRows: TableRow[];

  filtersPage: FiltersPage = {
    firstElement: 0,
    page: 1,
    itemsPerPage: 10,
    listRowToShowPerPage: [5, 10, 20, 30, 50, 100],
    totalRows: 0,
    totalPage: 1,
    orderBy: null,
    maxPagesToShowPagination: 6
  };

  isLoading = false;

  private _filterModalImport: ImportsFilter;

  private _isMobile = false;
  private _checkStatus = [ImportStatus.UPDATED, ImportStatus.ERROR, ImportStatus.INTERRUPTED];
  private _destroy = new Subject<void>();

  constructor(
    private _importsService: ImportService,
    private _importUtilsService: ImportUtilsService,
    private _dropdownEventsService: DropdownEventsService,
    private _breakpointObserver: BreakpointObserver,
    private _cdr: ChangeDetectorRef,
    private _communicationService: CommunicationService,
    private _modalUtilsService: ModalUtilsService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _router: Router,
    private _activeRoute: ActivatedRoute
  ) {
    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe(result => {
        this._isMobile = result.matches;
      });

    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = '-Id';
  }

  ngOnInit() {
    this._isMobile ? this.filtersPage.maxPagesToShowPagination = 3 : this.filtersPage.maxPagesToShowPagination = 6;

    this.getTable();
    this.getImports();

    this._communicationService.getEvent().pipe(
      takeUntil(this._destroy)
    ).subscribe({
      next: () => {
        this.getImports();
      }
    });
  }

  getTable() {
    if (!this._isMobile) {
      this.tableColumns = [
        {
          field: 'name',
          isSortable: true,
          header: "NAME",
          routerLink: (importFile: ImportFile) => this._checkStatus.includes(importFile.status as ImportStatus) ?
            `/${RoutesUrl.IMPORTS}/${RoutesUrl.IMPORTS_DETAIL}/${importFile.id}` : null,
          cssClass: (importFile: ImportFile) => this._checkStatus.includes(importFile.status as ImportStatus) ?
            'cursor-pointer color-primary' : '',
        },
        {
          field: 'importerName',
          isSortable: true,
          header: "IMPORT2",
          value: (importFile: ImportFileClient) => this._translateService.instant(importFile.importerName)
        },
        {
          field: 'creationDate',
          isSortable: true,
          header: "DATE",
        },
        {
          field: 'totalRows',
          isSortable: true,
          header: "ELEMENTS",
        },
        {
          field: 'validatedRows',
          isSortable: true,
          header: "VALIDATES",
        },
        {
          field: 'importedRows',
          isSortable: true,
          header: "IMPORTED",
        },
        {
          field: 'status',
          header: "STATUS",
          isSortable: true,
          value: (importFile: ImportFileClient) => importFile.statusClient?.text,
          cssClass: (importFile: ImportFileClient) => importFile.statusClient?.cssClass
        },
        {
          field: 'actionsDynamic', isSortable: false,
          header: "ACTIONS.NAME",
          dotOptions: (importFile: ImportFileClient) => importFile.actions
        }
      ];
    } else {
      this.tableColumns = [
        {
          field: 'name',
          isSortable: true,
          header: "NAME",
          routerLink: (importFile: ImportFileClient) => this._checkStatus.includes(importFile.status as ImportStatus) ?
            `/${RoutesUrl.IMPORTS}/${RoutesUrl.IMPORTS_DETAIL}/${importFile.id}` : null,
          cssClass: (importFile: ImportFileClient) => this._checkStatus.includes(importFile.status as ImportStatus) ?
            'cursor-pointer color-primary' : '',
        },
        {
          field: 'status',
          header: "STATUS",
          isSortable: true,
          value: (importFile: ImportFileClient) => importFile.statusClient?.text,
          cssClass: (importFile: ImportFileClient) => importFile.statusClient?.cssClass
        },
        {
          field: 'actionsDynamic', isSortable: false,
          header: "ACTIONS.NAME",
          dotOptions: (importFile: ImportFileClient) => importFile.actions
        }
      ];
    }
    this._cdr.detectChanges();
  }

  getImports(nameContains?: string) {
    let params: ImportsFilter = {
      start: this.filtersPage.firstElement,
      limit: this.filtersPage.itemsPerPage,
      orderBy: this.filtersPage.orderBy,
    }

    if (nameContains) {
      params.nameContains = nameContains;
    }

    if (this._filterModalImport) {
      params = { ...params, ...this._filterModalImport };
    }

    this.isLoading = true;

    this._importsService.listImports$(params).pipe(
      takeUntil(this._destroy)
    ).subscribe({
      next: response => {
        this.tableRows?.forEach(row => this._dropdownEventsService.removeSubscriptions(row.id));
        
        this.tableRows = response.items.map((importFile) => {
          importFile = this._importUtilsService.setDateImport(importFile);
          importFile = this._importUtilsService.setStatusBadgeImport(importFile);
          importFile = this._importUtilsService.setActionsImport(importFile);
          return importFile;
        });

        this.onActionTable();

        this.filtersPage.totalRows = response.count;
        this.filtersPage.totalPage = Math.ceil(this.filtersPage.totalRows / this.filtersPage.itemsPerPage);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }

  onActionTable() {
    this.tableRows.forEach((row) => {

      this._dropdownEventsService.getSelectedOptionOnClick(row.id).pipe(
        takeUntil(this._destroy)
      ).subscribe(
        (option) => {

          if (option && option.value === 'edit') {
            let importFile = {
              ...row,
            } as ImportFile;
            this._modalUtilsService.openImportFileModal(importFile).pipe(
              takeUntil(this._destroy)
            ).subscribe();
          }

          if (option && option.value === 'interrupt') {
            this._importsService.interruptImport$(row.id).pipe(
              takeUntil(this._destroy)
            ).subscribe({
              next: (response) => {
                if (response.status == 200) {
                  this.getImports();
                  this._toastrService.success(this._translateService.instant('IMPORTS.MESSAGES.SUCCESS_IMPORT_INTERRUPTED'));
                }
              }
            });
          }

          if (option && option.value === 'delete') {

          }

          if (option && option.value == 'detail') {
            this._router.navigate([`${RoutesUrl.IMPORTS_DETAIL}/${row.id}`], { relativeTo: this._activeRoute });
          }
        }
      );
    });
  }

  onFilterModalHeaderChanged(filter: ImportsFilter) {
    this._filterModalImport = filter;
    this.getImports();
  }

  onSortColumnHeader(columnField: string) {
    this.filtersPage.orderBy = columnField;
    this.getImports();
  }

  onPageChange($newPage: number) {
    this.filtersPage.page = $newPage;
    this.filtersPage.firstElement = ($newPage - 1) * this.filtersPage.itemsPerPage;
    this.getImports();
  }

  onPageSizeChange(newSize: number): void {
    this.filtersPage.itemsPerPage = newSize;
    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = null;
    this.getImports();
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
