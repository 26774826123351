<div class="filters mb-3">
    <div class="filters-section-left btn-group">
        <h3 class="table-title">{{ titleTable | translate}}</h3>
    </div>
  
    <div class="filters-section-right">
      <form [formGroup]="filterForm">
  
        <div class="input-group d-flex gap-1">
  
          <dropdown-primary 
            [(selectedOption)]="filterHeader"
            [emitNgOnInit]="false"
            [options]="listTableFilterHeaders"
            (selectedOptionChange)="selectedOption($event)"
            [iconClass]="'bi bi-chevron-down'"
            [cssClass]="'btn-lightgray rounded-1'">
          </dropdown-primary>
  
          <input type="text" class="form-control rounded-1" formControlName="inputFilter">
  
        </div>
      </form>

      <button-first-type 
          [cssClass]="'btn-gs-secondary-icon'"
          [iconClass]="'bi bi-funnel'"
          [buttonText]= "'FILTERR'"
          (click)="onFilterClick()">
      </button-first-type>

      <button-first-type 
        [cssClass]="'btn-gs-secondary-icon'"
        [iconClass]="'bi bi-download'"
        [buttonText]= "'EXPORT' | translate"
        (click)="onClickExport()">
      </button-first-type>

      @if (hasManageAssetPermission) {
        <dropdown-primary 
          [emitNgOnInit]="false"
          [selectedOption]="{ value: '', text: 'ACTIONS.NAME' | translate }"
          [options]="listMassiveActions"
          [isStaticSelectOption] = "true"
          (selectedOptionChange)="selectMassiveOption($event)"
          [iconClass]="'bi bi-three-dots-vertical'"
          [cssClass]="'btn-gs-primary-icon flex-row-reverse'">
        </dropdown-primary>
      }
    </div>

  </div>