import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { MeService } from 'src/app/shared/api-services/me.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import * as Permissions from 'src/app/shared/constant/permissions';

@Component({
  selector: 'app-actions-modal',
  templateUrl: './actions-modal.component.html',
  styleUrls: ['./actions-modal.component.scss']
})
export class ActionsModalComponent implements OnInit, OnDestroy {
  tenantId = this._tenantService.getTenantId();

  actions: ActionItems[];
  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _meService: MeService,
    private _modalUtilsService: ModalUtilsService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this._meService.getMePermissions$(this.tenantId)
      .pipe(takeUntil(this._destroy$))
      .subscribe(response => {
        this.setActions();
        this.actions = this.checkPermissions(response.permissions, this.actions);
      });
  }

  private setActions() {
    this.actions = [
      {
        sector: 'bookings/my-bookings',
        header: 'MODAL_ACTIONS.BOOKING_NOW',
        description: 'MODAL_ACTIONS.BOOKING_NOW_DESCRIPTION',
        icon: 'bi-bookmark',
        permissions: [Permissions.CAN_MANAGE_BOOKINGS],
        action: () => this.openBookingModal()
      },
      {
        sector: 'activity/tasks',
        header: 'MODAL_ACTIONS.CREATE_TASK',
        description: 'MODAL_ACTIONS.CREATE_TASK_DESCRIPTION',
        icon: 'icon-task-vectore',
        permissions: [Permissions.CAN_MANAGE_TASKS],
        action: () => this.openCreateTaskModal()
      },
      {
        sector: 'activity/costs',
        header: 'MODAL_ACTIONS.CREATE_COST',
        description: 'MODAL_ACTIONS.CREATE_COST_DESCRIPTION',
        icon: 'bi-wallet2',
        permissions: [Permissions.CAN_MANAGE_COSTS],
        action: () => this.openCreateTaskModal(true)
      },
      {
        sector: 'vehicles',
        header: 'MODAL_ACTIONS.ADD_VEHICLE',
        description: 'MODAL_ACTIONS.ADD_VEHICLE_DESCRIPTION',
        icon: 'bi-truck',
        permissions: [Permissions.CAN_MANAGE_VEHICLES],
        action: () => this.openVehicleModal()
      },
      {
        sector: 'page-assets',
        header: 'MODAL_ACTIONS.ADD_ASSET',
        description: 'MODAL_ACTIONS.ADD_ASSET_DESCRIPTION',
        icon: 'bi-layers',
        permissions: [Permissions.CAN_MANAGE_ASSETS],
        action: () => this.openCreateAssetModal()
      },
      {
        sector: 'imports',
        header: 'MODAL_ACTIONS.IMPORT_TRACE',
        description: 'MODAL_ACTIONS.IMPORT_TRACE_DESCRIPTION',
        icon: 'bi-download',
        permissions: [Permissions.CAN_MANAGE_IMPORTS],
        action: () => this.openImportModal()
      },
      {
        sector: 'imports',
        header: 'MODAL_ACTIONS.IMPORT_DOWNLOAD_TRACE',
        description: 'MODAL_ACTIONS.IMPORT_DOWNLOAD_TRACE_DESCRIPTION',
        icon: 'bi-file-earmark-excel',
        permissions: [Permissions.CAN_MANAGE_IMPORTS],
        action: () => this.openImportTraceDownloadModal()
      }
    ];
  }

  private checkPermissions(permissions: string[], actions: ActionItems[]) {
    return actions.filter(action => {
      // Se l'elemento non ha permessi e non ha figli, mostra sempre
      if (!action.permissions) {
        return true;
      }

      // Controlla se l'utente ha almeno uno dei permessi richiesti per la pagina
      const hasPermission = permissions.some(permission => action.permissions?.includes(permission));

      // Mostra l'elemento se ha permessi corrispondenti
      return hasPermission;
    });
  }

  openBookingModal() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openBookingModal().subscribe();
    }, 200);
  }

  openCreateTaskModal(isCost: boolean = false) {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openCreateTaskModal(isCost).subscribe();
    }, 200)
  }

  openVehicleModal() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openVehicleModal().subscribe();
    }, 200);
  }

  openCreateAssetModal() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openAssetModal().subscribe();
    }, 200);
  }

  openImportModal() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openCreateImportModal().subscribe();
    }, 200);
  }

  openImportTraceDownloadModal() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openDownloadTraceImportModal().subscribe();
    }, 200);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

interface ActionItems {
  sector: string;
  header: string;
  description: string;
  icon?: string;
  permissions?: string[];
  action?: () => void;
}