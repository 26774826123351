<div class="container-fluid bg-home p-3" *ngIf="booking$ | async as booking">
    <div class="header d-flex gap-2 align-items-center mb-2">
        <h3 class="title m-0">{{booking?.title}}</h3>
        <div [ngClass]="[booking?.statusClient?.cssClass ? booking?.statusClient?.cssClass : '']">{{booking?.statusClient?.text}}</div>
    </div> 

    <card-booking-usage-actions 
        [bookingId]="bookingId"
        [isFleetManager]="isFleetManager">
    </card-booking-usage-actions>

    <ul class="nav nav-tabs mb-4" role="tablist">
    
        <li class="nav-item" role="presentation">
            <button class="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview" type="button"
                role="tab" aria-controls="overview" aria-selected="true">
                {{'OVERVIEW' | translate}}
            </button>
        </li>

        <li class="nav-item" role="presentation">
            <button class="nav-link" id="tasks-tab" data-bs-toggle="tab" data-bs-target="#tasks" type="button"
                role="tab" aria-controls="tasks" aria-selected="false">
                {{'TASK' | translate}}
            </button>
        </li>

        <li class="nav-item" role="presentation">
            <button class="nav-link" id="usages-tab" data-bs-toggle="tab" data-bs-target="#usages" type="button"
                role="tab" aria-controls="usages" aria-selected="false">
                {{'USAGES' | translate}}
            </button>
        </li>

    </ul>
    <div class="tab-content">

        <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
            
            <booking-detail-overview 
                [booking]="booking"
                [isFleetManager]="isFleetManager" 
                (updatePage)="ngOnInit()">
            </booking-detail-overview>

        </div>

        <div class="tab-pane fade" id="tasks" role="tabpanel" aria-labelledby="tasks-tab">

            <div class="container-taks">
                <task-table [tableId]="'booking-detail-task-table'" [bookingId]="bookingId" [title]="'TASK' | translate" [listTableFilterTasks]="listTableFilterTasks"></task-table>
            </div>
        </div>

        <div class="tab-pane fade" id="usages" role="tabpanel" aria-labelledby="usages-tab">
            <div class="container-usages">
                <vehicle-usages-table [titleTable]="'VEHICLE_USAGES.NAME'" [bookingId]="bookingId"></vehicle-usages-table>
            </div>
        </div>

    </div>
</div>