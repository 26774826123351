<div class="navbar" *ngIf="!isMobile; else mobileNavbar">

    <div class="container-navbar">
        <div class="page-name">{{namePage | translate}}</div>       
    
        <div class="right-section">
            @if (showIconImports) {
                <button class="bi bi-download btn-gs-primary-noborder" (click)="navigateToImports()"></button>
            }

            <div class="container-notification dropdown">
                
                <button class="bi bi-bell btn-gs-primary-noborder" id="notificationDropdown" role="button" 
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="bi-bell-news" *ngIf="hasNews"></div>
                </button>

                <notification 
                    class="notification dropdown-menu dropdown-menu-end mt-3" 
                    aria-labelledby="notificationDropdown"
                    (haveNotificationsUnReadEmit)="markButtonNews($event)">
                </notification>
                
            </div>

            <div class="container-profile dropdown">
                
                <a class="nav-link profile dropdown-toggle" id="userDropdown" role="button" 
                data-bs-toggle="dropdown" aria-expanded="false">

                    <div class="avatar-placeholder-navbar person-placeholder">
                        <img alt="image-profile" class="image-profile" *ngIf="srcImage | async as safeUrl" [src]="safeUrl">
                    </div>

                    <span>
                        {{displayName | capitalize}}
                    </span>

                    <i class="bi bi-caret-down-fill"></i>
                </a>

                <ul class="dropdown-menu dropdown-menu-end mt-3" aria-labelledby="userDropdown">
                    <li><a class="dropdown-item" (click)="editProfileModal()"><i class="bi bi-person me-2"></i>{{'EDIT_PROFILE' | translate}}</a></li>
                    <li><a class="dropdown-item text-danger" (click)="logout()"><i class="bi bi-box-arrow-right me-2"></i>{{'LOGOUT' | translate}}</a></li>
                </ul>
                
            </div>
        </div>
        
    </div>

    <breadcrumb></breadcrumb>

</div>

<ng-template #mobileNavbar>
    <div class="navbar navbar-mobile">

        <div class="container-navbar-mobile">

            <div class="left-section">
                <div class="logo-vectore"></div>
            </div>

            <div class="right-section d-flex gap-2">

                <div class="avatar-placeholder-navbar-mobile person-placeholder">
                    <img alt="image-profile" class="image-profile" *ngIf="srcImage | async as safeUrl" [src]="safeUrl">
                </div>
                
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
            </div>

            
        </div>

        <breadcrumb></breadcrumb>

        <div class="container-sidebar-mobile">

            <div class="collapse sidebar-collapse" id="navbarScroll">
                <ul class="sidebar-list" style="--bs-scroll-height: 100px;">

                    <li class="sidebar-item" *ngFor="let item of listItemsSidebar" data-toggle="tooltip" data-placement="right" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">

                        <ng-container *ngIf="item.children; else singleLink">
                            <a class="sidebar-item-link-with-children collapsed" type="button" data-bs-toggle="collapse" 
                                [attr.data-bs-target]="'#' + item.label" aria-expanded="false" aria-controls="collapseExample"
                                (click)="toggleChevron(item)">

                                <div class="accordion-left">                                    
                                    <i [class]="item.icon"></i>
                                    <span>{{item.label}}</span>
                                </div>
                                <i [ngClass]="[item.children ? (item.isExpanded ? 'bi-chevron-up rotate-down' : 'bi-chevron-up rotate-up') : '']"></i>

                            </a>
                            
                            <div class="collapse" [id]="item.label">
                                <div class="accordion-body">
                                    <ul>
                                        <li class="sidebar-item-child" *ngFor="let child of item.children" routerLinkActive="active-child" [routerLinkActiveOptions]="{exact: true}">
                                            <a [routerLink]="child.routerLink" (click)="collapseNavbar()">{{child.label}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </ng-container>

                        <ng-template #singleLink>
                          <a class="sidebar-item-link" [routerLink]="item.routerLink" (click)="collapseNavbar()">
                            <i [class]="item.icon"></i>
                            <span>{{item.label}}</span>
                          </a>
                        </ng-template>

                    </li> 
                    
                    <li class="sidebar-item" data-toggle="tooltip" data-placement="right" title="Logout">
                        <a class="sidebar-item-link" href="javascript:void(0)" type="button" (click)="logout()">
                            <i class='bi bi-box-arrow-right text-danger mb-0'></i>
                            <span class="text-danger links_name">Logout</span>
                        </a>
                    </li>

                </ul>
            </div>

        </div>

    </div>
</ng-template>