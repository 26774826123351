import { Component } from '@angular/core';
import { BookingModalService } from '../booking-modal.service';
import { SafeUrl } from '@angular/platform-browser';
import { Observable, of, Subject, takeUntil, tap } from 'rxjs';
import { UsersService } from 'src/app/shared/api-services/users.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { BookingService } from 'src/app/shared/api-services/booking.service';
import { VehicleBooking } from 'src/app/shared/models/booking/booking';
import { BookingVehicleFilter } from 'src/app/shared/models/booking/booking-filter';
import { VehicleBookingResponse } from 'src/app/shared/models/booking/booking-response';
import { Vehicle } from 'src/app/shared/models/vehicle/vehicle';

@Component({
  selector: 'booking-modal-step3',
  templateUrl: './booking-modal-step3.component.html',
  styleUrl: './booking-modal-step3.component.scss'
})
export class BookingModalStep3Component {
  private _tenantId: string;

  get booking() {
    return this._bookingModalNewService.booking;
  }
  get bookingType() {
    return this._bookingModalNewService.bookingType;
  }

  vehiclesToShow: VehicleBooking[];

  imgSrc: Observable<SafeUrl>;

  private _destroy = new Subject<void>();

  constructor(
    private _bookingModalNewService: BookingModalService,
    private _tenantService: TenantService,
    private _usersService: UsersService,
    private _bookingsService: BookingService
  ) {
    this._tenantId = this._tenantService.getTenantId();
  }

  ngOnInit(): void {
    this._bookingModalNewService.setStep3Component(this);
    this.getUserImage();
    if (!this.bookingType?.enableAlternatives) {
      this.getVehiclesToShow();
    }
  }

  onChangeVehicleChecked(vehicles: VehicleBooking[]) {
    this.vehiclesToShow = vehicles;
  }

  checkIfAnyVehicleChecked() {
    return this.vehiclesToShow?.some((vehicle: VehicleBooking) => vehicle.vehicle.isChecked);
  }

  getVehicleChecked() : Vehicle {
    return this.vehiclesToShow?.find((vehicle: VehicleBooking) => vehicle.vehicle.isChecked)?.vehicle;
  }

  deleteBooking() {
    this._bookingModalNewService.deleteBooking();
  }

  private getVehiclesToShow() {
    let params: BookingVehicleFilter = {
      includeMake: true,
      includeModel: true,
      includeVersion: true,
    };
    this._bookingsService.getVehiclesAvailableForBooking$(this.booking.id, params)
      .pipe(
        takeUntil(this._destroy),
        tap((response: VehicleBookingResponse) => {
          if (response.count > 0) {
            this.vehiclesToShow = response.items.map((vehicle: VehicleBooking) => {
              vehicle.vehicle.isChecked = vehicle.vehicle.id === this.booking.vehicleId;
              return vehicle;
            });
          } else {
            this._bookingModalNewService.noVehicleAvailable();
          }
        })
      )
      .subscribe();
  }

  private getUserImage() {
    this.imgSrc = this.booking?.user?.id ?
      this._usersService.getUserImage$(this._tenantId, this.booking?.user?.id, 20, 20) : of(null);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
    this._bookingModalNewService.clearAll();
  }

}
