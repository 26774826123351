<div class="container-table">
    <div class="card border-0 p-3">

        <tracks-table-filter
            [isLoading]="isLoading"
            (onFilterChanged)="getDataForm($event)" 
            (showHoverMapEvent)="showHoverMap()"
            (exportEvent)="getExport()">
        </tracks-table-filter>

        <dynamic-table
            [isLoading]="isLoading"
            [columns]="tableColumns" [rows]="tableRows"
            [totalItems]="filtersPage.totalRows" [itemsPerPage]="filtersPage.itemsPerPage" 
            [maxPagesToShowPagination]="filtersPage.maxPagesToShowPagination" (pageChanged)="onPageChange($event)"
            [listRowToShowPerPage]="filtersPage.listRowToShowPerPage" 
            (pageSizeChanged)="onPageSizeChange($event)"
            (sortColumn)="onSortColumnHeader($event)">
        </dynamic-table>

    </div>
</div>