import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { ModalUtilsService } from 'src/app/shared/utilities/modal-utils.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'task-table-filters',
  templateUrl: './task-table-filters.component.html',
  styleUrls: ['./task-table-filters.component.scss']
})
export class TaskTableFiltersComponent {
  @Input() userId?: string;
  @Input() hasManageTaskPermission: boolean = false;

  @Input() titleTable?: string;

  @Input() listTableFilterTasks!: DropdownOption[];
  filterTask!: DropdownOption;

  listTableFilterHeaders!: DropdownOption[];
  filterHeader: DropdownOption;

  isMobile = false;

  listMassiveActions: DropdownOption[];

  @Output() filterTableChanged = new EventEmitter<string>();
  @Output() filterTableHeaderChanged = new EventEmitter<FormGroup>();

  @Output() exportEvent = new EventEmitter<any>();
  @Output() onMassiveAction = new EventEmitter<string>();

  filterForm: FormGroup = new FormGroup({
    headerToFilter: new FormControl(),
    inputFilter: new FormControl(),
    startDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/)),
    endDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/))
  });

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _translateService: TranslateService,
    private _modalUtilsService: ModalUtilsService,
    private _breakpointObserver: BreakpointObserver
  ) {
    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.isMobile = result.matches;
      });
  }

  ngOnInit(): void {
    if (this.listTableFilterTasks.length > 0) {
      this.filterTask = JSON.parse(JSON.stringify(this.listTableFilterTasks[0]));
    }

    this.listTableFilterHeaders = this._translateService.instant('TASKS.SECTION5.DYNAMIC_TABLE.FILTERS.FILTER_HEADERS');
    if (this.listTableFilterHeaders.length > 0) {
      this.filterHeader = JSON.parse(JSON.stringify(this.listTableFilterHeaders[0]));
    }

    this.listMassiveActions = this._translateService.instant('MASSIVE_ACTIONS');

    this.filterForm.patchValue({ headerToFilter: this.filterHeader.value });
    this.filterForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.filterTableHeaderChanged.emit(this.filterForm);
      });
  }

  selectedOption($event: DropdownOption) {
    this.filterHeader = $event;
    this.filterForm.patchValue({ headerToFilter: $event.value });
  }

  selectMassiveOption($event: DropdownOption) {
    this.onMassiveAction.emit($event.value);
  }

  onClickExport() {
    this.exportEvent.emit();
  }

  onFilterTableChange(selectedOption: DropdownOption) {
    this.filterTask = selectedOption;
    this.filterTableChanged.emit(selectedOption.value);
  }

  onFilterClick() {
    this._modalUtilsService.openTaskFilterModal('filter-task-modal')
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
