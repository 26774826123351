<div class="filters mb-3">
    <div class="filters-section-left btn-group">
      <form [formGroup]="trackForm" class="d-flex gap-2 align-items-center">
  
        <div class="input-group d-flex gap-2">
  
          @if (trackForm.get('isAdvanced')?.value) {
            <div class="input-group text-black-50 d-flex align-items-center border rounded">
    
              <input type="text" class="from-date form-control border-0" [placeholder]="'START_DATE' | translate" 
                bsDatepicker formControlName="startDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
              <i class="bi bi-arrow-right"></i>
      
              <input type="text" class="to-date form-control border-0" [placeholder]="'END_DATE' | translate" 
                bsDatepicker formControlName="endDate" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }">
              <i class="bi bi-calendar-check px-3"></i>
              
            </div>
          }

          @if (!trackForm.get('isAdvanced')?.value) {
            <div class="input-group d-flex align-items-center border rounded">
                            
              <input type="text" class="form-control expiration-date border-0" id="usageEndDate" [placeholder]="'SELECT_DATE' | translate"
                  bsDatepicker [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY' }" placement="bottom" formControlName="date">

              <i class="icon bi bi-calendar-check text-black-50"></i>
            </div>
          }
        </div>
        <p-inputSwitch formControlName="isAdvanced" class="custom-input-switch" (onChange)="onChangeAdvanced($event)" [pTooltip]="'ADVANCED_FILTER' | translate"/>
      </form>
    </div>

    <div class="filters-section-right">

      <button-first-type 
          [cssClass]="'btn-gs-primary-icon'"
          [iconClass]="'bi bi-map'" 
          [buttonText]= "'SHOW_HOVER_MAP' | translate"
          [isDisable]="isLoading"
          (click)="onClickShowHoverMap()">
      </button-first-type>
      
      <button-first-type 
          [cssClass]="'btn-gs-secondary-icon'"
          [iconClass]="'bi bi-download'" 
          [buttonText]= "'EXPORT' | translate"
          (click)="onClickExport()">
      </button-first-type>
    </div>  
</div>