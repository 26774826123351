<div class="card-booking-modal p-1">
    <div class="card-create-booking-header p-3">
        <div class="card-create-booking-title d-flex align-items-center justify-content-between">            
            <h2 class="modal-title title fs-5 pull-left">{{'BOOKINGS.NAME' | translate}}</h2>
            <button type="button" class="btn-close pull-right" aria-label="Close" (click)="closeModal()"></button>
        </div>
        <booking-modal-stepper></booking-modal-stepper>
    </div>

    <div class="card-create-booking-body">
        @for (item of bookingModalStatus; track item.id) {
            <div *ngIf="currentStep === item.id" [@slideAnimation]>
                <ng-container *ngComponentOutlet="item.component"></ng-container>
            </div>
        }
    </div>

    <booking-modal-footer [bsModalRef]="bsModalRef"></booking-modal-footer>
</div>