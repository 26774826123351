<div class="container-tabs">
    <ul class="nav nav-tabs" role="tablist">

        <li class="nav-item">
            <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#date-expiration" type="button"
                role="tab" aria-controls="date-expiration" aria-selected="true">
                <i class="bi bi-calendar"></i>
                 {{'DATE' | translate}}
            </button>
        </li>

        <li class="nav-item">
            <button class="nav-link" data-bs-toggle="tab" data-bs-target="#mileage-expiration" type="button" role="tab"
                aria-controls="mileage-expiration" aria-selected="false">
                <i class="bi bi-speedometer2"></i>
                 Km
            </button>
        </li>
    </ul>

    <div class="tab-content">
        
        <div class="tab-pane fade show active" id="date-expiration" role="tabpanel">
            <div *ngIf="listTaskExpirationDate && listTaskExpirationDate.length > 0; else noItemsTemplate">
                <div *ngFor="let task of listTaskExpirationDate">
                    <div class="container-task" [routerLink]="task.link" [queryParams]="task.queryParams"
                        [ngClass]="{'cursor-pointer': task.link}">
                        <div class="task-left">
                            <div [class]="'avatar-placeholder-sm vehicle-placeholder ' + task.cssImageVehicle">
                                <img *ngIf="task.srcImageVehicle | async as imageUrl" [src]="imageUrl" alt="vehicle-avatar">
                            </div>
                        </div>
        
                        <div class="task-right">
        
                            <div class="task-header">
                                <div class="task-title">
                                    {{task.title}}
                                </div>
                                <div class="task-title-side">{{task.sideTitle}}</div>
                            </div>
        
                            <div [class]="'task-content ' + task.contentClassCss">
                                {{ task.content }}
                            </div>
        
                            <div class="task-footer">
                                <div [class]="task.userDisplayNameClassCss">
                                    <div *ngIf="!task.userDisplayName">+ {{'TASKS.ASSIGN_TASK' | translate}}</div>
        
                                    <div class="avatar-placeholder-xs person-placeholder" *ngIf="task.srcImageUser && task.userDisplayName">
                                        <img *ngIf="task.srcImageUser | async as imageUrl" [src]="imageUrl" alt="user-avatar">
                                    </div>
                                    <span>{{task.userDisplayName }}</span>
                                </div>
                                <div [class]="task.taskStatusClient?.cssClass">{{task.taskStatusClient?.text}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-pane fade" id="mileage-expiration" role="tabpanel">
            <div *ngIf="listTaskExpirationMileage && listTaskExpirationMileage.length > 0; else noItemsTemplate">
                <div class="container-task" *ngFor="let task of listTaskExpirationMileage">
                    <div class="task-left">
                        <div class="avatar-placeholder-sm person-placeholder">
                            <img *ngIf="task.srcImageVehicle | async as imageUrl" [src]="imageUrl" alt="vehicle-avatar">
                        </div>
                    </div>
    
                    <div class="task-right">
    
                        <div class="task-header" [routerLink]="task.link">
                            <div class="task-title">
                                {{task.title}}
                            </div>
                            <div class="task-title-side">{{task.sideTitle}}</div>
                        </div>
    
                        <div [class]="'task-content ' + task.contentClassCss">
                            {{ task.content }}
                        </div>
    
                        <div class="task-footer">
                            <div [class]="task.userDisplayNameClassCss">
                                <div *ngIf="!task.userDisplayName">+ {{'TASKS.ASSIGN_TASK' | translate}}</div>
    
                                <div class="avatar-placeholder-xs" *ngIf="task.srcImageUser && task.userDisplayName">
                                    <img *ngIf="task.srcImageUser | async as imageUrl" [src]="imageUrl" alt="user-avatar">
                                </div>
                                <span>{{task.userDisplayName }}</span>
                            </div>
                            <div [class]="task.taskStatusClient?.cssClass">{{task.taskStatusClient?.text}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #noItemsTemplate>
            <no-items></no-items>
        </ng-template>

    </div>
    
    <div class="footer">
        <button class="btn-gs-primary-noborder" [routerLink]="['/' + RoutesUrl.TASKS]">{{ 'DASHBOARD.SEE_ALLS' | translate }}</button>
    </div>
</div>
