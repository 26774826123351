<div class="container-table">
    <div class="card border-0 p-3">

        <import-management-data-filter 
            [titleTable]="'MANAGEMENT_DATA' | translate" 
            [buttonImport]="buttonImport"
            (onImportValidated)="startImport()"
            (onImportUpdate)="updateTable()"
            (exportEvent)="getExport()">
        </import-management-data-filter>

        <dynamic-table
            [columns]="tableColumns" 
            [rows]="tableRows" 
            (filterTasks)="getTable()"
            [totalItems]="filtersPage.totalRows" 
            [itemsPerPage]="filtersPage.itemsPerPage" 
            [maxPagesToShowPagination]="filtersPage.maxPagesToShowPagination" 
            (pageChanged)="onPageChange($event)"
            [listRowToShowPerPage]="filtersPage.listRowToShowPerPage" 
            (pageSizeChanged)="onPageSizeChange($event)"
            (sortColumn)="onSortColumnHeader($event)" 
            [stickyLastColumn]="true">
        </dynamic-table>

    </div>
</div>