<form class="d-flex flex-column gap-2 px-3" [formGroup]="form">
    <h5>
        {{ 'BOOKINGS.INSERT_STAGES' | translate }}
    </h5>
    @for (address of listAddress; track address.index) {
        <div class="d-flex gap-3 align-items-center w-100">
            <div class="container-icons">
                @if (address.index === listAddress.length - 1) {
                    <i class="bi bi-geo-alt text-danger"></i>
                } @else {
                    <i class="bi bi-circle text-muted"></i>
                }
            </div>
            <p-autoComplete
                class="flex-grow-1"
                formControlName="address{{address.index}}"
                [showClear]="true"
                [forceSelection]="true"
                [suggestions]="listAddress[address.index].address"
                (completeMethod)="filterAdress($event, address.index)"
                (onClear)="onClearAddress(address.index)"
                (onSelect)="onSelectAddress($event, address.index)"
                optionLabel="name" />

            @if (address.index !== listAddress.length - 1 || address.index < 2) {
                <button class="btn-gs-noborder" [disabled]="true">
                    <i class="bi bi-x-lg"></i>
                </button>
            } @else {
                <button class="btn-gs-noborder" (click)="removeAddress(address.index)">
                    <i class="bi bi-x-lg"></i>
                </button>
            }
        </div>
    }

    <button class="btn-gs-noborder with-content" (click)="addAddress()">
        <i class="bi bi-plus-circle"></i>
        <span class="text-muted">{{'ADD_STAGE' | translate}}</span>
    </button>

    <div class="d-flex gap-3 align-items-center justify-content-center mb-3">
        <div class="d-flex gap-2 pt-2" *ngIf="storageInformation.expectedMileage">
            <label>
                <i class="bi bi-speedometer"></i>
                {{'MILEAGE' | translate}}
            </label>
            <span class="text-muted">
                {{storageInformation.expectedMileage | number:'1.0-0'}}
                {{'BOOKINGS.MILEAGE_UNIT' | translate}}
            </span>
        </div>
        <div class="d-flex gap-2 pt-2" *ngIf="storageInformation.expectedTime">
            <label>
                <i class="bi bi-alarm"></i>
                {{'BOOKINGS.TIME_ESTIMATE' | translate}}
            </label>
            <span class="text-muted">{{storageInformation.expectedTime}} {{'MINUTES' | translate | lowercase}}</span>
        </div>
    </div>

    <div style="height: 500px; border-radius: 10px;" leaflet
        [leafletOptions]="options"
        [leafletMarkerCluster]="markers"
        (leafletMapReady)="onMapReady($event)">
    </div>
</form>
