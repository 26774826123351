import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { TableRow } from '../../table/dynamic-table/dynamic-table-desktop/table-row.interface';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Task } from 'src/app/shared/models/task/task';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, from, Subject, switchMap, tap } from 'rxjs';
import { TasksFilter } from 'src/app/shared/models/task/task-filter';
import { CreateTaskRequest, UpdateTaskCostRequest } from 'src/app/shared/models/task/task-request';

@Component({
  selector: 'duplicate-task-modal',
  templateUrl: './duplicate-task-modal.component.html',
  styleUrl: './duplicate-task-modal.component.scss'
})
export class DuplicateTaskModalComponent implements OnInit, OnDestroy {
  tenantId = this._tenantService.getTenantId();

  @Input() elementsSelected: TableRow[];
  listTasksToDuplicate: Task[] = [];
  listWarningTasks: Task[] = [];

  rangeDateOptions: DropdownOption[] = [];
  rangeDateSelected: DropdownOption;

  onDuplicateProgressStep = false;
  progressBarValue = 0;
  elementsDuplicated: number = 0;
  warningMessage: string;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _tasksService: TasksService,
    private _translateService: TranslateService,
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.elementsSelected.forEach(element => {
      let taskFilter: TasksFilter = {
        includeTaskCost: true,
        includeTaskType: true,
      }
      this._tasksService.getTask$(this.tenantId, element.id, taskFilter).subscribe(task => {
        this.listTasksToDuplicate.push(task);

        if (!task.expirationDate) {
          this.listWarningTasks.push(task);
          this.warningMessage = this._translateService.instant('TASKS.MODAL_DUPLICATE_TASK.ATTENTION_EXPIRATION_DATE', { x: this.listWarningTasks.length });
          return;
        }

        if (!task.taskCost.netCost) {
          this.listWarningTasks.push(task);
          this.warningMessage = this._translateService.instant('TASKS.MODAL_DUPLICATE_TASK.ATTENTION_NET_COST', { x: this.listWarningTasks.length });
          return;
        }

        if (!task.taskCost.grossCost) {
          this.listWarningTasks.push(task);
          this.warningMessage = this._translateService.instant('TASKS.MODAL_DUPLICATE_TASK.ATTENTION_GROSS_COST', { x: this.listWarningTasks.length });
          return;
        }

        if (!task.usageStartDate && task.taskType.hasUsage) {
          this.listWarningTasks.push(task);
          this.warningMessage = this._translateService.instant('TASKS.MODAL_DUPLICATE_TASK.ATTENTION_USAGE_START_DATE', { x: this.listWarningTasks.length });
          return;
        }

        if (!task.usageEndDate && task.taskType.hasUsage) {
          this.listWarningTasks.push(task);
          this.warningMessage = this._translateService.instant('TASKS.MODAL_DUPLICATE_TASK.ATTENTION_USAGE_END_DATE', { x: this.listWarningTasks.length });
          return;
        }
      });
    });

    this.rangeDateOptions = this._translateService.instant('TASKS.MODAL_DUPLICATE_TASK.RANGE_DATE');
    this.rangeDateSelected = JSON.parse(JSON.stringify(this.rangeDateOptions[0]));
  }

  onRangeDateSelected($event: DropdownOption) {
    this.rangeDateSelected = JSON.parse(JSON.stringify($event));
  }

  private getNumberOfMonths(option: string): number {
    switch (option) {
      case 'one_month':
        return 1;
      case 'two_month':
        return 2;
      case 'three_month':
        return 3;
      case 'six_month':
        return 6;
      case 'one_year':
        return 12;
      default:
        return 0;
    }
  }

  duplicateTasks() {
    this.onDuplicateProgressStep = true;

    from(this.listTasksToDuplicate).pipe(
      concatMap(task => {
        let taskRequest: CreateTaskRequest = {
          title: task.title,
          taskTypeId: task.taskTypeId,
          headquarterId: task.headquarterId,
          ...task,
        };

        if (taskRequest.expirationDate) {
          let newExpirationDate = new Date(taskRequest.expirationDate);
          newExpirationDate.setMonth(newExpirationDate.getMonth() + this.getNumberOfMonths(this.rangeDateSelected.value));
          taskRequest.expirationDate = newExpirationDate.toISOString();
        }

        if (taskRequest.usageStartDate && taskRequest.usageEndDate) {
          let newUsageStartDate = new Date(taskRequest.usageStartDate);
          newUsageStartDate.setMonth(newUsageStartDate.getMonth() + this.getNumberOfMonths(this.rangeDateSelected.value));
          taskRequest.usageStartDate = newUsageStartDate.toISOString();

          let newUsageEndDate = new Date(taskRequest.usageEndDate);
          newUsageEndDate.setMonth(newUsageEndDate.getMonth() + this.getNumberOfMonths(this.rangeDateSelected.value));
          taskRequest.usageEndDate = newUsageEndDate.toISOString();
        }

        taskRequest.code = null;

        return this._tasksService.createTask$(this.tenantId, taskRequest, false).pipe(
          switchMap(taskResponse => {
            this.elementsDuplicated++;

            let newTaskCost: UpdateTaskCostRequest = {
              accountingDate: task.taskCost.accountingDate,
              netCost: task.taskCost.netCost,
              grossCost: task.taskCost.grossCost,
            };

            let newAccountingDate = new Date(newTaskCost.accountingDate);
            newAccountingDate.setMonth(newAccountingDate.getMonth() + this.getNumberOfMonths(this.rangeDateSelected.value));
            newTaskCost.accountingDate = newAccountingDate.toISOString();
            return this._tasksService.updateTaskCost$(this.tenantId, taskResponse.id, newTaskCost);
          }),
          tap(() => {
            this.progressBarValue = ((this.listTasksToDuplicate.indexOf(task) + 1) / this.listTasksToDuplicate.length) * 100;
          })
        );
      })
    ).subscribe(() => {
      if (this.elementsDuplicated === this.listTasksToDuplicate.length) {
        this.bsModalRef.hide();
      }
    });
  }


  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
