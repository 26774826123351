import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

export interface ListSwitch {
  id: string;
  text: string;
  checked: boolean;
}

@Component({
  selector: 'list-switches',
  templateUrl: './list-switches.component.html',
  styleUrl: './list-switches.component.scss'
})
export class ListSwitchesComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() listSwitches: ListSwitch[] = [];
  @Input() isCheckedAll: boolean = false;
  @Output() listSwitchesChange = new EventEmitter<ListSwitch[]>();

  allId: string = 'all';

  constructor() {
    this.allId = this.generateAllId();
  }

  ngOnInit() {
    this.isCheckedAll = this.listSwitches.every(sw => !sw.checked);
  }

  ngOnChanges() {
    this.isCheckedAll = this.listSwitches.every(sw => !sw.checked);
  }

  generateAllId() {
    let randomId = Math.random().toString(36).substring(2, 15);
    return 'all' + randomId;
  }

  onSwitchChange(sw: ListSwitch) {
    sw.checked = !sw.checked;
    this.listSwitchesChange.emit(this.listSwitches);
  }

  onAllSelected() {

    this.isCheckedAll = !this.isCheckedAll;
    this.listSwitches.forEach(sw => sw.checked = true);
    this.listSwitchesChange.emit(this.listSwitches);
  }
}
