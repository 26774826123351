<form class="dynamic-edit-modal" [formGroup]="taskEditForm">
    <div class="dynamic-edit-modal-header row">
        <div class="col-10 text">
            <input class="task-title" type="text" formControlName="taskTitle">
            <div class="subtitle task-type" [ngClass]="{'color-danger': task.taskType?.name == null}">
                {{task.taskType?.name ?? 'TASKS.TASK_TYPE_NOT_PRESENT' | translate}}
            </div>
        </div>
        <button type="button" class="col-2 btn-close pull-right mx-2" aria-label="Close" (click)="this.taskEditModal.hide()"></button>
    </div>

    <div class="dynamic-edit-modal-body">
        <div class="form-edit-task d-flex flex-column gap-4">
            <div class="cointainer-actions-button">
                
                <div class="d-flex align-items-center gap-2">

                    <button-love [taskId]="task.id"></button-love>
                    <avatar-group [task]="task"></avatar-group>

                </div>

                <dropdown-actions-dots [task]="task"></dropdown-actions-dots>
            </div>
    
            <div class="container-actions-assign">
                <dropdown-status-task [task]="task"></dropdown-status-task>
                <button-add-user-secondary 
                    [userId]="task.assignedUserId" 
                    [text]="'ADD_ASSIGN'"
                    (userIdChange)="onAssignedUserChange($event)">
                </button-add-user-secondary>
            </div>
    
            <div class="container-schedule">

                <div class="info-schedule" [ngClass]="{'gap-2': !isMobile}">
                    <span *ngIf="!isMobile">{{'TASKS.MODAL_EDIT_TASK.BY_THE_DATE' | translate}}</span>   

                    <button-add-date formControlName="expirationDate"></button-add-date>

                    <div class="form-floating" [ngClass]="{'w-50': isMobile}" *ngIf="task.taskType?.vehicleRequired">                        
                        <input type="number" class="form-control" id="expirationMileage" placeholder="Insert expiration milieage"
                           formControlName="expirationMileage" >

                        <label for="expirationMileage">
                            {{'TASKS.MODAL_EDIT_TASK.ADD_MILEAGE_EXPIRATION' | translate}} 
                            <i class="bi bi-plus-circle ps-1"></i>
                        </label>
                    </div>

                </div> 

                <!-- <progressive-bar [task]="task" [taskEditForm]="taskEditForm"></progressive-bar> -->

                <task-duplicate 
                    *ngIf="this.task.expirationDate !== null && this.task.usageStartDate !== null && this.task.usageEndDate !== null"
                    [task]="task">
                </task-duplicate>

            </div>

            <card-add-costs *ngIf="task.taskType?.hasTCO" [tenantId]="tenantId" [task]="task" [isMobile]="isMobile"></card-add-costs>

            <div class="d-flex flex-wrap gap-2">

                <div class="container-interest" 
                    [ngClass]="{'col': !isMobile, 'w-100': isMobile}">
                    <h3 class="title-interest fs-5">{{'TASKS.MODAL_EDIT_TASK.TITLE_INTEREST' | translate}}</h3>

                    <button-add-vehicle
                        [vehicleId]="task.vehicleId"
                        [text]="'ADD_VEHICLE'"
                        [autoCompleteParams]="{
                            taskTypeId: task.taskTypeId,
                            headquarterId: task.headquarterId
                        }"
                        (vehicleIdChange)="onVehicleChange($event)">
                    </button-add-vehicle>

                    <button-add-asset
                        [assetId]="task.assetId"
                        [text]="'ADD_ASSET'"
                        [autoCompleteParams]="{
                            taskTypeId: task.taskTypeId,
                            headquarterId: task.headquarterId
                        }"
                        (assetIdChange)="onAssetChange($event)">
                    </button-add-asset>

                    <button-add-user-primary
                        [userId]="task.userId"
                        [text]="'ADD_PERSON'"
                        [autoCompleteParams]="{
                            taskTypeId: task.taskTypeId,
                            headquarterId: task.headquarterId
                        }"
                        (userIdChange)="onUserChange($event)">
                    </button-add-user-primary>
    
                </div>

                <card-task-usage-date 
                    *ngIf="task.taskType?.hasUsage"
                    [ngClass]="{'col': !isMobile, 'w-100': isMobile}"
                    [startDateUsage]="task.usageStartDate" 
                    [endDateUsage]="task.usageEndDate" 
                    [isMobile]="isMobile"
                    (dateUsageChange)="addDateUsage($event)">
                </card-task-usage-date>

            </div>

    
            <div class="container-info-tab">
                <ul class="nav nav-tabs" role="tablist">

                    <li class="nav-item">
                        <button class="nav-link" [ngClass]="{'active': statusTabs?.info}" 
                            [attr.aria-selected]="statusTabs?.info"
                            data-bs-toggle="tab" data-bs-target="#info" type="button"
                            role="tab" aria-controls="info">
                            {{'TASKS.MODAL_EDIT_TASK.TABS.INFO' | translate}}
                        </button>
                    </li>

                    <li class="nav-item">
                        <button class="nav-link" [ngClass]="{'active': statusTabs?.comments}"
                            [attr.aria-selected]="statusTabs?.comments"
                            data-bs-toggle="tab" data-bs-target="#comments" type="button"
                            role="tab" aria-controls="comments">
                            {{'TASKS.MODAL_EDIT_TASK.TABS.COMMENTS' | translate}}
                        </button>
                    </li>

                    <li class="nav-item">
                        <button class="nav-link" [ngClass]="{'active': statusTabs?.files}"
                            [attr.aria-selected]="statusTabs?.files"
                            data-bs-toggle="tab" data-bs-target="#files" type="button"
                            role="tab" aria-controls="files">
                            {{'TASKS.MODAL_EDIT_TASK.TABS.FILES' | translate}}
                        </button>
                    </li>

                    <li class="nav-item" *ngIf="task.taskType.hasTCO">
                        <button class="nav-link" [ngClass]="{'active': statusTabs?.linkInvoices}"
                            [attr.aria-selected]="statusTabs?.linkInvoices"
                            data-bs-toggle="tab" data-bs-target="#link-invoices" type="button"
                            role="tab" aria-controls="link-invoices">
                            {{'TASKS.MODAL_EDIT_TASK.TABS.LINK_INVOICES' | translate}}
                        </button>
                    </li>

                </ul>


                <div class="tab-content">

                    <div class="tab-pane fade" id="info" role="tabpanel" 
                        [ngClass]="{'show active': statusTabs?.info}">

                        <div class="d-flex flex-column gap-4">

                            <div class="container-info-task">                                
                                <div class="title">{{'TASK' | translate}}</div>

                                <div class="item d-flex gap-2 fw-400">
                                    <span class="label">{{'HEADQUARTER.NAME' | translate}}</span> 
                                    <div>{{task.headquarter?.name}}</div>
                                </div>

                                <div class="item d-flex gap-2 fw-400">
                                    <span class="label">{{'TASKS.MODAL_EDIT_TASK.DATE_CREATION' | translate}}</span> 
                                    <div>{{task.startDate | date: 'dd-MM-yyyy '}}</div>
                                </div>

                                <div class="item d-flex gap-2 fw-400">
                                    <span class="label">{{'TASKS.MODAL_EDIT_TASK.CREATE_BY' | translate}}</span> 

                                    <div class="d-flex align-items-center gap-1">
                                        <div class="avatar-placeholder-xs person-placeholder d-flex align-items-center justify-content-center">
                                            <img *ngIf="srcUserCreateImage | async as imageUrl" [src]="imageUrl" alt="User Image">
                                        </div>

                                        <div class="start-user">{{task.startUser?.displayName}}</div>
                                    </div>

                                </div>
                            </div>
    
                            <task-description-edit [description]="task.description" (descriptionChange)="addDescription($event)"></task-description-edit>
    
                            <task-information-edit 
                                [tenantId]="tenantId"
                                [task]="task"
                                [taskRequest]="taskRequest">
                            </task-information-edit>

                        </div>

                    </div>

                    <div class="tab-pane fade" id="comments" role="tabpanel" [ngClass]="{'show active': statusTabs?.comments}">
                        <task-section-comments [task]="task"></task-section-comments>
                    </div>

                    <div class="tab-pane fade" id="files" role="tabpanel" [ngClass]="{'show active': statusTabs?.files}">
                        <task-section-files [task]="task"></task-section-files>
                    </div>

                    <div class="tab-pane fade" id="link-invoices" role="tabpanel" [ngClass]="{'show active': statusTabs?.linkInvoices}" *ngIf="task.taskType.hasTCO">
                        <task-link-invoices [taskId]="task.id"></task-link-invoices>
                    </div>

                </div>
            </div>
            
            
            <div class="container-actions-button d-flex gap-2 justify-content-center">
                <button class="btn-gs-secondary" type="button" (click)="this.taskEditModal.hide()">{{'ACTIONS.CANCEL' | translate}}</button>
                <button class="btn-gs-primary" type="button" [disabled]="taskEditForm.invalid" (click)="submitTaskChanges()">{{'ACTIONS.SAVE' | translate}}</button>
            </div>
        </div>
    </div>
</form>
