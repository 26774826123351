import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';

import { VECTORE_API_BASE_URL } from '../constant/app-config';

import { CommonService } from './common.service';
import { ImportFile, ImportField, ImportRow, Importer, MappingExport } from '../models/import/import';
import { ImporterResponse, ImportResponse, ImportRowResponse } from '../models/import/import-response';
import { CreateImportRequest, PatchImportRequest, PatchImportRowRequest } from '../models/import/import-request';
import { ImportRowsFilter, ImportsFilter } from '../models/import/import-filter';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from './tenant.service';
import { MappingModel } from '../models/user/users';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  tenantId = this._tenantService.getTenantId();
  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _toasterService: ToastrService,
    private _translateService: TranslateService,
    private _tenantService: TenantService
  ) { }

  listImporters$(): Observable<ImporterResponse> {
    return this._http.get<ImporterResponse>(`${VECTORE_API_BASE_URL}/${this.tenantId}/importers`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getImporter$(importerName: string): Observable<Importer> {
    return this._http.get<Importer>(`${VECTORE_API_BASE_URL}/${this.tenantId}/importers/${importerName}`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listImporterFields$(importerName: string): Observable<ImportField[]> {
    return this._http.get<ImportField[]>(`${VECTORE_API_BASE_URL}/${this.tenantId}/importers/${importerName}/fields`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  createTemplate$(importerName: string, mapping: MappingExport): Observable<HttpResponse<Blob>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${this.tenantId}/importers/${importerName}/template`, mapping,
      { observe: 'response', responseType: 'blob' })
      .pipe(
        tap({
          error: error => this._commonService.handleErrorDownload(error)
        })
      );
  }

  createImport$(importRequest: CreateImportRequest): Observable<ImportFile> {
    return this._http.post<ImportFile>(`${VECTORE_API_BASE_URL}/${this.tenantId}/imports`, importRequest)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  listImports$(filter: ImportsFilter): Observable<ImportResponse> {
    return this._http.get<ImportResponse>(`${VECTORE_API_BASE_URL}/${this.tenantId}/imports`,
      { params: this._commonService.buildParams(filter) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  getImport$(importId: string): Observable<ImportFile> {
    return this._http.get<ImportFile>(`${VECTORE_API_BASE_URL}/${this.tenantId}/imports/${importId}`)
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  patchImport$(importId: string, patchImportRequest: PatchImportRequest): Observable<ImportFile> {
    return this._http.patch<ImportFile>(`${VECTORE_API_BASE_URL}/${this.tenantId}/imports/${importId}`, patchImportRequest);
  }

  deleteImport$(importId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${VECTORE_API_BASE_URL}/${this.tenantId}/imports/${importId}`, { observe: 'response' })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  interruptImport$(importId: string): Observable<HttpResponse<Object>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${this.tenantId}/imports/${importId}/interrupt`, null, { observe: 'response' })
      .pipe(
        tap({
          error: error => {
            this._toasterService.error(this._translateService.instant(`IMPORTS.ERROR_CODE.${error.error.errorCode}`));
            this._commonService.handleError(error);
          }
        })
      );
  }

  getImportRow$(importId: string, importRowId: string): Observable<ImportRow> {
    return this._http.get<ImportRow>(`${VECTORE_API_BASE_URL}/${this.tenantId}/imports/${importId}/rows/${importRowId}`);
  }

  patchImportRow$(importId: string, importRowId: string, patchImportRowRequest: PatchImportRowRequest): Observable<ImportRow> {
    return this._http.patch<ImportRow>(`${VECTORE_API_BASE_URL}/${this.tenantId}/imports/${importId}/rows/${importRowId}`, patchImportRowRequest);
  }

  listImportRows$(importId: string, filter: ImportRowsFilter): Observable<ImportRowResponse> {
    return this._http.get<ImportRowResponse>(`${VECTORE_API_BASE_URL}/${this.tenantId}/imports/${importId}/rows`,
      { params: this._commonService.buildParams(filter) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

  exportImportRow$(importId: string, filter: ImportRowsFilter, exportData: MappingModel): Observable<HttpResponse<Blob>> {
    return this._http.post(`${VECTORE_API_BASE_URL}/${this.tenantId}/imports/${importId}/export/rows`, exportData,
      { observe: 'response', responseType: 'blob', params: this._commonService.buildParams(filter) })
      .pipe(
        tap({
          error: error => this._commonService.handleErrorDownload(error)
        })
      );
  }
}
