import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { icon, IconOptions, Icon, Map, Marker, marker, Layer, polyline, latLng, latLngBounds } from 'leaflet';
import { CommonService } from '../api-services/common.service';

export interface OsrmResponse {
  code: string;
  routes: {
    distance: number;
    duration: number;
    geometry: {
      coordinates: [number, number][];
    };
  }[];
  waypoints: {
    distance: number;
    location: [number, number];
    name: string;
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class OsrmMapService {

  private osrmUrl = 'https://osrm.vectore.it/route/v1/driving';

  constructor(
    private http: HttpClient,
    private _commonService: CommonService
  ) {}

  getRoute(coordinates: string): Observable<any> {
    const url = `${this.osrmUrl}/${coordinates}?overview=full&geometries=geojson`;
    return this.http.get(url);
  }

  centerViewMap(map: Map, latitude: number, longitude: number, zoom: number = 13) {
    if (map) {
      map.setView([latitude, longitude], zoom);
    }
  }

  setMarker(map: Map, latitude: number, longitude: number, message?: string) : Marker {
    const defaultMark: Icon<IconOptions> = icon({
      iconUrl: '../../../assets/images/marker-map.svg',
      iconSize: [38, 38],
      iconAnchor: [19, 38],
      popupAnchor: [0, -38],
    });

    if (message) {
      let popupContent = `
        <button class="btn-gs-primary d-flex gap-2" tabindex="0" style="align-items: center;">
          <i class="bi bi-car-front-fill"></i> ${message}
        </button>
      `;
      return marker([latitude, longitude], { icon: defaultMark }).bindPopup(popupContent, { maxWidth: 300 }).addTo(map);
    }

    return marker([latitude, longitude], { icon: defaultMark }).addTo(map);
  }

  loadRoute(coordinates: string) : Observable<OsrmResponse> {
    if (!coordinates) {
      return of(null);
    }

    return this.getRoute(coordinates).pipe(
      tap({
        error: (error) => {
          this._commonService.handleError(error);
        }
      })
    )
  }

  fitRouteBounds(map: Map, routeCoordinates: [number, number][]) {
    if (map && routeCoordinates.length > 0) {
      const latLngs = routeCoordinates.map(coord => latLng(coord[1], coord[0]));
      const bounds = latLngBounds(latLngs);
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }
}
