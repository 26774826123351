import * as Sentry from "@sentry/angular-ivy";
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import * as Permissions from 'src/app/shared/constant/permissions';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { DropdownEventsService } from 'src/app/shared/utilities/dropdown-events.service';
import { TableColumn } from '../dynamic-table/dynamic-table-desktop/table-column.interface';
import { TableRow } from '../dynamic-table/dynamic-table-desktop/table-row.interface';
import { FiltersPage } from 'src/app/shared/models/filter-page';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { FormGroup } from '@angular/forms';
import { MappingModel } from "src/app/shared/models/user/users";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Vehicle, VehicleClient } from "src/app/shared/models/vehicle/vehicle";
import { VehiclesService } from "src/app/shared/api-services/vehicles.service";
import { VehicleUtilsService } from "src/app/shared/utilities/vehicle-utils.servic";
import { ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import { FilterModalService } from "../../modals/filter-modal/filter-modal.service";
import { VehiclesFilter } from "src/app/shared/models/vehicle/vehicle-filter";
import { ConfirmModalComponent } from "../../modals/confirm-modal/confirm-modal.component";
import { MeService } from "src/app/shared/api-services/me.service";
import { PermissionResponse } from "src/app/shared/models/me/me-response";

@Component({
  selector: 'vehicles-table',
  templateUrl: './vehicles-table.component.html',
  styleUrls: ['./vehicles-table.component.scss']
})
export class VehiclesTableComponent implements OnInit, OnDestroy {
  tenantId = this._tenantService.getTenantId();

  @Input() titleTable: string;

  @Input() vehicleFilter: VehiclesFilter = {};

  tableColumns: TableColumn[];
  tableRows: TableRow[];

  filterHeader: string;
  filterTask: DropdownOption;

  fitersTable: FormGroup;
  expirationStartDate: string;
  expirationEndDate: string;

  filtersPage: FiltersPage = {
    firstElement: 0,
    page: 1,
    itemsPerPage: 10,
    listRowToShowPerPage: [5, 10, 20, 30, 50, 100],
    totalRows: 0,
    totalPage: 1,
    orderBy: null,
    maxPagesToShowPagination: 6
  };

  isMobile = false;
  hasManageVehiclePermission = false;
  inputFilter: string;

  confirmModal: BsModalRef;

  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _meService: MeService,
    private _vehiclesService: VehiclesService,
    private _vehicleUtilsService: VehicleUtilsService,
    private _filterModalService: FilterModalService,
    private _translateService: TranslateService,
    private _dropdownEventsService: DropdownEventsService,
    private _toastrService: ToastrService,
    private _breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private _communicationService: CommunicationService,
    private _modaltUtilsService: ModalUtilsService,
    private _modalService: BsModalService,
    public bsModalRef: BsModalRef,
  ) {
    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = '-Id';
  }

  ngOnInit(): void {
    this.isMobile ? this.filtersPage.maxPagesToShowPagination = 3 : this.filtersPage.maxPagesToShowPagination = 6;
    this.getPermissions();
    this.getTable();
    this.applyFilterToTable();

    this._communicationService.getEvent()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          this.getVehicles();
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }

  getTable() {

    this.getVehicles();

    this.tableColumns = [];
    if (!this.isMobile) {
      this.tableColumns = [
        {
          field: 'displayName',
          isSortable: true,
          header: "NAME",
          routerLink: (vehicle: Vehicle) => this.hasManageVehiclePermission ? `/${RoutesUrl.VEHICLES}/${RoutesUrl.VEHICLES_DETAIL}/${vehicle.id}` : null,
          cssClass: () => this.hasManageVehiclePermission ? 'cursor-pointer' : '',
        },
        {
          field: 'makeId',
          isSortable: true,
          header: "VEHICLES.MAKE",
          value: (vehicle: Vehicle) => vehicle.vehicleMake?.name || ''
        },
        {
          field: 'modelId',
          isSortable: true,
          header: "VEHICLES.MODEL",
          value: (vehicle: Vehicle) => vehicle.vehicleModel?.name || ''
        },
        {
          field: 'versionId',
          isSortable: true,
          header: "VEHICLES.VERSION",
          value: (vehicle: Vehicle) => vehicle.vehicleVersion?.name || ''
        },
        {
          field: 'vehicleTypeId',
          isSortable: true,
          header: "TYPOLOGY",
          value: (vehicle: Vehicle) => vehicle.vehicleType?.name || ''
        },
        {
          field: 'licensePlate',
          isSortable: true,
          header: "LICENSE_PLATE",
        },
        {
          field: 'purchaseTypeId',
          isSortable: true,
          header: "VEHICLES.PURCHASE_TYPE",
          value: (vehicle: Vehicle) => vehicle.purchaseType?.name || ''
        },
        {
          field: 'assetsInUse',
          isSortable: true,
          header: "ASSET",
          value: (vehicle: Vehicle) => vehicle.assetsInUse || 0
        },
        {
          field: 'headquarterId',
          isSortable: true,
          header: "HEADQUARTER.NAME",
          value: (vehicle: Vehicle) => vehicle.headquarter?.name || ''
        },
        {
          field: 'locationId',
          isSortable: true,
          header: "LOCATION.NAME",
          value: (vehicle: Vehicle) => vehicle.location?.name || ''
        },
        {
          field: 'mileage',
          isSortable: true,
          header: "MILEAGE",
        },
        {
          field: 'status',
          header: "STATUS",
          value: (vehicle: VehicleClient) => this._vehicleUtilsService.setStatusVehicle(vehicle).statusClient.text,
          tooltip: (vehicle: VehicleClient) => this._vehicleUtilsService.setStatusVehicle(vehicle).statusClient.tooltip,
          cssClass: (vehicle: VehicleClient) => this._vehicleUtilsService.setStatusVehicle(vehicle).statusClient.cssClass
        },
        {
          field: 'actionsDynamic', isSortable: false,
          header: "ACTIONS.NAME",
          dotOptions: (vehicle: VehicleClient) => vehicle.actions
        }
      ];
    } else {
      this.tableColumns = [
        {
          field: 'displayName',
          isSortable: true,
          header: "NAME",
          routerLink: (vehicle: Vehicle) => this.hasManageVehiclePermission ? `/${RoutesUrl.VEHICLES}/${RoutesUrl.VEHICLES_DETAIL}/${vehicle.id}` : null,
          cssClass: () => this.hasManageVehiclePermission ? 'cursor-pointer' : '',
        },
        {
          field: 'status',
          header: "STATUS",
          value: (vehicle: VehicleClient) => this._vehicleUtilsService.setStatusVehicle(vehicle).statusClient.text,
          tooltip: (vehicle: VehicleClient) => this._vehicleUtilsService.setStatusVehicle(vehicle).statusClient.tooltip,
          cssClass: (vehicle: VehicleClient) => this._vehicleUtilsService.setStatusVehicle(vehicle).statusClient.cssClass
        },
        {
          field: 'actionsDynamic', isSortable: false,
          header: "ACTIONS.NAME",
          dotOptions: (vehicle: VehicleClient) => vehicle.actions
        }
      ];
    }
    this._cdr.detectChanges();
  }

  getVehicles() {

    let params: VehiclesFilter = {
      start: this.filtersPage.firstElement,
      limit: this.filtersPage.itemsPerPage,
      orderBy: this.filtersPage.orderBy,
      includeMake: true,
      includeModel: true,
      includeVersion: true,
      includeVehicleType: true,
      includePurchaseType: true,
      includeHeadquarter: true,
      includeLocation: true,
    }

    if (this.vehicleFilter.vehicleTypeId) {
      params.vehicleTypeId = this.vehicleFilter.vehicleTypeId;
    }

    if (this.vehicleFilter.headquarterId) {
      params.headquarterId = this.vehicleFilter.headquarterId;
    }

    if (this.vehicleFilter.locationId) {
      params.locationId = this.vehicleFilter.locationId;
    }

    this.applyFilter(params);

    this._vehiclesService.listVehicles$(this.tenantId, params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          this.tableRows?.forEach(row => this._dropdownEventsService.removeSubscriptions(row.id));
          this.tableRows = response.items.map(vehicle => this._vehicleUtilsService.setActionsVehicls(vehicle));

          this.onActionTable();

          this.filtersPage.totalRows = response.count;
          this.filtersPage.totalPage = Math.ceil(this.filtersPage.totalRows / this.filtersPage.itemsPerPage);
        },
        error: error => {
          Sentry.captureEvent(error);
        }
      });
  }

  getPermissions() {
    this._meService.getMePermissions$(this.tenantId)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response: PermissionResponse) => {
        this.hasManageVehiclePermission = response.permissions.includes(Permissions.CAN_MANAGE_VEHICLES);
        if (!this.hasManageVehiclePermission) {
          this.tableColumns = this.tableColumns.filter(column => column.field !== 'actionsDynamic');
        }
      });
  }

  private applyFilter(params: VehiclesFilter) {
    if (this.fitersTable) {
      if (this.fitersTable.value.headerToFilter && this.fitersTable.value.headerToFilter === 'DisplayName') {
        this.inputFilter = this.fitersTable.value.inputFilter;
        params.displayNameContains = this.inputFilter;
      }

      if (this.fitersTable.value.headerToFilter && this.fitersTable.value.headerToFilter === 'LicensePlate') {
        this.inputFilter = this.fitersTable.value.inputFilter;
        params.licensePlateContains = this.inputFilter;
      }
    }
  }

  getDataForm($event: FormGroup) {
    this.fitersTable = $event;
    this.getVehicles();
  }

  onActionTable() {
    this.tableRows.forEach((row) => {

      this._dropdownEventsService.getSelectedOptionOnClick(row.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (option) => {

            if (option && option.value === 'edit') {
              let vehicle: Vehicle = { id: row.id };
              this._modaltUtilsService.openVehicleModal(vehicle)
                .pipe(takeUntil(this._destroy$))
                .subscribe();
            }

            if (option && option.value === 'delete') {
              let confirmDeleteModal = this._modalService.show(ConfirmModalComponent, {
                class: 'modal-sm',
                animated: true,
                initialState: {
                  data: {
                    content: 'MESSAGES.CONFIRM_DELETE'
                  }
                }
              });

              confirmDeleteModal.content.onConfirm.pipe(
                takeUntil(this._destroy$),
                switchMap(() => this._vehiclesService.deleteVehicle$(this.tenantId, row.id))
              ).subscribe({
                next: (response) => {
                  if (response.status == 204) {
                    this.getVehicles();
                    this._toastrService.success(this._translateService.instant('VEHICLES.MESSAGES.SUCCESS_DETELED'));
                  }
                }
              });
            }

            if (option && option.value == 'detail') {
              this._router.navigate([`${RoutesUrl.VEHICLES_DETAIL}/${row.id}`], { relativeTo: this._activeRoute });
            }
          }
        );
    });
  }

  onMassiveSelections($event: TableRow[]) {
    this.tableRows = $event;
  }

  onMassiveAction($event: string) {
    if ($event === 'delete_selected') {
      let elementsSelected = this.tableRows.filter(element => element.isSelected);
      this.confirmModal = this._modalService.show(ConfirmModalComponent, {
        class: 'modal-sm',
        animated: true,
        initialState: {
          data: {
            content: this._translateService.instant('MODAL_DELETE_CONFIRM.MESSAGE', { x: elementsSelected.length }),
          }
        }
      });

      this.confirmModal.content.onConfirm.pipe(
        takeUntil(this._destroy$),
      )
        .subscribe(() => {
          for (let index = 0; index < elementsSelected.length; index++) {
            this._vehiclesService.deleteVehicle$(this.tenantId, elementsSelected[index].id)
              .pipe(takeUntil(this._destroy$))
              .subscribe({
                next: (response) => {
                  if (response.status == 204) {
                    if (index === elementsSelected.length - 1) {
                      this.getVehicles();
                    }
                    this._toastrService.success(this._translateService.instant('VEHICLES.MESSAGES.SUCCESS_DETELED'));
                  }
                }
              });
          }
        });
    }
  }

  onSortColumnHeader(columnField: string) {
    this.filtersPage.orderBy = columnField;
    this.getVehicles();
  }

  onPageChange($newPage: number) {
    this.filtersPage.page = $newPage;
    this.filtersPage.firstElement = ($newPage - 1) * this.filtersPage.itemsPerPage;
    this.getVehicles();
  }

  onPageSizeChange(newSize: number): void {
    this.filtersPage.itemsPerPage = newSize;
    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = null;
    this.getVehicles();
  }

  private applyFilterToTable() {
    this._filterModalService.getForm$('filter-vehicle-modal')
      .pipe(takeUntil(this._destroy$))
      .subscribe(form => {
        if (form) {
          this.vehicleFilter.vehicleTypeId = form.value.vehicleTypeId ?? null;
          this.vehicleFilter.headquarterId = form.value.headquarterId ?? null;
          this.vehicleFilter.locationId = form.value.locationId ?? null;
          this.getVehicles();
        }
      });
  }

  getExport() {
    let params: VehiclesFilter = {
      versionId: this.vehicleFilter?.versionId ?? null,
      purchaseTypeId: this.vehicleFilter?.purchaseTypeId ?? null
    }
    this._vehiclesService.listVehicleFields$(this.tenantId, params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          let mappings: MappingModel = {
            mappings: [
              { sourceName: 'DisplayName', destName: this._translateService.instant('DisplayName') },
              { sourceName: 'LicensePlate', destName: this._translateService.instant('LicensePlate') },

              { sourceName: 'Headquarter.Name', destName: this._translateService.instant('Headquarter') },
              { sourceName: 'Location.Name', destName: this._translateService.instant('Location') },

              { sourceName: 'FleetEntryValue', destName: this._translateService.instant('FleetEntryValue') },
              { sourceName: 'FleetEntryDate', destName: this._translateService.instant('FleetEntryDate') },
              { sourceName: 'FleetExitDate', destName: this._translateService.instant('FleetExitDate') },

              { sourceName: 'VehicleMake.Name', destName: this._translateService.instant('Make') },
              { sourceName: 'VehicleModel.Name', destName: this._translateService.instant('Model') },
              { sourceName: 'VehicleVersion.Name', destName: this._translateService.instant('Version') },

              { sourceName: 'SourceId', destName: this._translateService.instant('SourceId') },
              { sourceName: 'VehicleType.Name', destName: this._translateService.instant('VehicleType') },
              { sourceName: 'PurchaseType.Name', destName: this._translateService.instant('PurchaseType') },
              { sourceName: 'Mileage', destName: this._translateService.instant('Mileage') },
            ]
          };

          if (response.items && response.items.length > 0) {
            response.items.forEach(field => {
              mappings.mappings.push({ sourceName: `ExtraFields.${field.name}`, destName: this._translateService.instant('ExtraFields.' + field.name) });
            });
          }

          let params: VehiclesFilter = {
            includeMake: true,
            includeModel: true,
            includeVersion: true,
            includeVehicleType: true,
            includePurchaseType: true,
            includeHeadquarter: true,
            includeLocation: true,
            vehicleTypeId: this.vehicleFilter.vehicleTypeId ?? null,
            headquarterId: this.vehicleFilter.headquarterId ?? null,
            locationId: this.vehicleFilter.locationId ?? null,
          }

          this.applyFilter(params);

          this._vehiclesService.exportVehicles$(this.tenantId, mappings, params)
            .pipe(takeUntil(this._destroy$))
            .subscribe({
              next: (response) => {
                const filename = 'export_vehicle.xlsx';
                const blob = new Blob([response.body], { type: response.body.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                link.click();
                window.URL.revokeObjectURL(url);
              },
              error: (error) => {
                Sentry.captureEvent(error);
              }
            });
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }

  ngOnDestroy(): void {
    this._dropdownEventsService.removeSubscriptionsAll();
    this._destroy$.next();
    this._destroy$.complete();
  }
}
