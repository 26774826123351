import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DropdownOption } from '../../../dropdown/dropdown-primary/dropdown.interface';
import { FilterModalService } from '../filter-modal.service';
import { DropdownPrimaryAssetTypeComponent } from '../../../dropdown/dropdown-primary/dropdown-primary-asset-type/dropdown-primary-asset-type.component';

@Component({
  selector: 'filter-asset-modal',
  templateUrl: './filter-asset-modal.component.html',
  styleUrls: ['./filter-asset-modal.component.scss']
})
export class FilterAssetModalComponent implements OnInit {
  id = 'filter-asset-modal';

  form: FormGroup;

  @Input() autoCompleteParams: any = {};

  @ViewChild(DropdownPrimaryAssetTypeComponent) dropdownPrimaryTaskTypeComponent: DropdownPrimaryAssetTypeComponent;

  constructor(
    private _filterTaskModalService: FilterModalService,
    public bsModalRef: BsModalRef
  ) {
    this.form = new FormGroup({
      assetTypeId: new FormControl(null),
    });
  }

  ngOnInit() {
    let form = this._filterTaskModalService.getForm(this.id);
    if (form) {
      this.form = form;
    }
  }

  onAssetTypeSelected($event: DropdownOption) {
    this.form.patchValue({ assetTypeId: $event.value });
  }

  apply() {
    this._filterTaskModalService.applyFilterFromForm(this.id, this.form);
    this.bsModalRef.hide();
  }

  reset() {
    this.form.reset();
    this.dropdownPrimaryTaskTypeComponent.onReset();
    this._filterTaskModalService.applyFilterFromForm(this.id, this.form);
  }
}
