import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

export interface ConfirmModal {
  title?: string;
  titleCssClass?: string;
  content?: string;
  contentCssClass?: string;
  btnCssClassNo?: string;
  btnCssClassYes?: string;
}

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  @Input() data: ConfirmModal;

  @Output() onConfirm = new EventEmitter<boolean>();

  constructor(public confirmModal: BsModalRef) { }

  emitTrue() {
    this.onConfirm.emit(true);
    this.confirmModal.hide();
  }
}