<div class="sidebar bg-white" *ngIf="!isMobile">
    <div class="container-sidebar">

        <a *ngIf="!isMobile" routerLink="/" class="container-logo">
            <div class="logo-vectore"></div>
            <div class="logo-text-vectore"></div>
        </a>

        <ul class="sidebar-list">
            
            @if (hasPermissions) {
                <li id="add-task" class="sidebar-item add-task" data-toggle="tooltip" data-placement="right" title="new" #newButton>
        
                    <a href="javascript:void(0)" type="button"
                        (click)="openModalActions()">
                        <i class="bi bi-plus-circle mb-0"></i>
                        <span>{{'ACTIONS.NEW' | translate}}</span>
                    </a>

                </li>
            }

            <sidebar-button [listItemsSidebar]="listItemsSidebar"></sidebar-button>

            <li class="sidebar-item logout" data-toggle="tooltip" data-placement="right" title="Logout">
                <a href="javascript:void(0)" type="button" (click)="logout()">
                    <i class='bi bi-box-arrow-right text-danger mb-0'></i>
                    <span class="text-danger links_name">{{'LOGOUT' | translate}}</span>
                </a>
            </li>

        </ul>
    </div>
    <div class="version">
        {{copyright}}
        <br>
        {{version}}
    </div>
</div>                                       