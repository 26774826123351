import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { BookingService } from 'src/app/shared/api-services/booking.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { BookingUsage } from 'src/app/shared/models/booking/booking';
import { BookingUsageRequest } from 'src/app/shared/models/booking/booking-request';

export interface BookingCloseUsageModal {
  title?: string;
  content?: string;
}

@Component({
  selector: 'booking-close-usage-modal',
  templateUrl: './booking-close-usage-modal.component.html',
  styleUrl: './booking-close-usage-modal.component.scss'
})
export class BookingCloseUsageModalComponent implements OnInit, OnDestroy {
  tenantId: string = this._tenantService.getTenantId();

  @Input() bookingUsage: BookingUsage;
  @Input() bookingId: string;
  @Output() onConfirm = new EventEmitter<void>();

  form: FormGroup = new FormGroup({
    endMileage: new FormControl(null)
  });

  private _destroy$ = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _bookingService: BookingService,
    public confirmModal: BsModalRef
  ) { }

  ngOnInit(): void {
    this.form.get('endMileage')?.valueChanges.subscribe(() => this.validateEndMileage());
  }

  validateEndMileage() {
    let endMileage = this.form.get('endMileage')?.value;

    if (endMileage && endMileage < this.bookingUsage.startMileage) {
      this.form.get('endMileage')?.setErrors({ 'invalid': true });
    }
  }

  confirm() {
    if (this.form.value.endMileage) {
      let request: BookingUsageRequest = {
        ...this.bookingUsage,
        endMileage: this.form.value.endMileage
      }
      this._bookingService.patchBookingUsage$(this.tenantId, this.bookingId, this.bookingUsage.id, request)
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          this.onConfirm.emit();
          this.confirmModal.hide();
        });
    } else {
      this.onConfirm.emit();
      this.confirmModal.hide();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
