export enum ImportStatus {
    CREATED = "CREATED",
    UPDATING = "UPDATING",
    UPDATED = "UPDATED",
    INTERRUPTED = "INTERRUPTED",
    ERROR = "ERROR"
}

export enum ImportDataStatus {
    VALID = "VALID",
    IMPORTED = "IMPORTED",
    ERROR = "ERROR"
}