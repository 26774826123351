import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersService } from '../../../../../api-services/users.service';
import { TenantService } from '../../../../../api-services/tenant.service';
import { Task } from '../../../../../models/task/task';
import { TasksService } from '../../../../../api-services/tasks.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { TasksFilter } from 'src/app/shared/models/task/task-filter';

@Component({
  selector: 'avatar-group',
  template: `
    <p-avatarGroup *ngIf="avatars && avatars.length > 0">
      <ng-container *ngFor="let avatar of avatars">
        <p-avatar *ngIf="avatar.image" [image]="avatar.image" shape="circle"></p-avatar>
        <p-avatar *ngIf="!avatar.image" [label]="avatar.label" styleClass="mr-2" [style]="{ 'background-color': avatar.backgroundColor, color: '#ffffff' }" shape="circle"></p-avatar>
      </ng-container>
      <p-avatar label="+2" shape="circle" [style]="{ 'background-color': '#9c27b0', color: '#ffffff' }" *ngIf="moreThanFive"></p-avatar>
    </p-avatarGroup>
  `
})
export class AvatarGroupComponent {
  tenantId: string = this._tenantService.getTenantId();
  @Input() task: Task;

  @Input() userIds: Observable<string[]>;
  avatars: Avatar[];

  moreThanFive: boolean = false;

  constructor(
    private _tenantService: TenantService,
    private _usersService: UsersService,
    private _tasksService: TasksService,
    private _communicationService: CommunicationService,
  ) { }

  ngOnInit() {
    this.getWatchers();

    this._communicationService.getSpecificEvent(this.task.id).subscribe({
      next: () => {

        let params: TasksFilter = {
          includeTaskWatchers: true
        }

        this._tasksService.getTask$(this.tenantId, this.task.id, params).subscribe({
          next: response => {
            this.task = response;
            this.getWatchers();
          }
        });
      }
    });

  }

  private getWatchers() {
    this.avatars = [];
    if (this.task && this.task.taskWatchers && this.task.taskWatchers.length > 0) {

      for (let i = 0; i < 5; i++) {
        const user = this.task.taskWatchers[i];

        if (user) {
          let imageUrl = this._usersService.getUserImage$(this.tenantId, user.id, 42, 42, false);
          imageUrl.subscribe({
            next: url => {
              this.avatars.push({
                image: url as string,
                label: user.displayName[0].toUpperCase(),
                backgroundColor: this.randomColor(),
              });
            }
          });
        }
      }

      if (this.task.taskWatcherIds.length > 4) {
        this.moreThanFive = true;
      } else {
        this.moreThanFive = false;
      }
    }
  }

  randomColor(): string {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}

interface Avatar {
  userId?: string;
  image?: string;
  label?: string;
  backgroundColor?: string;
}