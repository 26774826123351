import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Layer, Map, Marker, polyline, tileLayer } from 'leaflet';
import { MapOptions } from 'leaflet';
import { latLng } from 'leaflet';
import { Subject, takeUntil, Observable, distinctUntilChanged, Observer, debounceTime, switchMap, map, of, tap, forkJoin } from 'rxjs';
import { NominatimResponse, NominatimService } from 'src/app/shared/services/nominatim.service';
import { OsrmMapService } from 'src/app/shared/services/osrm-map.service';
import { BookingModalService } from '../booking-modal.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { HeadquartersService } from 'src/app/shared/api-services/headquarters.service';
import { Location as LocationModel, Stage } from 'src/app/shared/models/location/location';
import { BookingRequest } from 'src/app/shared/models/booking/booking-request';
import { AutoCompleteSelectEvent } from 'primeng/autocomplete';
interface Address {
  name: string;
}

@Component({
  selector: 'booking-modal-step2',
  templateUrl: './booking-modal-step2.component.html',
  styleUrl: './booking-modal-step2.component.scss'
})
export class BookingModalStep2Component {
  form: FormGroup = new FormGroup({});
  storageInformation: BookingRequest = {
    stages: []
  };
  listStage: Stage[] = [];

  map: Map;
  options: MapOptions = {
    layers: [
      tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
    ],
    zoom: 10,
    center: latLng(46.4988821, 11.308806)
  };

  routerLayer: Layer;
  markers: Marker[] = [];

  listAddress: { index: number, address: Address[] }[] = [];

  private _destroy = new Subject<void>();

  constructor(
    private _bookingModalNewService: BookingModalService,
    private _headquartersService: HeadquartersService,
    private _osrmService: OsrmMapService,
    private _nominatimService: NominatimService,
  ) { 
  }

  ngOnInit(): void {
    this._bookingModalNewService.setStep2Component(this);
    this.addStartEndAddress();
    this.setForm();
  }

  onMapReady(map: Map) {
    this.map = map;
  }

  addStartEndAddress() {
    this.addAddress();
    this.addAddress();
  }

  addAddress() {
    let index = this.listAddress.length;
    this.listAddress.push({ index: index, address: [] });
    this.form.addControl(`address${index}`, new FormControl(null, Validators.required));
  }

  removeAddress(index: number) {
    this.listAddress.splice(index, 1);
    this.form.removeControl(`address${index}`);
    this.listStage.splice(index, 1);

    if (this.markers[index]) {
      this.map.removeLayer(this.markers[index]);
    }    
    this.markers.splice(index, 1);
    this.storageInformation.stages.splice(index, 1);
    this.setRoute();
  }

  onClearAddress(index: number) {
    this.listStage[index] = null;
    if (this.markers[index]) {
      this.map.removeLayer(this.markers[index]);
    }
    this.markers[index] = null;
    this.storageInformation.stages[index] = null;
  }

  filterAdress(event: { query: string }, index: number) {
    this._nominatimService.searchLocation(event.query, 5).pipe(
      takeUntil(this._destroy),
      map((locations: NominatimResponse[]) => locations.map(location => ({ name: location.display_name })))
    ).subscribe((locations: { name: string }[]) => {
      this.listAddress[index].address = locations;
    });
  }

  onSelectAddress($event: AutoCompleteSelectEvent, index: number) {
    this._nominatimService.searchLocation($event.value.name).pipe(
      takeUntil(this._destroy),
      tap((locations: NominatimResponse[]) => {
        if (this.listStage[index] === undefined || this.listStage[index] === null) {
          this.listStage[index] = {
            latitude: Number(locations[0].lat),
            longitude: Number(locations[0].lon)
          };
        } else {
          this.listStage[index].latitude = Number(locations[0].lat);
          this.listStage[index].longitude = Number(locations[0].lon);
        }

        if (this.markers[index] === undefined || this.markers[index] === null) {
          this.markers[index] = this._osrmService.setMarker(this.map, Number(locations[0].lat), Number(locations[0].lon));
        } else {
          this.markers[index].setLatLng([Number(locations[0].lat), Number(locations[0].lon)]);
        }

        if (this.storageInformation.stages[index] === undefined || this.storageInformation.stages[index] === null) {
          this.storageInformation.stages[index] = $event.value.name;
        } else {
          this.storageInformation.stages[index] = $event.value.name;
        }

        this._osrmService.centerViewMap(this.map, Number(locations[0].lat), Number(locations[0].lon), 18);

        let lastIndex = this.listAddress.length - 1;
        if (this.listStage[0]?.latitude && this.listStage[0]?.longitude && this.listStage[lastIndex]?.latitude && this.listStage[lastIndex]?.longitude) {
          this.setRoute();
        }
      })
    ).subscribe();
  }

  private setForm() {
    let formValue = this._bookingModalNewService.getInformationStep(2);
    if (formValue) {
      this.form.patchValue(formValue);
    } else if (this._bookingModalNewService.booking?.stages?.length > 0) {
      this.storageInformation = JSON.parse(JSON.stringify(this._bookingModalNewService.booking));
      const stageObservables = this._bookingModalNewService.booking.stages.map((stage: string, index: number) => {
        this.form.patchValue({
          [`address${index}`]: stage
        });
        return this._nominatimService.searchLocation(stage).pipe(
          takeUntil(this._destroy),
          tap((locations: NominatimResponse[]) => {
            this.listStage.push({
              latitude: Number(locations[0].lat),
              longitude: Number(locations[0].lon)
            });
            this.markers.push(this._osrmService.setMarker(this.map, Number(locations[0].lat), Number(locations[0].lon)));
          })
        );
      });

      forkJoin(stageObservables).subscribe(() => {
        this.setRoute();
      });
    } else if (this._bookingModalNewService.bookingRequest?.locationId) {
      this._headquartersService.getLocation$(this._bookingModalNewService.bookingRequest.locationId)
        .pipe(
          takeUntil(this._destroy),
          tap((location: LocationModel) => {
            this.form.patchValue({
              address0: location.address
            });
          })
        ).subscribe();
    }
  }

  setRoute() {
    if (!this.listStage || this.listStage.length < 2) {
      return;
    }
    let getFirstStage = JSON.parse(JSON.stringify(this.listStage[0]));
    let tempListStage = JSON.parse(JSON.stringify(this.listStage)) as Stage[];
    tempListStage.push(getFirstStage);

    const coordinates = tempListStage.filter(stage => stage?.latitude && stage?.longitude).map(stage => {
      return `${stage.longitude},${stage.latitude}`;
    }).join(';');

    if (this.routerLayer) {
      this.map.removeLayer(this.routerLayer);
    }

    this._osrmService.loadRoute(coordinates)
      .pipe(
        takeUntil(this._destroy),
        tap((response) => {
          if (response.routes.length > 0) {
            this.routerLayer = polyline(response.routes[0].geometry.coordinates.map((coord: [number, number]) => latLng(coord[1], coord[0])), { color: '#00a2bd', weight: 10 });
            this.map.addLayer(this.routerLayer);
            this._osrmService.fitRouteBounds(this.map, response.routes[0].geometry.coordinates);

            const totalDistance = response.routes.reduce((acc, route) => acc + route.distance, 0) / 1000;
            const totalDurationSeconds = response.routes[0].duration;
            const minutes = Math.floor(totalDurationSeconds / 60);
            
            this.storageInformation.expectedMileage = totalDistance;
            this.storageInformation.expectedTime = minutes;
          }
        })
      )
      .subscribe();
  }

  checkFormValidity() : boolean {
    this.form.markAllAsTouched();

    if (!this.storageInformation.expectedMileage) {
      return false;
    }

    if (this.form.valid) {
      this._bookingModalNewService.saveInformationStep(2, this.form.value);
    }
    return this.form.valid;
  }

  updateBookingRequestStep2() {
    this._bookingModalNewService.setBookingRequestStep2(this.storageInformation);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
