<div class="card card-booking-usages">
    <div class="card-booking-usages-header">
        <i class="bi bi-infinity bg-icon-primary-light default-icon"></i>
        <div class="card-booking-usages-header-title">{{ 'USAGES' | translate}}</div>
    </div>

    <div class="booking-no-usages" *ngIf="!bookingUsageResponse || bookingUsageResponse?.count === 0">
        <svg width="64" height="40" viewBox="0 0 64 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_6612_3364)">
            <rect width="64" height="40" fill="white" fill-opacity="0.01"/>
            <path d="M32 39.7031C49.6731 39.7031 64 36.5923 64 32.755C64 28.9177 49.6731 25.807 32 25.807C14.3269 25.807 0 28.9177 0 32.755C0 36.5923 14.3269 39.7031 32 39.7031Z" fill="#F5F5F5"/>
            <path d="M55 13.6653L44.854 2.24866C44.367 1.47048 43.656 1 42.907 1H21.093C20.344 1 19.633 1.47048 19.146 2.24767L9 13.6663V22.8367H55V13.6653Z" stroke="#CED4DA"/>
            <path d="M41.613 16.8128C41.613 15.2197 42.607 13.9046 43.84 13.9036H55V31.9059C55 34.0132 53.68 35.7402 52.05 35.7402H11.95C10.32 35.7402 9 34.0122 9 31.9059V13.9036H20.16C21.393 13.9036 22.387 15.2167 22.387 16.8098V16.8317C22.387 18.4248 23.392 19.7111 24.624 19.7111H39.376C40.608 19.7111 41.613 18.4128 41.613 16.8198V16.8128Z" fill="#FAFAFA" stroke="#CED4DA"/>
            </g>
            <defs>
            <clipPath id="clip0_6612_3364">
            <rect width="64" height="40" fill="white"/>
            </clipPath>
            </defs>
        </svg> 
        <div class="booking-no-usages-title">{{"BOOKINGS.NO" | translate}} <span>{{"BOOKINGS.USAGE" | translate}}</span></div>
        <div class="booking-no-usages-subtitle">{{"BOOKINGS.NO_USE_SUBTITLE" | translate}}</div>
    </div>

    <div class="booking-usages" *ngIf="bookingUsageResponse?.count > 0">
        <div class="container-booking-usages-item" *ngFor="let usage of bookingUsageResponse.items">

            <div class="d-flex gap-3" *ngIf="usage.endDate">
                <div class="usage-item-left">{{usage.endDate | date: 'dd MMM' }}</div>
    
                <div class="usage-item-right">
                    <div class="usage-item-header" *ngIf="usage.endDate">
                        <div class="avatar-placeholder-xs person-placeholder">
                            <img *ngIf="usage?.user?.srcImage$ | async as imageUrl" [src]="imageUrl" alt="User Image">
                        </div>
                        <div class="usage-item-title"><span>{{ 'BOOKINGS.END_USAGE_VEHICLE' | translate }}</span> {{usage.endDate | date: 'dd MMM yyyy HH:mm' }}</div>
                    </div>
                    <div class="usage-item-content">
                        <div class="status-mileage"></div>
                        <div class="usage-item-title" *ngIf="usage.endMileage">
                            <span>{{ 'BOOKINGS.MILEAGE_INSERTED_END' | translate }}</span>
                            {{usage.endMileage}}
                        </div>
                        <div class="usage-item-title" *ngIf="!usage.endMileage">
                            <span>{{ 'BOOKINGS.MILEAGE_TO_INSERT_END' | translate }}</span>
                            <button-insert *ngIf="!usage?.endMileage" (click)="openModalMileage(usage)"></button-insert>
                            <button class="btn-edit" *ngIf="usage?.endMileage" (click)="openModalMileage(usage)">{{ 'ACTIONS.EDIT' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex gap-3">
                <div class="usage-item-left">{{usage.startDate | date: 'dd MMM' }}</div>

                <div class="usage-item-right">
                    <div class="usage-item-header">
                        <div class="avatar-placeholder-xs person-placeholder">
                            <img *ngIf="usage?.user?.srcImage$ | async as imageUrl" [src]="imageUrl" alt="User Image">
                        </div>
                        <div class="usage-item-title">
                            <span>{{ 'BOOKINGS.START_USAGE_VEHICLE' | translate }}</span> {{usage.startDate | date: 'dd MMM yyyy HH:mm' }}
                        </div>
                    </div>
                    <div class="usage-item-content">
                        <div class="status-mileage"></div>
                        <div class="usage-item-title" *ngIf="usage.startMileage">
                            <span>{{ 'BOOKINGS.MILEAGE_INSERTED_START' | translate }}</span>
                            {{usage.startMileage}}
                        </div>
                        <div class="usage-item-title" *ngIf="!usage.startMileage">
                            <span>{{ 'BOOKINGS.MILEAGE_TO_INSERT_START' | translate }}</span>
                            <button-insert *ngIf="!usage?.startMileage" (click)="openModalMileage(usage)"></button-insert>
                            <button class="btn-edit" *ngIf="usage?.startMileage" (click)="openModalMileage(usage)">{{ 'ACTIONS.EDIT' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</div>