import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Asset } from 'src/app/shared/models/assets/asset';
import { Headquarter } from 'src/app/shared/models/headquarter/headquarter';
import { Location } from 'src/app/shared/models/location/location';
import { Task } from 'src/app/shared/models/task/task';
import { UserModel } from 'src/app/shared/models/user/users';
import { Vehicle } from 'src/app/shared/models/vehicle/vehicle';
import { VehicleType } from 'src/app/shared/models/make/make';
import { ImportDataStatus, ImportStatus } from 'src/app/shared/constant/status-import';

@Injectable({
  providedIn: 'root'
})
export class FilterModalService {

  private listForm = new Map<string, FormGroup>;
  private sourceForm = new Map<string, Subject<FormGroup>>();

  private listFilter = new Map<string, GenericFilter>();
  private sourceFilter = new Map<string, Subject<GenericFilter>>();

  constructor(
    private _toastrService: ToastrService,
    private _translateService: TranslateService
  ) { }

  getForm(id: string): FormGroup | null {
    if (this.listForm.has(id)) {
      return this.listForm.get(id);
    }
    return null;
  }

  getForm$(id: string): Observable<FormGroup> {
    if (!this.sourceForm.has(id)) {
      this.sourceForm.set(id, new BehaviorSubject<FormGroup>(null));
    }
    return this.sourceForm.get(id).asObservable();
  }

  applyFilterFromForm(id: string, form: FormGroup) {
    this.listForm.set(id, form);
    const filterSubject = this.sourceForm.get(id);
    if (filterSubject) {
      filterSubject.next(form);
    }
  }

  getFilter(id: string): GenericFilter | null {
    if (this.listFilter.has(id)) {
      return this.listFilter.get(id);
    }
    return null;
  }

  getFilter$(id: string): Observable<GenericFilter> {
    if (!this.sourceFilter.has(id)) {
      this.sourceFilter.set(id, new BehaviorSubject<GenericFilter>(null));
    }
    return this.sourceFilter.get(id).asObservable();
  }

  applyFilter(id: string, filter: GenericFilter) {
    this.listFilter.set(id, filter);
    const filterSubject = this.sourceFilter.get(id);
    if (filterSubject) {
      filterSubject.next(filter);
    }
  }
}

export interface GenericFilter {
  startMileage?: number;
  endMileage?: number;

  startCost?: number;
  endCost?: number;

  startDate?: Date;
  endDate?: Date;

  user?: UserModel;
  startUser?: UserModel;
  endUser?: UserModel;

  asset?: Asset;
  task?: Task;
  vehicle?: Vehicle;
  vehicleType?: VehicleType;
  headquarter?: Headquarter;
  location?: Location;

  importerName?: string;
  statusImportFile?: ImportStatus;
  statusImportData?: ImportDataStatus;
}
