import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';

import { ChartModule } from 'primeng/chart';
import { NgSelectModule } from '@ng-select/ng-select'

import { NotFoundElementComponent } from './components/not-found-element/not-found-element.component';

import { NgxSpinnerModule } from 'ngx-spinner';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TooltipModule as PrimeNgTooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';


import { ReverseForPipe } from './pipes/reverse-for.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { DynamicHighlightPipe } from './pipes/dynamic-highlight.pipe';

import { ButtonFirstTypeComponent } from './components/buttons/button-first-type/button-first-type.component';
import { DropdownPrimaryComponent } from './components/dropdown/dropdown-primary/dropdown-primary.component';
import { DropdownDynamicComponent } from './components/dropdown/dropdown-dynamic/dropdown-dynamic.component';
import { DropdownOrizontalDotsComponent } from './components/dropdown/dropdown-dots/dropdown-orizontal-dots/dropdown-orizontal-dots.component';
import { ButtonChooseComponent } from './components/buttons/button-choose/button-choose.component';
import { ButtonInsertComponent } from './components/buttons/button-insert/button-insert.component';

import { InputGroupDateComponent } from './components/inputs/input-group-date/input-group-date.component';
import { InputUploadFilesComponent } from './components/file-components/input-upload-files/input-upload-files.component';

import { BadgeBookingComponent } from './components/badge/badge-booking/badge-booking.component';

import { OutputFilesComponent } from './components/file-components/output-files/output-files.component';

import { BookingUsageMileageModalComponent } from './components/modals/booking-usage-mileage-modal/booking-usage-mileage-modal.component';
import { BookingDetailComponent } from './components/bookings/booking-detail/booking-detail.component';
import { BookingDetailOverviewComponent } from './components/bookings/booking-detail/booking-detail-overview/booking-detail-overview.component';

import { CardSmallComponent } from './components/cards/card-small/card-small.component';
import { CardBookingUsagesComponent } from './components/cards/card-booking-usages/card-booking-usages.component';
import { CardBookingEvidanceComponent } from './components/cards/card-booking-evidance/card-booking-evidance.component';
import { CardBookingUsageActionsComponent } from './components/cards/card-booking-usage-actions/card-booking-usage-actions.component';
import { CardBookingInfoComponent } from './components/cards/card-booking-info/card-booking-info.component';
import { CardTasksComponent } from './components/cards/card-tasks/card-tasks.component';
import { CardTaskUsageDateComponent } from './components/cards/card-task-usage-date/card-task-usage-date.component';
import { CardAddCostsComponent } from './components/cards/card-add-costs/card-add-costs.component';
import { CardInfoProfileComponent } from './components/cards/card-info-profile/card-info-profile.component';
import { CardNextBookingComponent } from './components/cards/card-next-booking/card-next-booking.component';
import { CardManagedComponent } from './components/cards/card-managed/card-managed.component';
import { CardMainActionsComponent } from './components/cards/card-main-actions/card-main-actions.component';
import { CardShowDataPreviousComponent } from './components/cards/card-show-data-previous/card-show-data-previous.component';
import { CardShowDataPreviousCostsComponent } from './components/cards/card-show-data-previous/card-show-data-previous-costs/card-show-data-previous-costs.component';
import { CardShowDataPreviousVehicleUsagesComponent } from './components/cards/card-show-data-previous/card-show-data-previous-vehicle-usages/card-show-data-previous-vehicle-usages.component';
import { CardShowDataPreviousAssetUsagesComponent } from './components/cards/card-show-data-previous/card-show-data-previous-asset-usages/card-show-data-previous-asset-usages.component';
import { CardShowDataPreviousTaskTodoComponent } from './components/cards/card-show-data-previous/card-show-data-previous-task-todo/card-show-data-previous-task-todo.component';
import { CardShowDataPreviousReportsComponent } from './components/cards/card-show-data-previous/card-show-data-previous-reports/card-show-data-previous-reports.component';
import { CardShowDataPreviousFinesComponent } from './components/cards/card-show-data-previous/card-show-data-previous-fines/card-show-data-previous-fines.component';
import { CardShowDataComponent } from './components/cards/card-show-data/card-show-data.component';
import { CardShowDataTaskTodoComponent } from './components/cards/card-show-data/card-show-data-task-todo/card-show-data-task-todo.component';
import { CardLastCommentsComponent } from './components/cards/card-last-comments/card-last-comments.component';
import { CardItemListComponent } from './components/cards/card-item-list/card-item-list.component';
import { CardLastBookingsComponent } from './components/cards/card-last-bookings/card-last-bookings.component';
import { CardAssetDataUsersComponent } from './components/cards/card-asset-data-users/card-asset-data-users.component';
import { CardAssetDataUsagesComponent } from './components/cards/card-asset-data-usages/card-asset-data-usages.component';
import { CardProgressDataComponent } from './components/cards/card-progress-data/card-progress-data.component';
import { CardAddTagsComponent } from './components/cards/card-add-tags/card-add-tags.component';
import { CardUpcomingDeadlinesComponent } from './components/cards/card-upcoming-deadlines/card-upcoming-deadlines.component';
import { CardVehicleUsagesComponent } from './components/cards/card-vehicle-usages/card-vehicle-usages.component';
import { CardReportsComponent } from './components/cards/card-reports/card-reports.component';
import { CardFinesComponent } from './components/cards/card-fines/card-fines.component';
import { CardTopCostsComponent } from './components/cards/card-top-costs/card-top-costs.component';
import { CardLastUsagesComponent } from './components/cards/card-last-usages/card-last-usages.component';
import { CardAddGroupsComponent } from './components/cards/card-add-groups/card-add-groups.component';
import { CardTaskWithoutCostsComponent } from './components/cards/card-task-without-costs/card-task-without-costs.component';

import { NgxPaginationModule } from 'ngx-pagination';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { DynamicTableComponent } from './components/table/dynamic-table/dynamic-table-desktop/dynamic-table.component';
import { DynamicTableMobileBookingsComponent } from './components/table/dynamic-table/dynamic-table-mobile-bookings/dynamic-table-mobile-bookings.component';
import { TaskTableComponent } from './components/table/task-table/task-table.component';
import { TaskTableFiltersComponent } from './components/table/task-table/task-table-filters/task-table-filters.component';
import { VehicleUsagesTableComponent } from './components/table/vehicle-usages-table/vehicle-usages-table.component';
import { VehicleUsagesTableFiltersComponent } from './components/table/vehicle-usages-table/vehicle-usages-table-filters/vehicle-usages-table-filters.component';
import { BookingTableComponent } from './components/table/booking-table/booking-table.component';
import { BookingTableFiltersComponent } from './components/table/booking-table/booking-table-filters/booking-table-filters.component';
import { CostTableComponent } from './components/table/cost-table/cost-table.component';
import { CostTableFiltersComponent } from './components/table/cost-table/cost-table-filters/cost-table-filters.component';
import { VehiclesTableComponent } from './components/table/vehicles-table/vehicles-table.component';
import { AssetsTableComponent } from './components/table/assets-table/assets-table.component';
import { VehiclesTableFiltersComponent } from './components/table/vehicles-table/vehicles-table-filters/vehicles-table-filters.component';
import { AssetsTableFiltersComponent } from './components/table/assets-table/assets-table-filters/assets-table-filters.component';
import { PersonsTableComponent } from './components/table/persons-table/persons-table.component';
import { PersonsTableFiltersComponent } from './components/table/persons-table/persons-table-filters/persons-table-filters.component';

import { TabsTasksExpiringComponent } from './components/tabs/tabs-tasks-expiring/tabs-tasks-expiring.component';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NoItemsComponent } from './components/no-items/no-items.component';

import { PersonModalComponent } from './components/modals/person-modal/person-modal.component';
import { AssetsModalComponent } from './components/modals/assets-modal/assets-modal.component';
import { ConfirmModalComponent } from './components/modals/confirm-modal/confirm-modal.component';
import { VehiclesModalComponent } from './components/modals/vehicles-modal/vehicles-modal.component';
import { InputDatePickerComponent } from './components/inputs/input-date-picker/input-date-picker.component';
import { CardTaskWithoutExpirationComponent } from './components/cards/card-task-without-expiration/card-task-without-expiration.component';
import { CardVehiclesInfoComponent } from './components/cards/card-vehicles-info/card-vehicles-info.component';
import { VehicleModalInfoComponent } from './components/modals/vehicles-modal/vehicle-modal-info/vehicle-modal-info.component';
import { CardVehiclesVersionComponent } from './components/cards/card-vehicles-version/card-vehicles-version.component';
import { VehicleModalVersionComponent } from './components/modals/vehicles-modal/vehicle-modal-version/vehicle-modal-version.component';
import { CardVehiclesFleetDataComponent } from './components/cards/card-vehicles-fleet-data/card-vehicles-fleet-data.component';
import { VehicleModalFleetDataComponent } from './components/modals/vehicles-modal/vehicle-modal-fleet-data/vehicle-modal-fleet-data.component';
import { CardVehiclesPurchaseTypeExtrafieldsComponent } from './components/cards/card-vehicles-purchase-type-extrafields/card-vehicles-purchase-type-extrafields.component';
import { CardVehiclesVersionExtrafieldsComponent } from './components/cards/card-vehicles-version-extrafields/card-vehicles-version-extrafields.component';
import { VehicleModalVersionExtrafieldsComponent } from './components/modals/vehicles-modal/vehicle-modal-version-extrafields/vehicle-modal-version-extrafields.component';
import { VehicleModalVehiclesVersionSpecsComponent } from './components/modals/vehicles-modal/vehicle-modal-vehicles-version-specs/vehicle-modal-vehicles-version-specs.component';
import { CardVehiclesVersionSpecsComponent } from './components/cards/card-vehicles-version-specs/card-vehicles-version-specs.component';
import { CardVehiclesCommonVersionDataComponent } from './components/cards/card-vehicles-common-version-data/card-vehicles-common-version-data.component';
import { CardVehiclesFinancialComponent } from './components/cards/card-vehicles-financial/card-vehicles-financial.component';
import { VehicleModalFinancialComponent } from './components/modals/vehicles-modal/vehicle-modal-financial/vehicle-modal-financial.component';
import { InputDateFormlyComponent } from './components/inputs/input-date-formly/input-date-formly.component';
import { ActionsModalComponent } from './components/modals/actions-modal/actions-modal.component';
import { UploadImportModalComponent } from './components/modals/import-modal/upload-import-modal/upload-import-modal.component';
import { ImportFileTableComponent } from './components/table/import-file-table/import-file-table.component';
import { ImportManagementDataTableComponent } from './components/table/import-management-data-table/import-management-data-table.component';
import { ImportManagementDataFilterComponent } from './components/table/import-management-data-table/import-management-data-filter/import-management-data-filter.component';
import { ImportFileFilterComponent } from './components/table/import-file-table/import-file-filter/import-file-filter.component';
import { VersionModalComponent } from './components/modals/version-modal/version-modal.component';
import { TagModalComponent } from './components/modals/tag-modal/tag-modal.component';
import { HeadquarterModalComponent } from './components/modals/headquarter-modal/headquarter-modal.component';
import { LocationModalComponent } from './components/modals/location-modal/location-modal.component';
import { LocationTableComponent } from './components/table/location-table/location-table.component';
import { HeadquarterTableComponent } from './components/table/headquarter-table/headquarter-table.component';
import { VersionTableComponent } from './components/table/version-table/version-table.component';
import { TagsTableComponent } from './components/table/tags-table/tags-table.component';
import { DynamicFilterTableComponent } from './components/table/dynamic-filter-table/dynamic-filter-table.component';
import { TagTableFilterComponent } from './components/table/tags-table/tag-table-filter/tag-table-filter.component';
import { VersionTableFilterComponent } from './components/table/version-table/version-table-filter/version-table-filter.component';
import { HeadquarterTableFilterComponent } from './components/table/headquarter-table/headquarter-table-filter/headquarter-table-filter.component';
import { LocationTableFilterComponent } from './components/table/location-table/location-table-filter/location-table-filter.component';
import { DropdownPrimaryLocationComponent } from './components/dropdown/dropdown-primary/dropdown-primary-location/dropdown-primary-location.component';
import { DropdownPrimaryHeadquarterComponent } from './components/dropdown/dropdown-primary/dropdown-primary-headquarter/dropdown-primary-headquarter.component';
import { DropdownPrimaryMakeComponent } from './components/dropdown/dropdown-primary/dropdown-primary-make/dropdown-primary-make.component';
import { DropdownPrimaryModelComponent } from './components/dropdown/dropdown-primary/dropdown-primary-model/dropdown-primary-model.component';
import { DropdownPrimaryFuelTypeComponent } from './components/dropdown/dropdown-primary/dropdown-primary-fuel-type/dropdown-primary-fuel-type.component';
import { DropdownPrimaryVehicleTypeComponent } from './components/dropdown/dropdown-primary/dropdown-primary-vehicle-type/dropdown-primary-vehicle-type.component';
import { FilterTaskModalComponent } from './components/modals/filter-modal/filter-task-modal/filter-task-modal.component';
import { DropdownPrimaryTaskTypeComponent } from './components/dropdown/dropdown-primary/dropdown-primary-task-type/dropdown-primary-task-type.component';

import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { DropdownVerticalDotsComponent } from './components/dropdown/dropdown-dots/dropdown-vertical-dots/dropdown-vertical-dots.component';
import { TaskManageFollowerModalComponent } from './components/modals/task-manage-follower-modal/task-manage-follower-modal.component';
import { ButtonAddDateComponent } from './components/buttons/button-add-date/button-add-date.component';
import { ButtonAddUserPrimaryComponent } from './components/buttons/button-add-user-primary/button-add-user-primary.component';
import { ButtonAddUserSecondaryComponent } from './components/buttons/button-add-user-secondary/button-add-user-secondary.component';
import { ButtonAddVehicleComponent } from './components/buttons/button-add-vehicle/button-add-vehicle.component';
import { ButtonAddAssetComponent } from './components/buttons/button-add-asset/button-add-asset.component';
import { MakeTableComponent } from './components/table/make-table/make-table.component';
import { ModelTableComponent } from './components/table/model-table/model-table.component';
import { ModelTableFilterComponent } from './components/table/model-table/model-table-filter/model-table-filter.component';
import { MakeTableFilterComponent } from './components/table/make-table/make-table-filter/make-table-filter.component';
import { VersionMakeModelActionsModalComponent } from './components/modals/version-make-model-actions-modal/version-make-model-actions-modal.component';
import { MakeModalComponent } from './components/modals/make-modal/make-modal.component';
import { ModelModalComponent } from './components/modals/model-modal/model-modal.component';
import { DropdownStaticPrimaryComponent } from './components/dropdown/dropdown-static-primary/dropdown-static-primary.component';
import { CardTcoComponent } from './components/cards/card-tco/card-tco.component';
import { DropdownPrimaryPurchaseTypeComponent } from './components/dropdown/dropdown-primary/dropdown-primary-purchase-type/dropdown-primary-purchase-type.component';
import { DropdownPrimaryAssetTypeComponent } from './components/dropdown/dropdown-primary/dropdown-primary-asset-type/dropdown-primary-asset-type.component';
import { CardCostTrendComponent } from './components/cards/card-cost-trend/card-cost-trend.component';
import { ProgressBarVerticalComponent } from './components/progress-bar/progress-bar-vertical/progress-bar-vertical.component';
import { DownloadImportModalComponent } from './components/modals/import-modal/download-import-modal/download-import-modal.component';
import { CardVehicleStatusComponent } from './components/cards/cards-vehicle/card-vehicle-status/card-vehicle-status.component';
import { VehicleSuspensionProgramModalComponent } from './components/modals/vehicles-modal/vehicle-suspension-program-modal/vehicle-suspension-program-modal.component';
import { FilterBookingModalComponent } from './components/modals/filter-modal/filter-booking-modal/filter-booking-modal.component';
import { DropdownPrimaryBookingTypeComponent } from './components/dropdown/dropdown-primary/dropdown-primary-booking-type/dropdown-primary-booking-type.component';
import { FilterAssetModalComponent } from './components/modals/filter-modal/filter-asset-modal/filter-asset-modal.component';
import { FilterVehicleModalComponent } from './components/modals/filter-modal/filter-vehicle-modal/filter-vehicle-modal.component';
import { CardFringeBenefitComponent } from './components/cards/card-fringe-benefit/card-fringe-benefit.component';
import { ListItemsWithAvatarComponent } from './components/lists/list-items-with-avatar/list-items-with-avatar.component';
import { FringeBenefitModalComponent } from './components/modals/fringe-benefit-modal/fringe-benefit-modal.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FilterVehicleUsagesModalComponent } from './components/modals/filter-modal/filter-vehicle-usages-modal/filter-vehicle-usages-modal.component';
import { FilterCostModalComponent } from './components/modals/filter-modal/filter-cost-modal/filter-cost-modal.component';
import { SuppliersModalComponent } from './components/modals/suppliers-modal/suppliers-modal.component';
import { InvoicesModalComponent } from './components/modals/invoices-modal/invoices-modal.component';
import { SupplierTableComponent } from './components/table/supplier-table/supplier-table.component';
import { InvoiceTableComponent } from './components/table/invoice-table/invoice-table.component';
import { InvoiceTableFilterComponent } from './components/table/invoice-table/invoice-table-filter/invoice-table-filter.component';
import { SupplierTableFilterComponent } from './components/table/supplier-table/supplier-table-filter/supplier-table-filter.component';
import { DuplicateTaskModalComponent } from './components/modals/duplicate-task-modal/duplicate-task-modal.component';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownPrimaryPermissionComponent } from './components/dropdown/dropdown-primary/dropdown-primary-permission/dropdown-primary-permission.component';
import { ListSwitchesComponent } from './components/list-switches/list-switches.component';
import { BookingCloseUsageModalComponent } from './components/modals/booking-close-usage-modal/booking-close-usage-modal.component';
import { FilterPersonModalComponent } from './components/modals/filter-modal/filter-person-modal/filter-person-modal.component';
import { ImportFileFilterModalComponent } from './components/modals/import-file-filter-modal/import-file-filter-modal.component';
import { ImportManagementDataFilterModalComponent } from './components/modals/import-management-data-filter-modal/import-management-data-filter-modal.component';
import { ImportFileModalComponent } from './components/modals/import-file-modal/import-file-modal.component';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@bluehalo/ngx-leaflet-markercluster';

@NgModule({
  declarations: [
    NotFoundElementComponent,

    ReverseForPipe,
    TruncatePipe,
    CapitalizePipe,
    DynamicHighlightPipe,

    ButtonFirstTypeComponent,
    DropdownPrimaryComponent,
    DropdownDynamicComponent,
    DropdownOrizontalDotsComponent,
    ButtonChooseComponent,
    ButtonInsertComponent,

    InputGroupDateComponent,
    InputUploadFilesComponent,

    BadgeBookingComponent,

    OutputFilesComponent,

    BookingDetailComponent,
    BookingDetailOverviewComponent,

    CardShowDataComponent,
    CardSmallComponent,
    CardBookingUsagesComponent,
    CardBookingEvidanceComponent,
    CardBookingUsageActionsComponent,
    CardBookingInfoComponent,
    CardTasksComponent,
    CardTaskUsageDateComponent,
    CardAddCostsComponent,
    CardInfoProfileComponent,
    CardNextBookingComponent,
    CardManagedComponent,
    CardMainActionsComponent,
    CardShowDataPreviousComponent,
    CardShowDataPreviousCostsComponent,
    CardShowDataPreviousVehicleUsagesComponent,
    CardShowDataPreviousAssetUsagesComponent,
    CardShowDataPreviousTaskTodoComponent,
    CardShowDataPreviousReportsComponent,
    CardShowDataPreviousFinesComponent,
    CardShowDataTaskTodoComponent,
    CardItemListComponent,
    CardLastCommentsComponent,
    CardLastBookingsComponent,
    CardProgressDataComponent,
    CardAddTagsComponent,
    CardAddGroupsComponent,
    CardTaskWithoutCostsComponent,
    CardUpcomingDeadlinesComponent,
    CardVehicleUsagesComponent,
    CardReportsComponent,
    CardFinesComponent,
    CardTopCostsComponent,
    CardLastUsagesComponent,
    CardAssetDataUsersComponent,
    CardAssetDataUsagesComponent,
    CardTaskWithoutExpirationComponent,
    CardVehiclesInfoComponent,

    BookingUsageMileageModalComponent,
    PersonModalComponent,
    ConfirmModalComponent,
    AssetsModalComponent,
    VehiclesModalComponent,
    VehicleModalInfoComponent,
    ActionsModalComponent,
    UploadImportModalComponent,

    DynamicTableComponent,
    DynamicTableMobileBookingsComponent,
    TaskTableComponent,
    TaskTableFiltersComponent,
    VehicleUsagesTableComponent,
    VehicleUsagesTableFiltersComponent,
    BookingTableComponent,
    BookingTableFiltersComponent,
    CostTableComponent,
    CostTableFiltersComponent,
    PersonsTableComponent,
    PersonsTableFiltersComponent,
    VehiclesTableComponent,
    VehiclesTableFiltersComponent,
    AssetsTableComponent,
    AssetsTableFiltersComponent,

    TabsTasksExpiringComponent,

    NoItemsComponent,
    InputDatePickerComponent,
    CardVehiclesVersionComponent,
    VehicleModalVersionComponent,
    CardVehiclesFleetDataComponent,
    VehicleModalFleetDataComponent,
    CardVehiclesPurchaseTypeExtrafieldsComponent,
    CardVehiclesVersionExtrafieldsComponent,
    VehicleModalVersionExtrafieldsComponent,
    VehicleModalVehiclesVersionSpecsComponent,
    CardVehiclesVersionSpecsComponent,
    CardVehiclesCommonVersionDataComponent,
    CardVehiclesFinancialComponent,
    VehicleModalFinancialComponent,
    InputDateFormlyComponent,
    ImportFileTableComponent,
    ImportManagementDataTableComponent,
    ImportManagementDataFilterComponent,
    ImportFileFilterComponent,
    VersionModalComponent,
    TagModalComponent,
    HeadquarterModalComponent,
    LocationModalComponent,
    LocationTableComponent,
    HeadquarterTableComponent,
    VersionTableComponent,
    TagsTableComponent,
    DynamicFilterTableComponent,
    TagTableFilterComponent,
    VersionTableFilterComponent,
    HeadquarterTableFilterComponent,
    LocationTableFilterComponent,
    DropdownPrimaryLocationComponent,
    DropdownPrimaryHeadquarterComponent,
    DropdownPrimaryPermissionComponent,
    DropdownPrimaryMakeComponent,
    DropdownPrimaryModelComponent,
    DropdownPrimaryFuelTypeComponent,
    DropdownPrimaryVehicleTypeComponent,
    FilterTaskModalComponent,
    DropdownPrimaryTaskTypeComponent,
    DropdownVerticalDotsComponent,
    TaskManageFollowerModalComponent,
    ButtonAddDateComponent,
    ButtonAddUserPrimaryComponent,
    ButtonAddUserSecondaryComponent,
    ButtonAddVehicleComponent,
    ButtonAddAssetComponent,
    MakeTableComponent,
    ModelTableComponent,
    ModelTableFilterComponent,
    MakeTableFilterComponent,
    VersionMakeModelActionsModalComponent,
    MakeModalComponent,
    ModelModalComponent,
    DropdownStaticPrimaryComponent,
    CardTcoComponent,
    DropdownPrimaryPurchaseTypeComponent,
    DropdownPrimaryAssetTypeComponent,
    CardCostTrendComponent,
    ProgressBarVerticalComponent,
    DownloadImportModalComponent,
    CardVehicleStatusComponent,
    VehicleSuspensionProgramModalComponent,
    FilterBookingModalComponent,
    DropdownPrimaryBookingTypeComponent,
    FilterAssetModalComponent,
    FilterVehicleModalComponent,
    FilterPersonModalComponent,
    CardFringeBenefitComponent,
    ListItemsWithAvatarComponent,
    FringeBenefitModalComponent,
    FilterVehicleUsagesModalComponent,
    FilterCostModalComponent,
    SuppliersModalComponent,
    InvoicesModalComponent,
    SupplierTableComponent,
    InvoiceTableComponent,
    InvoiceTableFilterComponent,
    SupplierTableFilterComponent,
    DuplicateTaskModalComponent,
    ListSwitchesComponent,
    BookingCloseUsageModalComponent,
    ImportFileFilterComponent,
    ImportFileFilterModalComponent,
    ImportManagementDataFilterModalComponent,
    ImportFileModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
    NgxPaginationModule,
    BsDatepickerModule,
    FormlyModule.forRoot({
      types: [
        {
          name: 'ngx-bootstra-datepicker',
          component: InputDateFormlyComponent,
        }
      ]
    }),
    FormlyBootstrapModule,
    TranslateModule,
    TooltipModule.forRoot(),

    PrimeNgTooltipModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,

    InputSwitchModule,
    TypeaheadModule.forRoot(),
    NgSelectModule,

    ChartModule,
    NgCircleProgressModule.forRoot({
      "animationDuration": 300,
    }),

    AvatarModule,
    AvatarGroupModule,

    NgxSpinnerModule,

    LeafletModule,
    LeafletMarkerClusterModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    PaginationModule,
    NgxPaginationModule,
    BsDatepickerModule,
    FormlyModule,
    FormlyBootstrapModule,
    TranslateModule,
    TooltipModule,

    NotFoundElementComponent,

    ReverseForPipe,
    TruncatePipe,
    CapitalizePipe,
    DynamicHighlightPipe,

    ButtonFirstTypeComponent,
    DropdownPrimaryComponent,
    DropdownDynamicComponent,
    DropdownOrizontalDotsComponent,

    ButtonChooseComponent,
    ButtonInsertComponent,
    ButtonAddDateComponent,
    ButtonAddUserPrimaryComponent,
    ButtonAddUserSecondaryComponent,
    ButtonAddVehicleComponent,
    ButtonAddAssetComponent,

    InputGroupDateComponent,
    InputUploadFilesComponent,

    BadgeBookingComponent,

    OutputFilesComponent,

    BookingDetailComponent,
    BookingDetailOverviewComponent,

    CardShowDataComponent,
    CardSmallComponent,
    CardBookingUsagesComponent,
    CardBookingEvidanceComponent,
    CardBookingUsageActionsComponent,
    CardBookingInfoComponent,
    CardTasksComponent,
    CardTaskUsageDateComponent,
    CardAddCostsComponent,
    CardInfoProfileComponent,
    CardNextBookingComponent,
    CardManagedComponent,
    CardMainActionsComponent,
    CardShowDataPreviousComponent,
    CardShowDataPreviousCostsComponent,
    CardShowDataPreviousVehicleUsagesComponent,
    CardShowDataPreviousAssetUsagesComponent,
    CardShowDataPreviousTaskTodoComponent,
    CardShowDataPreviousReportsComponent,
    CardShowDataPreviousFinesComponent,
    CardShowDataTaskTodoComponent,
    CardItemListComponent,
    CardLastCommentsComponent,
    CardLastBookingsComponent,
    CardProgressDataComponent,
    CardAddTagsComponent,
    CardAddGroupsComponent,
    CardTaskWithoutCostsComponent,
    CardUpcomingDeadlinesComponent,
    CardVehicleUsagesComponent,
    CardReportsComponent,
    CardFinesComponent,
    CardTopCostsComponent,
    CardLastUsagesComponent,
    CardAssetDataUsersComponent,
    CardAssetDataUsagesComponent,
    CardTaskWithoutExpirationComponent,
    CardVehiclesInfoComponent,
    CardVehiclesVersionComponent,
    CardVehiclesFleetDataComponent,
    CardVehiclesPurchaseTypeExtrafieldsComponent,
    CardVehiclesVersionExtrafieldsComponent,
    CardVehiclesVersionSpecsComponent,
    CardVehiclesCommonVersionDataComponent,
    CardVehiclesFinancialComponent,
    CardTcoComponent,
    CardCostTrendComponent,
    CardVehicleStatusComponent,
    CardFringeBenefitComponent,

    BookingUsageMileageModalComponent,
    PersonModalComponent,
    ConfirmModalComponent,

    DynamicTableComponent,
    DynamicTableMobileBookingsComponent,
    TaskTableComponent,
    TaskTableFiltersComponent,
    VehicleUsagesTableComponent,
    VehicleUsagesTableFiltersComponent,
    BookingTableComponent,
    BookingTableFiltersComponent,
    CostTableComponent,
    CostTableFiltersComponent,
    SupplierTableComponent,
    SupplierTableFilterComponent,
    InvoiceTableComponent,
    InvoiceTableFilterComponent,
    PersonsTableComponent,
    PersonsTableFiltersComponent,
    VehiclesTableComponent,
    VehiclesTableFiltersComponent,
    AssetsTableComponent,
    AssetsTableFiltersComponent,
    ImportFileTableComponent,
    ImportManagementDataTableComponent,
    HeadquarterTableComponent,
    HeadquarterTableFilterComponent,
    LocationTableComponent,
    LocationTableFilterComponent,
    TagsTableComponent,
    TagTableFilterComponent,
    VersionTableComponent,
    VersionTableFilterComponent,
    MakeTableComponent,
    MakeTableFilterComponent,
    ModelTableComponent,
    ModelTableFilterComponent,

    TabsTasksExpiringComponent,

    NoItemsComponent,
    InputDatePickerComponent,

    DropdownPrimaryLocationComponent,
    DropdownPrimaryHeadquarterComponent,
    DropdownPrimaryPermissionComponent,
    DropdownPrimaryMakeComponent,
    DropdownPrimaryModelComponent,
    DropdownPrimaryFuelTypeComponent,
    DropdownPrimaryVehicleTypeComponent,
    DropdownPrimaryTaskTypeComponent,
    DropdownPrimaryPurchaseTypeComponent,
    DropdownPrimaryAssetTypeComponent,
    DropdownPrimaryBookingTypeComponent,

    DropdownVerticalDotsComponent,
    DropdownStaticPrimaryComponent,

    ListSwitchesComponent,
    BookingCloseUsageModalComponent,
    ImportFileFilterComponent,
    ImportFileFilterModalComponent,
    ImportManagementDataFilterModalComponent,
    ImportFileModalComponent,
  ]
})
export class SharedModule { }