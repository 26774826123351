import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarButtonComponent } from './components/sidebar/sidebar-button/sidebar-button.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NotificationComponent } from './components/notification/notification.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CapitalizePipe } from '../shared/pipes/capitalize.pipe';

@NgModule({
  declarations: [
    NavbarComponent,
    SidebarComponent,
    SidebarButtonComponent,
    BreadcrumbComponent,
    NotificationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    TranslateModule.forChild(),
    InfiniteScrollModule,
  ],
  exports: [
    NavbarComponent,
    SidebarComponent,
    BreadcrumbComponent
  ]
})
export class CoreModule { }
