import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Booking } from 'src/app/shared/models/booking/booking';

@Component({
  selector: 'booking-detail-overview',
  templateUrl: './booking-detail-overview.component.html',
  styleUrls: ['./booking-detail-overview.component.scss'],
})

export class BookingDetailOverviewComponent {
  @Input() booking: Booking;
  @Input() isFleetManager: boolean;
}
