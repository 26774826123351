import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MeService } from 'src/app/shared/api-services/me.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import * as Permissions from 'src/app/shared/constant/permissions';

@Component({
  selector: 'version-make-model-actions-modal',
  templateUrl: './version-make-model-actions-modal.component.html',
  styleUrls: ['./version-make-model-actions-modal.component.scss']
})
export class VersionMakeModelActionsModalComponent {
  tenantId = this._tenantService.getTenantId();

  actions: ActionItems[];

  constructor(
    private _tenantService: TenantService,
    private _meService: MeService,
    private _modalUtilsService: ModalUtilsService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.checkPermissions();
    this.actions = [
      {
        sector: 'settings',
        icon: 'bi-plus-lg',
        header: 'MAKES.NEW',
        description: 'MAKES.DESCRIPTION',
        permissions: [Permissions.CAN_MANAGE_MAKES],
        action: () => this.openMakeModal()
      },
      {
        sector: 'settings',
        icon: 'bi-plus-lg',
        header: 'MODELS.NEW',
        description: 'MODELS.DESCRIPTION',
        permissions: [Permissions.CAN_MANAGE_MODELS],
        action: () => this.openModelModal()
      },
      {
        sector: 'settings',
        icon: 'bi-plus-lg',
        header: 'VERSIONS.NEW',
        description: 'VERSIONS.DESCRIPTION',
        permissions: [Permissions.CAN_MANAGE_VERSIONS],
        action: () => this.openVersionModal()
      },
    ];
  }

  checkPermissions() {
    this._meService.getMePermissions$(this.tenantId).subscribe({
      next: response => {
        this.actions = this.actions.filter(action => action.permissions.some(permission => response.permissions.includes(permission)));
      }
    });
  }

  openVersionModal() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openVehicleVersionModal().subscribe();;
    }, 200);
  }

  openMakeModal() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openMakeModal().subscribe();;
    }, 200);
  }

  openModelModal() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this._modalUtilsService.openModelModal().subscribe();;
    }, 200);
  }
}

interface ActionItems {
  sector: string;
  header: string;
  description?: string;
  icon?: string;
  action?: () => void;
  permissions?: string[];
}