import * as Sentry from "@sentry/angular-ivy";
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { Observable, Observer, debounceTime, distinctUntilChanged, map, of, switchMap } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { DropdownOption } from "../../dropdown/dropdown-primary/dropdown.interface";
import { SafeUrl } from "@angular/platform-browser";
import { Vehicle } from "src/app/shared/models/vehicle/vehicle";
import { Tag } from "src/app/shared/models/tag/tag";
import { TagsService } from "src/app/shared/api-services/tags.service";
import { VehiclesService } from "src/app/shared/api-services/vehicles.service";
import { MakesService } from "src/app/shared/api-services/makes.service";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { TypeExtraField } from "src/app/shared/models/common";
import { ExtraFieldsUtilsService } from "src/app/shared/utilities/extra-fields-utils.servic";
import { StorageUtilsService } from "src/app/shared/utilities/storage-utils.servic";
import { VehiclesFilter } from "src/app/shared/models/vehicle/vehicle-filter";

@Component({
  selector: 'vehicles-modal',
  templateUrl: './vehicles-modal.component.html',
  styleUrls: ['./vehicles-modal.component.scss']
})
export class VehiclesModalComponent {
  tenantId = this._tenantService.getTenantId();
  @Input() vehicle?: Vehicle;
  vehicleRequest?: Vehicle;

  typeExtraFields: TypeExtraField[];

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  vehicleForm: FormGroup;
  requestVehicle: Vehicle = {};

  tags$: Observable<string[]>;
  selectedTags: Tag[] = [];

  vehicleVersionStatus = {
    version: false
  };

  defaultOption: DropdownOption = {
    value: '',
    text: this._translateService.instant('SELECT_OPTION')
  };

  vehiclePurchaseTypeListOptions: DropdownOption[];
  vehiclePurchaseTypeSelected: DropdownOption;

  makeListOptions: DropdownOption[];
  makeSelected: DropdownOption;

  modelListOptions: DropdownOption[];
  modelSelected: DropdownOption;

  vehicleVersionListOptions: DropdownOption[];
  vehicleVersionSelected: DropdownOption;

  extraFields: TypeExtraField[] = [];
  fields: FormlyFieldConfig[];

  selectedFile: File | null = null;
  previewAvatar!: Observable<SafeUrl>;
  private bucketNameImage = 'vehicles-profile-images';

  isMobile = false;

  viewMobileSubscription = this._breakpointObserver
    .observe([Breakpoints.Handset])
    .subscribe(result => {
      this.isMobile = result.matches;
    });

  constructor(
    public bsModalRef: BsModalRef,
    private _tenantService: TenantService,
    private _vehiclesService: VehiclesService,
    private _makesService: MakesService,
    private _tagsService: TagsService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _communicationService: CommunicationService,
    private _breakpointObserver: BreakpointObserver,
    private _extraFieldsUtilsService: ExtraFieldsUtilsService,
    private _storageUtilsService: StorageUtilsService,
  ) {

    this.vehicleForm = new FormGroup({
      imageFileId: new FormControl(null),
      licensePlate: new FormControl(null, Validators.required),
      vehicleTypeId: new FormControl(null),
      purchaseTypeId: new FormControl(null, Validators.required),
      makeId: new FormControl(null, Validators.required),
      modelId: new FormControl(null, Validators.required),
      versionId: new FormControl(null, Validators.required),
      headquarterId: new FormControl(null),
      locationId: new FormControl(null),
      displayName: new FormControl(null),
      mileage: new FormControl(null),
      fleetEntryDate: new FormControl(null, Validators.required),
      fleetExitDate: new FormControl(null),
      fleetEntryValue: new FormControl(null),
      tag: new FormControl(null),
    });
    this.vehicleForm.get('vehicleTypeId').disable();
  }

  ngOnInit() {
    this.vehiclePurchaseTypeSelected = this.defaultOption;
    this.makeSelected = this.defaultOption;
    this.modelSelected = this.defaultOption;
    this.vehicleVersionSelected = this.defaultOption;

    this.autoComplete();
    this.getVehiclePurchaseTypes();
    this.getVehicle();
  }

  getVehicle() {
    if (this.vehicle) {

      this.requestVehicle.imageFileId = this.vehicle.imageFileId;
      this.setValueFields();

      this.previewAvatar = this._vehiclesService.getVehicleImage$(this.vehicle.id, 140, 140);
    } else {
      this.vehicle = {};
    }
  }

  setValueFields() {
    if (this.vehicle.id) {
      this.modalStatus.isCreate = false;
      this.modalStatus.isPatch = true;

      this.vehicleForm.patchValue({
        licensePlate: this.vehicle.licensePlate,
        displayName: this.vehicle.displayName,
        mileage: this.vehicle.mileage,
        fleetEntryValue: this.vehicle.fleetEntryValue,
      });

      if (this.vehicle.purchaseTypeId) {
        this.vehiclePurchaseTypeSelected = {
          value: this.vehicle.purchaseType.id,
          text: this.vehicle.purchaseType.name
        };
        this.vehicleForm.controls['purchaseTypeId'].setValue(this.vehiclePurchaseTypeSelected.value);
      }

      if (this.vehicle.vehicleMake) {
        this.makeSelected = {
          value: this.vehicle.vehicleMake.id,
          text: this.vehicle.vehicleMake.name
        };
        this.onVehicleMakeSelected(this.makeSelected);
      }

      if (this.vehicle.vehicleModel) {
        this.modelSelected = {
          value: this.vehicle.vehicleModel.id,
          text: this.vehicle.vehicleModel.name
        };
        this.onVehicleModelSelected(this.modelSelected);
      }

      if (this.vehicle.vehicleVersion) {
        this.vehicleVersionSelected = {
          value: this.vehicle.vehicleVersion.id,
          text: this.vehicle.vehicleVersion.name
        };
        this.onVehicleVersionSelected(this.vehicleVersionSelected);
      }

      if (this.vehicle.tags && this.vehicle.tags.length > 0) {
        this.selectedTags = this.vehicle.tags;
        this.vehicleForm.patchValue({
          tag: this.selectedTags.map(tag => tag.name).join(',')
        });
      }

      if (this.vehicle.fleetEntryDate) {
        let date = new Date(this.vehicle.fleetEntryDate);
        this.vehicleForm.patchValue({
          fleetEntryDate: date
        });
      }

      if (this.vehicle.fleetExitDate) {
        let date = new Date(this.vehicle.fleetExitDate);
        this.vehicleForm.patchValue({
          fleetExitDate: date
        });
      }

      if (this.vehicle.disabledFrom) {
        let date = new Date(this.vehicle.disabledFrom);
        this.vehicleForm.patchValue({
          disabledFrom: date
        });
      }

      if (this.vehicle.disabledTo) {
        let date = new Date(this.vehicle.disabledTo);
        this.vehicleForm.patchValue({
          disabledTo: date
        });
      }

      this.getExtraFields();
    }
  }

  autoComplete() {
    this.tags$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.vehicleForm.controls['tag'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._tagsService.listTags$(this.tenantId, { nameContains: token, limit: 5 })),
      map((response) => {
        let tagNames: string[] = [];
        response.items.forEach((tag) => {
          tagNames.push(tag.name);
        });
        return tagNames;
      }),
    );
  }

  onTagSelected(match: TypeaheadMatch) {
    const tagName = match.value;

    let tagExists = false;
    if (this.selectedTags && this.selectedTags.length > 0) {
      tagExists = this.selectedTags.some(tag => tag.name === tagName);
    }

    if (!tagExists) {
      this._tagsService.listTags$(this.tenantId, { name: tagName }).subscribe({
        next: (tags) => {
          if (tags.items.length > 0) {
            const tag = tags.items[0];
            this.selectedTags.push(tag);
          }
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
    }
  }

  removeTag(tagId: string) {
    this.selectedTags = this.selectedTags.filter(tag => tag.id !== tagId);
  }

  getVehiclePurchaseTypes() {
    this._makesService.listPurchaseTypes$(this.tenantId).subscribe({
      next: (response) => {
        this.vehiclePurchaseTypeListOptions = response.items.map(vehiclePurchaseType => ({
          value: vehiclePurchaseType.id,
          text: this._translateService.instant(vehiclePurchaseType.name)
        }));
      },
      error: (error) => {
        Sentry.captureEvent(error);
      }
    });
  }

  onVehiclePurchaseTypeSelected($newSelectedOption: DropdownOption) {
    if ($newSelectedOption) {
      this.vehicleForm.controls['purchaseTypeId'].setValue($newSelectedOption.value);
      this.getExtraFields();
    }
  }

  onVehicleMakeSelected($newSelectedOption: DropdownOption) {
    if (!$newSelectedOption) {
      console.error('Make is required');
      return;
    }
    this.vehicleForm.patchValue({
      makeId: $newSelectedOption.value
    });
  }

  onVehicleModelSelected($newSelectedOption: DropdownOption) {
    if (!$newSelectedOption) {
      console.error('Model is required');
      return;
    }
    this.vehicleForm.patchValue({
      modelId: $newSelectedOption.value
    });
    this.vehicleVersionStatus.version = true;
    this.getVehicleVersions();
  }

  getVehicleVersions() {
    let makeId = this.vehicleForm.controls['makeId'].value;
    let modelId = this.vehicleForm.controls['modelId'].value;
    if (makeId && modelId) {
      let params = {
        IncludeVehicleType: true,
        makeId,
        modelId
      }
      this._makesService.listVersions$(this.tenantId, params).subscribe({
        next: (response) => {
          this.vehicleVersionListOptions = response.items.map(version => ({
            value: version.id,
            text: this._translateService.instant(version.name),
            extraField: version.vehicleType
          }));
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
    }
  }

  onVehicleVersionSelected($newSelectedOption: DropdownOption) {
    if ($newSelectedOption) {
      this.vehicleForm.controls['versionId'].setValue($newSelectedOption.value);
      const name = $newSelectedOption.extraField?.name ?? this.vehicle.vehicleType?.name;
      this.vehicleForm.controls['vehicleTypeId'].setValue(name);
      this.getExtraFields();
    }
  }

  onHeadquarterSelected($newSelectedOption: DropdownOption) {
    if ($newSelectedOption) {
      this.vehicleForm.controls['headquarterId'].setValue($newSelectedOption.value);
    }
  }

  onLocationSelected($newSelectedOption: DropdownOption) {
    if ($newSelectedOption) {
      this.vehicleForm.controls['locationId'].setValue($newSelectedOption.value);
    }
  }

  onFileSelected($event: Event): void {
    this.selectedFile = this._storageUtilsService.getFileSelect($event);
    this.previewAvatar = this._storageUtilsService.onFileSelected(this.selectedFile);
  }

  getExtraFields() {
    let versionId = this.vehicleForm.controls['versionId'].value;
    let purchaseTypeId = this.vehicleForm.controls['purchaseTypeId'].value;

    let params: VehiclesFilter = {
      purchaseTypeId: purchaseTypeId ?? null,
      versionId: versionId ?? null
    };

    if (versionId || purchaseTypeId) {
      this._vehiclesService.listVehicleFields$(this.tenantId, params)
        .subscribe({
          next: (response) => {
            this.typeExtraFields = response.items.map(x => x as TypeExtraField);
            this.vehicle = this.vehicle ? this.vehicle : {};
            this.vehicle.extraFields = this.vehicle.extraFields ? this.vehicle.extraFields : {};
            this.vehicle.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.vehicle.extraFields, false);
            this.fields = this.typeExtraFields.map(field =>
              this._extraFieldsUtilsService.createFieldConfig(field, this.vehicle.extraFields));
          },
          error: (error) => {
            Sentry.captureEvent(error);
          }
        });
    }
  }

  cleanExtraFields() {
    Object.keys(this.vehicleForm.controls).forEach(key => {
      if (this.fields && this.fields.length > 0) {
        this.fields.forEach(field => {
          if (field.key == key) {
            this.vehicleForm.removeControl(key);
          }
        });
      }
    });
  }

  onSubmit() {
    this._storageUtilsService.uploadFile$(this.selectedFile, this.bucketNameImage).subscribe({
      next: (response) => {
        this.requestVehicle.imageFileId = response?.body?.id ?? this.vehicle.imageFileId;
        if (this.vehicle.id) {
          this.onPatchVehicle();
        } else {
          this.onCreateVehicle();
        }

      },
      error: error => {
        this._toastrService.error(error.error.detail, error.error.title);
        Sentry.captureEvent(error);
      }
    });
  }

  onCreateVehicle() {
    this.createRequestFromForm();
    if (this.vehicleForm.invalid) {
      return;
    }

    let allFieldsFromForm = this.vehicleForm.value;

    this._vehiclesService.listVehicleFields$(this.tenantId, {
      versionId: this.requestVehicle.versionId,
      purchaseTypeId: this.requestVehicle.purchaseTypeId
    }).pipe(
      switchMap((response) => {
        const vehicleFields = response.items;

        if (!this.requestVehicle.extraFields && vehicleFields.length > 0) {
          this.requestVehicle.extraFields = {};

          vehicleFields.forEach(field => {
            if (field.name in allFieldsFromForm) {
              this.requestVehicle.extraFields[field.name] = allFieldsFromForm[field.name];
            }
          });
        }

        return this._vehiclesService.createVehicle$(this.tenantId, this.requestVehicle);
      })
    ).subscribe({
      next: response => {
        if (response.id) {
          this._toastrService.success(this._translateService.instant('VEHICLES.MESSAGES.SUCCESS_CREATED'));
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
        else {
          this._toastrService.error(this._translateService.instant('VEHICLES.MESSAGES.ERROR_CREATING'));
        }
      }
    });
  }

  onPatchVehicle() {
    this.createRequestFromForm();
    if (this.vehicleForm.invalid) {
      return;
    }

    this._vehiclesService.updateVehicle$(this.tenantId, this.vehicle.id, this.requestVehicle).subscribe({
      next: response => {
        this.vehicleRequest = response;
        if (response.id) {
          this._communicationService.sendEvent();
          this.bsModalRef.hide();
        }
      }
    });
  }

  createRequestFromForm() {
    if (this.typeExtraFields.length > 0) {
      this.requestVehicle = this._extraFieldsUtilsService.updateRequestFromForm(this.typeExtraFields, this.vehicleForm.value, this.requestVehicle);
      this.requestVehicle.extraFields = this._extraFieldsUtilsService.parseBool(this.typeExtraFields, this.requestVehicle.extraFields);
    }

    if (!this.requestVehicle.extraFields) {
      this.requestVehicle.extraFields = {};
    }

    this.requestVehicle.licensePlate = this.vehicleForm.controls['licensePlate'].value ?? null;
    this.requestVehicle.purchaseTypeId = this.vehicleForm.controls['purchaseTypeId'].value ?? null;
    this.requestVehicle.versionId = this.vehicleForm.controls['versionId'].value ?? null;
    this.requestVehicle.displayName = this.vehicleForm.controls['displayName'].value ?? null;
    this.requestVehicle.mileage = this.vehicleForm.controls['mileage'].value ?? null;


    if (this.vehicleForm.controls['headquarterId'].value && this.vehicleForm.controls['locationId'].value === null) {
      this._toastrService.info(this._translateService.instant('VEHICLES.MESSAGES.LOCATION_REQUIRED'));
      return; // location required
    }

    this.requestVehicle.locationId = this.vehicleForm.controls['locationId'].value ?? null;

    if (!this.requestVehicle.imageFileId) {
      this.requestVehicle.imageFileId = null;
    }

    if (this.vehicleForm.controls['fleetEntryDate'].value) {
      let inputFleetEntryDate = this.vehicleForm.controls['fleetEntryDate'].value;
      let entryDateUtc = new Date(inputFleetEntryDate).toISOString();
      this.requestVehicle.fleetEntryDate = entryDateUtc;
    }

    if (this.vehicleForm.controls['fleetExitDate'].value) {
      let inputFleetExitDate = this.vehicleForm.controls['fleetExitDate'].value;
      let exitDateUtc = new Date(inputFleetExitDate).toISOString();
      this.requestVehicle.fleetExitDate = exitDateUtc;
    }

    this.requestVehicle.fleetEntryValue = this.vehicleForm.controls['fleetEntryValue'].value ?? null;

    this.requestVehicle.disabledFrom = this.vehicle?.disabledFrom ?? null;
    this.requestVehicle.disabledTo = this.vehicle?.disabledTo ?? null;

    if (this.selectedTags && this.selectedTags.length > 0) {
      this.requestVehicle.tagIds = this.selectedTags.map(tag => tag.id);
    }
  }
}
