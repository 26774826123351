import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { SharedModule } from 'src/app/shared/shared.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'tracks-table-filter',
  standalone: true,
  imports: [
    SharedModule,
    InputSwitchModule,
    TooltipModule
  ],
  templateUrl: './tracks-table-filter.component.html',
  styleUrl: './tracks-table-filter.component.scss'
})
export class TracksTableFilterComponent implements OnInit, OnDestroy {
  @Input() titleTable: string;

  @Input() trackForm: FormGroup = new FormGroup({
    isAdvanced: new FormControl(false),
    date: new FormControl(new Date(), Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/)),
    startDate: new FormControl(new Date(), Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/)),
    endDate: new FormControl(new Date(), Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/))
  });

  @Input() isLoading = false;

  @Output() onFilterChanged = new EventEmitter<FormGroup>();
  @Output() exportEvent = new EventEmitter<any>();
  @Output() showHoverMapEvent = new EventEmitter<void>();

  private destroy$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.trackForm.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.onFilterChanged.emit(this.trackForm);
      });

    this.onFilterChanged.emit(this.trackForm);
  }

  onChangeAdvanced(event: any) {
    this.trackForm.get('isAdvanced').setValue(event.checked);
  }

  onClickExport() {
    this.exportEvent.emit();
  }

  onClickShowHoverMap() {
    this.showHoverMapEvent.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}