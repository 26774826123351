import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserClient } from '../models/user/users';
import { UserGroupClient } from '../models/user-group/user-group';
import { PermissionClient } from '../models/permission/permission';

@Injectable({
  providedIn: 'root'
})
export class UserUtilsService {
  constructor(
    private _translate: TranslateService) { }

  setActionsPersons(person: UserClient): UserClient {
    person.actions = [
      { text: this._translate.instant("ACTIONS.DETAIL"), value: 'detail' },
    ];

    if (person.deleted === false) {
      person.actions.push({ text: this._translate.instant("ACTIONS.EDIT"), value: 'edit' });
      person.actions.push({ text: this._translate.instant("ACTIONS.DELETE"), value: 'delete' });
    }

    return person;
  }

  setActionsUserGroups(userGroup: UserGroupClient): UserGroupClient {
    userGroup.actions = [
      { text: this._translate.instant("ACTIONS.EDIT"), value: 'edit' },
      { text: this._translate.instant("ACTIONS.DELETE"), value: 'delete' },
    ];

    return userGroup;
  }

  setActionsPermissions(permission: PermissionClient): PermissionClient {
    permission.actions = [
      { text: this._translate.instant("ACTIONS.EDIT"), value: 'edit' },
      { text: this._translate.instant("ACTIONS.DELETE"), value: 'delete' },
    ];

    return permission;
  }

  setStatusUser(user: UserClient): UserClient {
    user.statusClient = { text: '', tooltip: '', cssClass: '' };

    if (user.groupIds.length > 0) {
      user.statusClient.text = this._translate.instant("ACTIVE");
      user.statusClient.cssClass = ' bg-success';
    }
    else {
      user.statusClient.text = this._translate.instant("SUSPENDED2");
      user.statusClient.cssClass = ' bg-danger';
    }

    user.statusClient.cssClass += ' custom-badge-bold';

    return user;
  }
}