import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DropdownOption } from '../../../dropdown/dropdown-primary/dropdown.interface';
import { Observable, Observer, Subject, debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { VehicleResponse } from 'src/app/shared/models/vehicle/vehicle-response';
import { FilterModalService } from '../filter-modal.service';
import { UsersService } from 'src/app/shared/api-services/users.service';
import { UserModelResponse } from 'src/app/shared/models/user/user-response';

@Component({
  selector: 'filter-booking-modal',
  templateUrl: './filter-booking-modal.component.html',
  styleUrls: ['./filter-booking-modal.component.scss']
})
export class FilterBookingModalComponent implements OnInit, OnDestroy {
  id = 'filter-booking-modal';
  private tenantId = this._tenantService.getTenantId();
  form: FormGroup;

  @Input() autoCompleteParams: any = {};
  @Input() showUser: boolean = true;
  vehicleDisplayName$: Observable<string[]>;
  vehicleLicensePlate$: Observable<string[]>;
  userDisplayName$: Observable<string[]>;

  private _destroy$ = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _vehiclesService: VehiclesService,
    private _usersService: UsersService,
    private _filterTaskModalService: FilterModalService,
    public bsModalRef: BsModalRef
  ) {
    this.form = new FormGroup({
      bookingTypeId: new FormControl(null),
      vehicleId: new FormControl(null),
      vehicleDisplayName: new FormControl(null),
      vehicleLicensePlate: new FormControl(null),
      userId: new FormControl(null),
      userDisplayName: new FormControl(null),
      headquarterId: new FormControl(null),
      locationId: new FormControl(null),
      driverId: new FormControl(null),
    });
  }

  ngOnInit() {
    let form = this._filterTaskModalService.getForm(this.id);
    if (form) {
      this.form = form;
    }
    this.autoComplete();
  }

  private autoComplete() {
    this.vehicleDisplayName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['vehicleDisplayName'].value);
    }).pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._vehiclesService.listVehicles$(this.tenantId,
        { displayNameContains: token, limit: 5, ...this.autoCompleteParams })),
      map((vehicle: VehicleResponse) => {
        let displayNames: string[] = [];
        vehicle.items.forEach((vehicle) => {
          displayNames.push(vehicle.displayName);
        });
        return displayNames;
      }),
    );

    this.vehicleLicensePlate$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['vehicleLicensePlate'].value);
    }).pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._vehiclesService.listVehicles$(this.tenantId,
        { licensePlateContains: token, limit: 5, ...this.autoCompleteParams })),
      map((vehicle: VehicleResponse) => {
        let licensePlates: string[] = [];
        vehicle.items.forEach((vehicle) => {
          licensePlates.push(vehicle.licensePlate);
        });
        return licensePlates;
      }),
    );

    this.userDisplayName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['userDisplayName'].value);
    }).pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._usersService.listUsers$({ displayNameContains: token, limit: 5, ...this.autoCompleteParams })),
      map((user: UserModelResponse) => {
        let displayNames: string[] = [];
        user.items.forEach((user) => {
          displayNames.push(user.displayName);
        });
        return displayNames;
      }),
    );
  }

  onBookingTypeSelected($event: DropdownOption) {
    this.form.patchValue({ bookingTypeId: $event.value });
  }

  onSelectVehicleDisplayName(match: TypeaheadMatch) {
    const displayName = match.value;

    this._vehiclesService.listVehicles$(this.tenantId, { displayName: displayName }).pipe(
      takeUntil(this._destroy$)
    ).subscribe({
      next: (response) => {
        if (response.items.length > 0) {
          this.form.patchValue({
            vehicleId: response.items[0].id,
            vehicleDisplayName: response.items[0].displayName,
            vehicleLicensePlate: response.items[0].licensePlate
          });
        }
      }
    });
  }

  onSelectVehicleLicensePlate(match: TypeaheadMatch) {
    const licensePlate = match.value;

    this._vehiclesService.listVehicles$(this.tenantId, { licensePlate: licensePlate }).pipe(
      takeUntil(this._destroy$)
    ).subscribe({
      next: (response) => {
        if (response.items.length > 0) {
          this.form.patchValue({
            vehicleId: response.items[0].id,
            vehicleDisplayName: response.items[0].displayName,
            vehicleLicensePlate: response.items[0].licensePlate
          });
        }
      }
    });
  }

  onSelectUserDisplayName(match: TypeaheadMatch) {
    const displayName = match.value;

    this._usersService.listUsers$({ displayName: displayName }).pipe(
      takeUntil(this._destroy$)
    ).subscribe({
      next: (response) => {
        this.form.patchValue({ userId: response.items[0].id, userDisplayName: response.items[0].displayName });
      }
    });
  }

  onHeadquarterSelected($event: DropdownOption) {
    this.form.patchValue({ headquarterId: $event.value });
  }

  onLocationSelected($event: DropdownOption) {
    if ($event.value) {
      this.form.patchValue({ locationId: $event.value });
    }
  }

  apply() {
    this._filterTaskModalService.applyFilterFromForm(this.id, this.form);
    this.bsModalRef.hide();
  }

  reset() {
    this.form.reset();
    this._filterTaskModalService.applyFilterFromForm(this.id, this.form);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
