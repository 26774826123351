<div class="filters mb-3">
    <div class="filters-section-left btn-group">
        <h3 class="table-title">{{ titleTable | translate}}</h3>
    </div>

    <div class="filters-section-right">
      <form [formGroup]="filterForm">
  
        <p-iconField iconPosition="left">
          <p-inputIcon styleClass="pi pi-search" />
          <input type="text" pInputText [placeholder]="'TYPE_TO_SEARCH' | translate" formControlName="inputFilter" />
        </p-iconField>
      </form>

      <button-first-type 
        [cssClass]="'btn-gs-secondary-icon'"
        [iconClass]="'bi bi-funnel'" 
        [buttonText]= "'FILTERR'"
        (click)="onFilterClick()">
      </button-first-type>
        
        <!-- <button-first-type 
            [cssClass]="'btn-gs-secondary-icon'"
            [iconClass]="'bi bi-plus-square'" 
            [buttonText]= "'EXPORT' | translate"
            (click)="onClickExport()">
        </button-first-type> -->
    </div>  
</div>

@if (importFilter) {
  <div class="container-filters d-flex gap-3 align-items-center justify-content-end mb-3">
    @if (importFilter?.importerName) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'IMPORT2' | translate }}: {{ importFilter?.importerName | translate }}</span>
        <i class="bi bi-x" (click)="removeImporterFilter()"></i>
      </button>
    }

    @if (importFilter?.status) {
      <button class="btn-gs-filter">
        <i class="bi bi-funnel"></i>
        <span>{{ 'STATUS' | translate }}: {{ importFilter?.status | translate }}</span>
        <i class="bi bi-x" (click)="removeStatusFilter()"></i>
      </button>
    }

    <button class="btn-gs-secondary-trash" (click)="removeAllFilters()">
      <i class="bi bi-trash"></i>
    </button>
  </div>
}