<div class="d-flex gap-3 flex-column">

    <div class="card p-4">
        @if (permissionsConst.CAN_MANAGE_COSTS === permissionName || permissionsConst.CAN_MANAGE_TASKS === permissionName) {
            <div class="card-title">{{ 'USER_GROUPS.PERMISSIONS.MODAL.TASK_WATCHER_INTEREST_MANAGE' | translate }}</div>
        } @else {
            <div class="card-title">{{ 'USER_GROUPS.PERMISSIONS.MODAL.TASK_WATCHER_INTEREST_READ' | translate }}</div>
        }
        <div class="form-check form-switch">
            <input 
                class="form-check-input" 
                type="checkbox" 
                id="taskWatcherInterest" 
                [checked]="taskWatcher.isInterest" 
                (change)="onClickTaskWatcherInterest()">
            <label class="form-check-label" for="taskWatcherInterest">{{ taskWatcher.isInterest ? ('ACTIONS.YES' | translate) : ('ACTIONS.NO' | translate) }}</label>
        </div>
    </div>

    <div class="card p-4">
        @if (permissionsConst.CAN_MANAGE_COSTS === permissionName || permissionsConst.CAN_MANAGE_TASKS === permissionName) {
            <div class="card-title">{{ 'USER_GROUPS.PERMISSIONS.MODAL.TASK_ASSIGNEE_MANAGE' | translate }}</div>
        } @else {
            <div class="card-title">{{ 'USER_GROUPS.PERMISSIONS.MODAL.TASK_ASSIGNEE_READ' | translate }}</div>
        }
        <div class="form-check form-switch">
            <input 
                class="form-check-input" 
                type="checkbox" 
                id="taskAssignee" 
                [checked]="taskAssignee.isAssignee" 
                (change)="onClickTaskAssignee()">
            <label class="form-check-label" for="taskAssignee">{{ taskAssignee.isAssignee ? ('ACTIONS.YES' | translate) : ('ACTIONS.NO' | translate) }}</label>
        </div>
    </div>

    <div class="accordion">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTaskTypes" aria-expanded="true" aria-controls="collapseTaskTypes">
                    {{ this.taskTypeSwitch.titleSwitch | translate }}
                </button>
            </h2>
            <div id="collapseTaskTypes" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <list-switches 
                        [listSwitches]="this.taskTypeSwitch.listSwitch" 
                        (listSwitchesChange)="onSwitchTaskTypesSelected($event)">
                    </list-switches>                
                </div>
            </div>
        </div>
    </div>
    
    <div class="accordion">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHeadquarters" aria-expanded="true" aria-controls="collapseHeadquarters">
                    {{ this.headquartersSwitch.titleSwitch | translate }}
                </button>
            </h2>
            <div id="collapseHeadquarters" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <list-switches 
                        [listSwitches]="this.headquartersSwitch.listSwitch" 
                        (listSwitchesChange)="onSwitchHeadquartersSelected($event)">
                    </list-switches>                
                </div>
            </div>
        </div>
    </div>
    
    <div class="accordion">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTags" aria-expanded="true" aria-controls="collapseTags">
                    {{ this.tagsSwitch.titleSwitch | translate }}
                </button>
            </h2>
            <div id="collapseTags" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <list-switches 
                        [listSwitches]="this.tagsSwitch.listSwitch" 
                        (listSwitchesChange)="onSwitchTagsSelected($event)">
                    </list-switches>                
                </div>
            </div>
        </div>
    </div>

</div>