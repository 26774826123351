<div class="filters mb-3">
    <div class="filters-section-left btn-group">
        <h3 class="table-title">{{ titleTable | translate}}</h3>
    </div>
  
    <div class="filters-section-right">
        <button type="submit" class="btn-gs-primary" (click)="onValidateImport()" *ngIf="buttonImport?.show">
            {{ 'IMPORT_ROWS' | translate: {value: buttonImport?.toImportRows} }}

            <div class="ms-2 spinner-border spinner-border-sm text-light" role="status" *ngIf="buttonImport?.isLoading">
                <span class="visually-hidden">Loading...</span>
            </div>
        </button>

        <!-- <button type="submit" class="btn-gs-secondary-icon gap-1" (click)="onUpdateTable()">
            {{'UPDATE' | translate}}
            <i class="bi bi-arrow-repeat"></i>
        </button> -->
        
        <button-first-type 
            [cssClass]="'btn-gs-secondary-icon'"
            [iconClass]="'bi bi-funnel'" 
            [buttonText]= "'FILTERR'"
            (click)="onFilterClick()">
        </button-first-type>        

        <button-first-type 
          [cssClass]="'btn-gs-secondary-icon'"
          [iconClass]="'bi bi-download'" 
          [buttonText]= "'EXPORT' | translate"
            (click)="onClickExport()">
        </button-first-type>
    </div>
</div>