import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { DropdownEventsService } from 'src/app/shared/utilities/dropdown-events.service';
import { TableColumn } from '../dynamic-table/dynamic-table-desktop/table-column.interface';
import { TableRow } from '../dynamic-table/dynamic-table-desktop/table-row.interface';
import { FiltersPage } from 'src/app/shared/models/filter-page';
import { ImportFile, MappingExport, RelatedField, RowClient } from "src/app/shared/models/import/import";
import { ImportService } from "src/app/shared/api-services/import.service";
import { ImportUtilsService } from "src/app/shared/utilities/import-utils.servic";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { ButtonImport } from "./import-management-data-filter/import-management-data-filter.component";
import { PatchImportRequest } from "src/app/shared/models/import/import-request";
import { ImportRowsFilter } from 'src/app/shared/models/import/import-filter';
import { CentralQueryParamsService } from 'src/app/core/services/central-query-params.service';
import { ImportDataStatus } from 'src/app/shared/constant/status-import';
import { MappingModel } from 'src/app/shared/models/user/users';
import { Router } from '@angular/router';
import * as RoutesUrl from 'src/app/shared/constant/app-routes';


@Component({
  selector: 'import-management-data-table',
  templateUrl: './import-management-data-table.component.html'
})
export class ImportManagementDataTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() importFile: ImportFile;

  tableColumns: TableColumn[];
  tableRows: TableRow[];
  
  isLoading = false;

  filtersPage: FiltersPage = {
    firstElement: 0,
    page: 1,
    itemsPerPage: 10,
    listRowToShowPerPage: [5, 10, 20, 30, 50, 100],
    totalRows: 0,
    totalPage: 1,
    orderBy: null,
    maxPagesToShowPagination: 6
  };

  buttonImport: ButtonImport = {
    toImportRows: 0,
    show: false,
    isLoading: false,
  }
  
  private _statusImport: ImportDataStatus;
  private _header: {
    [key: string]: string;
  };
  private _isMobile = false;
  private _destroy = new Subject<void>();

  constructor(
    private _importsService: ImportService,
    private _importUtilsService: ImportUtilsService,
    private _dropdownEventsService: DropdownEventsService,
    private _breakpointObserver: BreakpointObserver,
    private _cdr: ChangeDetectorRef,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private _centralQueryParamsService: CentralQueryParamsService,
    private _router: Router
  ) {
    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(result => {
        this._isMobile = result.matches;
      });

    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = '-Id';
  }

  ngOnInit() {
    this._isMobile ? this.filtersPage.maxPagesToShowPagination = 3 : this.filtersPage.maxPagesToShowPagination = 6;
    this.getImportRows();

    this._centralQueryParamsService.getQueryParam$('status-import-file').pipe(
      takeUntil(this._destroy)
    ).subscribe(status => {
      //check if respect ImportDataStatus
      if (Object.values(ImportDataStatus).includes(status as ImportDataStatus)) {
        this._statusImport = status as ImportDataStatus;
        this.getImportRows();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['importFile'] && changes['importFile'].currentValue) {
      this.getImportRows();
    }
  }

  getTable() {
    if (this.importFile && this._header) {
      this.tableColumns = [];
      if (!this._isMobile) {
        this.tableColumns = Object.keys(this._header).map(key => {
          return {
            header: key,
            field: key,
            value: (row: RowClient) => row[key]?.text,
            cssClass: (row: RowClient) => row[key]?.cssClass,
            tooltip: (row: RowClient) => row[key]?.tooltip,
          }
        })

        this.tableColumns.push(
          {
            field: 'status',
            header: "STATUS",
            value: (row: RowClient) => row.status?.text,
            cssClass: (row: RowClient) => row.status?.cssClass,
            tooltip: (row: RowClient) => row.status?.tooltip,
          }
        );
      } else {
        this.tableColumns = [

        ];
      }
      this._cdr.detectChanges();
    }
  }

  getImportRows() {
    if (this.importFile) {
      let params: ImportRowsFilter = {
        start: this.filtersPage.firstElement,
        limit: this.filtersPage.itemsPerPage,
        orderBy: this.filtersPage.orderBy,
      }

      if (this._statusImport) {
        params.status = this._statusImport;
      }

      this._importsService.listImportRows$(this.importFile.id, params)
        .pipe(
          takeUntil(this._destroy)
        )
        .subscribe({
          next: response => {
            this.tableRows = response.items.map(importRow => this._importUtilsService.convertRowToRowClient(importRow));
            this._header = response.items[0].row;
            this.getTable();

            this.buttonImport.show = response.items.some(x => x.status === 'VALID');
            this.buttonImport.toImportRows = this.importFile.validatedRows;

            this.filtersPage.totalRows = response.count;
            this.filtersPage.totalPage = Math.ceil(this.filtersPage.totalRows / this.filtersPage.itemsPerPage);
          }
        });
    }
  }

  startImport() {
    this.buttonImport.isLoading = true;
    let request: PatchImportRequest = {
      name: this.importFile.name,
      confirmed: true,
    };

    this._importsService.listImporterFields$(this.importFile.importerName).pipe(
      takeUntil(this._destroy),
      switchMap(response => {
        let mappings = response.reduce((acc, field) => {
          acc[field.name] = this._translateService.instant(field.name);
          return acc;
        }, {} as RelatedField);

        request.mappings = mappings;

        return this._importsService.patchImport$(this.importFile.id, request)
      })
    ).subscribe({
      next: response => {
        if (response.id) {
          this._toastrService.success(this._translateService.instant('IMPORT.MESSAGES.IMPORT_SUCCESS'));
          this._router.navigate([RoutesUrl.IMPORTS]);
        }
        else {
          this._toastrService.error(this._translateService.instant('IMPORT.MESSAGES.IMPORT_ERROR'));
        }
      },
      complete: () => {
        this.buttonImport.show = false;
        this.buttonImport.isLoading = false;
      }
    });

  }

  updateTable() {
    this.getImportRows();
  }

  onActionTable() {
    this.tableRows.forEach((row) => {
      this._dropdownEventsService.getSelectedOptionOnClick(row.id).pipe(
        takeUntil(this._destroy)
      ).subscribe(
        (option) => {
          if (option && option.value === 'edit') {

          }
        }
      );
    });
  }

  onSortColumnHeader(columnField: string) {
    this.filtersPage.orderBy = columnField;
    this.getImportRows();
  }

  onPageChange($newPage: number) {
    this.filtersPage.page = $newPage;
    this.filtersPage.firstElement = ($newPage - 1) * this.filtersPage.itemsPerPage;
    this.getImportRows();
  }

  onPageSizeChange(newSize: number): void {
    this.filtersPage.itemsPerPage = newSize;
    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = null;
    this.getImportRows();
  }

  getExport() {
    if (!this._header || typeof this._header !== 'object') {
        this._toastrService.error(this._translateService.instant('IMPORT.MESSAGES.INVALID_HEADER'));
        return;
    }

    const headerKeys = Object.keys(this._header);

    // Crea l'oggetto mappings conforme a RelatedField
    let mappings: MappingModel = {
        mappings: headerKeys.map(key => ({ sourceName: 'Row.'+key, destName: key }))
    };

    let filter: ImportRowsFilter = {
        status: this._statusImport
    }

    this._importsService.exportImportRow$(this.importFile.id, filter, mappings)
      .pipe(takeUntil(this._destroy))
      .subscribe({
        next: response => {
            const filename = 'export_import.xlsx';
            const blob = new Blob([response.body], { type: response.body.type });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.click();
            window.URL.revokeObjectURL(url);
        }
      });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }
}
