<div class="d-flex gap-3 flex-column">

    @if (permissionsConst.CAN_ADMIN_BOOKINGS !== permissionName) {
        <div class="card p-4">
            @if (permissionsConst.CAN_MANAGE_BOOKINGS === permissionName) {
                <div class="card-title">{{ 'USER_GROUPS.PERMISSIONS.MODAL.BOOKING_FOR_ME_MANAGE' | translate }}</div>
            }
            @if (permissionsConst.CAN_READ_BOOKINGS === permissionName) {
                <div class="card-title">{{ 'USER_GROUPS.PERMISSIONS.MODAL.BOOKING_FOR_ME_READ' | translate }}</div>
            }
            @if (permissionsConst.CAN_APPROVE_BOOKINGS === permissionName) {
                <div class="card-title">{{ 'USER_GROUPS.PERMISSIONS.MODAL.BOOKING_FOR_ME_APPROVE' | translate }}</div>
            }
            @if (permissionsConst.CAN_MANAGE_VEHICLE_USAGES === permissionName) {
                <div class="card-title">{{ 'USER_GROUPS.PERMISSIONS.MODAL.BOOKING_FOR_ME_MANAGE_VEHICLE_USAGES' | translate }}</div>
            }
            @if (permissionsConst.CAN_READ_VEHICLE_USAGES === permissionName) {
                <div class="card-title">{{ 'USER_GROUPS.PERMISSIONS.MODAL.BOOKING_FOR_ME_READ_VEHICLE_USAGES' | translate }}</div>
            }
            <div class="form-check form-switch">
                <input 
                    class="form-check-input" 
                    type="checkbox" 
                    id="bookingForMe" 
                    [checked]="bookingForMe.isForMe" 
                    (change)="onClickBookingForMe()">
                <label class="form-check-label" for="bookingForMe">{{ bookingForMe.isForMe ? ('USER_GROUPS.PERMISSIONS.ONLY_ME' | translate) : ('ALL' | translate) }}</label>
            </div>
        </div>
    }

    @if (this.bookingTypeSwitch.showSwitch) {
        <div class="accordion">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBookingTypes" aria-expanded="true" aria-controls="collapseBookingTypes">
                        {{ this.bookingTypeSwitch.titleSwitch | translate }}
                    </button>
                </h2>
                <div id="collapseBookingTypes" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <list-switches 
                            [listSwitches]="this.bookingTypeSwitch.listSwitch" 
                            (listSwitchesChange)="onSwitchBookingTypesSelected($event)">
                        </list-switches>                
                    </div>
                </div>
            </div>
        </div>
    }
    
    <div class="accordion">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHeadquarters" aria-expanded="true" aria-controls="collapseHeadquarters">
                    {{ this.headquartersSwitch.titleSwitch | translate }}
                </button>
            </h2>
            <div id="collapseHeadquarters" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <list-switches 
                        [listSwitches]="this.headquartersSwitch.listSwitch" 
                        (listSwitchesChange)="onSwitchHeadquartersSelected($event)">
                    </list-switches>                
                </div>
            </div>
        </div>
    </div>

    <div class="accordion">
        <div class="accordion-item">
            <h2 class="accordion-locations" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLocations" aria-expanded="true" aria-controls="collapseLocations">
                    {{ this.locationsSwitch.titleSwitch | translate }}
                </button>
            </h2>
            <div id="collapseLocations" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <list-switches 
                        [listSwitches]="this.locationsSwitch.listSwitch" 
                        (listSwitchesChange)="onSwitchLocationsSelected($event)">
                    </list-switches>                
                </div>
            </div>
        </div>
    </div>

    @if (userGroupsSwitch.showSwitch) {
        <div class="accordion">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseUserGroups" aria-expanded="true" aria-controls="collapseUserGroups">
                        {{ this.userGroupsSwitch.titleSwitch | translate }}
                    </button>
                </h2>
                <div id="collapseUserGroups" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <list-switches 
                            [listSwitches]="this.userGroupsSwitch.listSwitch" 
                            (listSwitchesChange)="onSwitchUserGroupsSelected($event)">
                        </list-switches>                
                    </div>
                </div>
            </div>
        </div>
    }
    

</div>