import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Observable, Observer, Subscription, debounceTime, distinctUntilChanged, map, of, switchMap } from 'rxjs';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { AssetsService } from 'src/app/shared/api-services/assets.service';
import { Asset } from 'src/app/shared/models/assets/asset';
import { AssetResponse } from 'src/app/shared/models/assets/asset-response';

@Component({
  selector: 'button-add-asset',
  templateUrl: './button-add-asset.component.html',
  styleUrls: ['./button-add-asset.component.scss']
})
export class ButtonAddAssetComponent {
  tenantId: string = this._tenantService.getTenantId();

  @Input() text: string;

  @Input() assetId: string;
  asset: Observable<Asset>;
  assetSrcImage: Observable<SafeUrl>;

  @Input() autoCompleteParams: any = {};

  isEditMode = false;

  form: FormGroup = new FormGroup({
    assetDisplayName: new FormControl(),
  });
  assetDisplayName$: Observable<string[]>;

  @Output() assetIdChange = new EventEmitter<string>();

  subscription: Subscription;

  constructor(
    private _tenantService: TenantService,
    private _assetsService: AssetsService,
    private _elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.autoComplete();

    if (this.assetId) {
      this.getAsset();
    }
  }

  private getAsset() {
    this.asset = this._assetsService.getAsset$(this.tenantId, this.assetId);
    this.assetSrcImage = this._assetsService.getAssetImage$(this.tenantId, this.assetId, 48, 48);
  }

  onAddAsset() {
    this.isEditMode = true;
  }

  private autoComplete() {
    this.assetDisplayName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['assetDisplayName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._assetsService.listAssets$(this.tenantId,
        { displayNameContains: token, limit: 5, ...this.autoCompleteParams })),
      map((asset: AssetResponse) => {
        let displayNames: string[] = [];
        asset.items.forEach((asset) => {
          displayNames.push(asset.displayName);
        });
        return displayNames;
      }),
    );
  }

  onSelectAsset(match: TypeaheadMatch) {
    const displayName = match.value;

    this._assetsService.listAssets$(this.tenantId, { displayName: displayName }).subscribe({
      next: (response) => {
        if (response.items.length > 0) {
          this.asset = of(response.items[0]);
          this.assetSrcImage = this._assetsService.getAssetImage$(this.tenantId, response.items[0].id, 48, 48);
          this.form.controls['assetDisplayName'].setValue(null);
          this.isEditMode = false;
          this.assetIdChange.emit(response.items[0].id);
        }
      }
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedInside = this._elementRef.nativeElement.contains(event.target);
    const clickedOnInput = (event.target as HTMLElement).id === 'assetId';
    const clickedOnButton = (event.target as HTMLElement).closest('#btn-add-asset');
    if (!clickedInside && !clickedOnInput && !clickedOnButton && this.isEditMode) {
      this.isEditMode = false;
    } else if (clickedOnButton && !this.isEditMode) {
      this.isEditMode = true;
    }
  }

  onDelete() {
    this.asset = of(null);
    this.isEditMode = true;
    this.assetIdChange.emit(null);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}