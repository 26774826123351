import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { TableColumn } from './table-column.interface';
import { TableRow } from './table-row.interface';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTableComponent<T> {
  @Input() columns!: TableColumn[];
  @Input() rows!: TableRow[];

  @Input() isLoading: boolean = false;
  @Input() hasCheckbox: boolean = false;

  @Input() totalItems!: number;
  @Input() itemsPerPage!: number;
  @Input() maxPagesToShowPagination: number = 6;

  @Output() pageChanged = new EventEmitter<number>();
  @Output() pageSizeChanged = new EventEmitter<number>();

  @Output() sortColumn = new EventEmitter<string>();

  @Output() emitTableRows = new EventEmitter<TableRow[]>();

  @Input() listRowToShowPerPage!: number[];
  @Input() selectedPageSize!: number;

  @Input() filterHeader!: string[];
  goToPageNumber: string = '';

  currentPage: number = 1;

  currentSortColumn: string = '';
  currentSortDirection: string = '';

  sortVisualStatus: { [key: string]: boolean } = {};

  showFooterRight: boolean;
  isMobile: boolean = false;

  headerIcons: { [key: string]: string } = {};

  private subscription: any;

  maxWidthStyle: string;

  @Input() stickyLastColumn: boolean = false;

  @Input() customId?: string = 'id';

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private spinner: NgxSpinnerService
  ) {
    this.subscription = this._breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.showFooterRight = !this.isMobile;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isLoading'] && this.isLoading) {
      this.spinner.show();
    }
    else if (changes['isLoading'] && !this.isLoading) {
      this.spinner.hide();
    }

    if (changes['columns'] && this.columns) {
      this.columns.forEach(column => {
        if (column.isSortable) {
          this.headerIcons[column.field] = 'bi bi-filter';
        }
      });
    }
    this.maxWidthStyle = 100 / this.columns?.length + 'vw';
    if (this.rows && this.rows.length > 0) {
      this.rows;
    }
  }

  onMouseEnter(event: MouseEvent) {
    const target = event.target as HTMLElement;
    target.classList.add('expanded');
  }

  onMouseLeave(event: MouseEvent) {
    const target = event.target as HTMLElement;
    setTimeout(() => {
      target.classList.remove('expanded');
    }, 1000);
  }

  onGoToPage() {
    let page = Number(this.goToPageNumber);
    if (page && page > 0 && page <= this.totalPages()) {
      this.currentPage = page;
      this.pageChanged.emit(this.currentPage);
    }
  }

  totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  sortColumnHeader(columnField: string) {

    const sortableColumn = this.columns.find(column => column.field === columnField);
    if (!sortableColumn?.isSortable) {
      return;
    }

    this.resetSortVisualStatusAndIcons(columnField);

    if (this.currentSortColumn !== columnField) {
      this.currentSortDirection = 'asc';
    } else {
      this.currentSortDirection = this.currentSortDirection === 'asc' ? 'desc' : 'asc';
    }
    this.currentSortColumn = columnField;

    this.headerIcons[columnField] = this.currentSortDirection === 'asc' ? 'bi-sort-up' : 'bi-sort-down';

    const sortOrder = this.currentSortDirection === 'asc' ? '' : '-';
    const sortField = columnField.charAt(0).toUpperCase() + columnField.slice(1);

    this.sortColumn.emit(sortOrder + sortField);
  }

  onPageSizeChange($event: number) {
    this.pageSizeChanged.emit($event);
  }

  private resetSortVisualStatusAndIcons(exceptColumn: string) {
    this.columns.forEach(column => {
      if (column.isSortable) {
        this.headerIcons[column.field] = column.field === exceptColumn ? this.headerIcons[column.field] : 'bi bi-filter';
      }
    });
  }

  displayValue(row: TableRow, column: TableColumn) {
    if (column.value) {
      return column.value(row);
    } else {
      return row[column.field];
    }
  }

  setCssClasses(row: TableRow, column: TableColumn): string {
    if (column.cssClass) {
      const classes = column.cssClass(row);
      return classes ? classes : '';
    }
    return '';
  }

  setActionOptions(row: TableRow, column: TableColumn) {
    return column.dotOptions ? column.dotOptions(row) : [];
  }

  setTooltip(row: TableRow, column: TableColumn) {
    return column.tooltip ? column.tooltip(row) : '';
  }

  setRouteLink(row: TableRow, column: TableColumn) {
    return column.routerLink ? column.routerLink(row) : '';
  }

  setQueryParams(row: TableRow, column: TableColumn) {
    return column.queryParams ? column.queryParams(row) : {};
  }

  selectAllRows(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.rows.forEach(row => {
      row.isSelected = isChecked;
    });
  }

  selectRow(id: string, event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.rows.find(row => row.id === id).isSelected = isChecked;
    this.emitTableRows.emit(this.rows);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
