import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { ModalUtilsService } from 'src/app/shared/utilities/modal-utils.service';

@Component({
  selector: 'assets-table-filters',
  templateUrl: './assets-table-filters.component.html',
  styleUrls: ['./assets-table-filters.component.scss']
})
export class AssetsTableFiltersComponent {
  @Input() titleTable: string;
  @Input() hasManageAssetPermission: boolean;

  listTableFilterHeaders!: DropdownOption[];
  filterHeader: DropdownOption;

  listMassiveActions: DropdownOption[];

  filterForm: FormGroup = new FormGroup({
    headerToFilter: new FormControl(),
    inputFilter: new FormControl(),
    startDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/)),
    endDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/))
  });

  @Output() filterTableHeaderChanged = new EventEmitter<FormGroup>();
  @Output() exportEvent = new EventEmitter<any>();
  @Output() onMassiveAction = new EventEmitter<string>();

  constructor(
    private _modalUtilsService: ModalUtilsService,
    private _translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.listTableFilterHeaders = this._translateService.instant('ASSETS.FILTERS.HEADERS');
    this.filterHeader = this.listTableFilterHeaders[0];

    this.listMassiveActions = this._translateService.instant('MASSIVE_ACTIONS');

    this.filterForm.patchValue({ headerToFilter: this.filterHeader.value });
    this.filterForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.filterTableHeaderChanged.emit(this.filterForm);
      });
  }

  selectedOption($event: DropdownOption) {
    this.filterHeader = $event;
    this.filterForm.patchValue({ headerToFilter: $event.value });
  }

  selectMassiveOption($event: DropdownOption) {
    this.onMassiveAction.emit($event.value);
  }

  onFilterClick() {
    this._modalUtilsService.openAssetFilterModal().subscribe();
  }

  onClickExport() {
    this.exportEvent.emit();
  }
}