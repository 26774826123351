<div class="card-user-group-modal">
    <header class="card-user-group-modal-header">
        @if (this.modalStatus.isCreate) {
            <h4 class="card-user-group-modal-title">
                {{ 'USER_GROUPS.NEW' | translate }}
            </h4>
        }
        @if (this.modalStatus.isPatch) {
            <h4 class="card-user-group-modal-title">
                {{ 'USER_GROUPS.EDIT' | translate }}
            </h4>
        }
            
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
    </header>

    <content>
        <form [formGroup]="userGroupForm">

            <div class="container-main-fields d-flex gap-3 flex-column mb-4">
    
                <div class="form-group">
                    <label for="name" class="form-label">{{ 'USER_GROUPS.NAME_LABEL' | translate }} <span>*</span></label>
                    <input type="text" class="form-control" id="name" formControlName="name" aria-describedby="name-help"
                        [class.is-invalid]="userGroupForm.controls['name'].invalid && (userGroupForm.controls['name'].dirty || userGroupForm.controls['name'].touched)">
                    <span *ngIf="userGroupForm.controls['name'].hasError('invalid')" class="invalid-feedback">
                        {{ 'USER_GROUPS.MESSAGES.ERROR_NAME_REQUIRED' | translate }}
                    </span>
                </div>

                <div class="form-group">
                    <label for="description" class="form-label">{{ 'USER_GROUPS.DESCRIPTION_LABEL' | translate }}</label>
                    <textarea type="text" class="form-control" id="description" formControlName="description" aria-describedby="description-help"></textarea>
                </div>

                <div class="form-group">
                    <label for="rank" class="form-label">{{ 'USER_GROUPS.RANK_LABEL' | translate }}</label>
                    <input type="number" class="form-control" id="rank" formControlName="rank" aria-describedby="rank-help">
                    <span class="form-text text-muted">
                        {{ 'USER_GROUPS.RANK_HELP' | translate }}
                    </span>
                </div>

            </div>

            <div class="d-flex gap-3 justify-content-center mt-4">
                <div type="button" class="btn-gs-secondary-light" (click)="this.bsModalRef.hide()">
                    {{ 'ACTIONS.CANCEL' | translate }}
                </div>

                @if (userGroup?.locked) {
                    <button type="submit" class="btn-gs-primary" 
                        [disabled]="userGroup?.locked" 
                        [pTooltip]="'USER_GROUPS.TOOLTIP_LOCKED' | translate"
                    >
                        {{ 'ACTIONS.SAVE' | translate }}
                    </button>
                }
                @if (!userGroup?.locked) {
                    <button type="submit" class="btn-gs-primary" (click)="onSubmit()">
                        {{ 'ACTIONS.SAVE' | translate }}
                    </button>
                }
            </div>

        </form>
    </content>
</div>