export const VECTORE_API_BASE_URL = getApiBaseUrl();

function getApiBaseUrl(): string {
  const host = window.location.host;
  let apiBaseUrl = '';

  if (host.includes('vectore.eu')) {
    apiBaseUrl = 'https://api.vectore.eu';
  } else if (host.includes('vectore.cloud')) {
    apiBaseUrl = 'https://api.vectore.cloud';
  } else {
    apiBaseUrl = 'https://api.vectore.dev';
  }

  return apiBaseUrl;
}