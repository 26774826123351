import * as Sentry from "@sentry/angular-ivy";
import * as RoutesUrl from 'src/app/shared/constant/app-routes';
import * as Permissions from 'src/app/shared/constant/permissions';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, switchMap, takeUntil } from 'rxjs';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { DropdownEventsService } from 'src/app/shared/utilities/dropdown-events.service';
import { TableColumn } from '../dynamic-table/dynamic-table-desktop/table-column.interface';
import { TableRow } from '../dynamic-table/dynamic-table-desktop/table-row.interface';
import { FiltersPage } from 'src/app/shared/models/filter-page';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';
import { FormGroup } from '@angular/forms';
import { MappingModel, UserModel } from "src/app/shared/models/user/users";
import { AssetsService } from "src/app/shared/api-services/assets.service";
import { Asset, AssetClient } from "src/app/shared/models/assets/asset";
import { AssetUtilsService } from "src/app/shared/utilities/asset-utils.servic";
import { ModalUtilsService } from "src/app/shared/utilities/modal-utils.service";
import { FilterModalService } from "../../modals/filter-modal/filter-modal.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ConfirmModalComponent } from "../../modals/confirm-modal/confirm-modal.component";
import { AssetsFilter } from "src/app/shared/models/assets/asset-filter";
import { MeService } from "src/app/shared/api-services/me.service";
@Component({
  selector: 'assets-table',
  templateUrl: './assets-table.component.html',
  styleUrls: ['./assets-table.component.scss']
})
export class AssetsTableComponent implements OnInit, OnDestroy {
  tenantId = this._tenantService.getTenantId();

  @Input() titleTable: string;
  @Input() user: UserModel;

  assetTypeId: string;

  tableColumns: TableColumn[];
  tableRows: TableRow[];

  filterHeader: string;
  filterTask: DropdownOption;

  queryParams: AssetsFilter = {};

  fitersTable: FormGroup;
  expirationStartDate: string;
  expirationEndDate: string;

  filtersPage: FiltersPage = {
    firstElement: 0,
    page: 1,
    itemsPerPage: 10,
    listRowToShowPerPage: [5, 10, 20, 30, 50, 100],
    totalRows: 0,
    totalPage: 1,
    orderBy: null,
    maxPagesToShowPagination: 6
  };

  isMobile = false;
  hasManageAssetPermission: boolean = false;
  inputFilter: string;

  confirmModal: BsModalRef;

  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _meService: MeService,
    private _assetsService: AssetsService,
    private _assetUtilsService: AssetUtilsService,
    private _translateService: TranslateService,
    private _dropdownEventsService: DropdownEventsService,
    private _toastrService: ToastrService,
    private _breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    private _cdr: ChangeDetectorRef,
    private _communicationService: CommunicationService,
    private _modalUtilsService: ModalUtilsService,
    private _filterModalService: FilterModalService,
    private _modalService: BsModalService,
    public bsModalRef: BsModalRef,
  ) {
    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        this.isMobile = result.matches;
      });

    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = '-Id';
  }

  ngOnInit(): void {
    this.isMobile ? this.filtersPage.maxPagesToShowPagination = 3 : this.filtersPage.maxPagesToShowPagination = 6;
    this.getTable();
    this.applyFilterToTable();
    this.checkPermissions();
    this._communicationService.getEvent()
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          this.getAssets();
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }

  checkPermissions() {
    this._meService.getMePermissions$(this.tenantId).subscribe(response => {
      this.hasManageAssetPermission = response.permissions.includes(Permissions.CAN_MANAGE_ASSETS);
      if (!this.hasManageAssetPermission) {
        this.tableColumns = this.tableColumns.filter(column => column.field !== 'actionsDynamic');
      }
    });
  }

  getTable() {
    this.getAssets();

    this.tableColumns = [];
    if (!this.isMobile) {
      this.tableColumns = [
        {
          field: 'displayName',
          isSortable: true,
          header: "NAME",
          routerLink: (asset: Asset) => this.hasManageAssetPermission ? `/${RoutesUrl.ASSETS}/${RoutesUrl.ASSETS_DETAIL}/${asset.id}` : null,
          cssClass: () => this.hasManageAssetPermission ? 'cursor-pointer' : '',
        },
        {
          field: 'assetTypeId',
          isSortable: true,
          header: "TYPOLOGY",
          value: (booking: Asset) => booking.assetType?.name || ''
        },
        {
          field: 'vehiclesInUse',
          isSortable: true,
          header: "VEHICLESS",
          value: (booking: Asset) => booking.vehiclesInUse || '0'
        },
        {
          field: 'usersInUse',
          isSortable: true,
          header: "PERSONS.NAME",
          value: (booking: Asset) => booking.usersInUse || '0'
        },
        {
          field: 'headquarterId',
          isSortable: true,
          header: "HEADQUARTER.NAME",
          value: (asset: Asset) => asset.headquarter?.name || ''
        },
        {
          field: 'actionsDynamic',
          isSortable: false,
          header: "ACTIONS.NAME",
          dotOptions: (asset: AssetClient) => asset.actions
        }
      ];
    } else {
      this.tableColumns = [
        {
          field: 'displayName',
          isSortable: true,
          header: "NAME",
          routerLink: (asset: Asset) => this.hasManageAssetPermission ? `/${RoutesUrl.ASSETS}/${RoutesUrl.ASSETS_DETAIL}/${asset.id}` : null,
          cssClass: () => this.hasManageAssetPermission ? 'cursor-pointer' : '',
        },
        {
          field: 'actionsDynamic',
          isSortable: false,
          header: "ACTIONS.NAME",
          dotOptions: (asset: AssetClient) => asset.actions
        }
      ];
    }
    this._cdr.detectChanges();
  }

  getAssets() {

    this.queryParams.start = this.filtersPage.firstElement;
    this.queryParams.limit = this.filtersPage.itemsPerPage;
    this.queryParams.orderBy = this.filtersPage.orderBy;
    this.queryParams.includeHeadquarter = true;
    this.queryParams.includeAssetType = true;

    if (this.assetTypeId) {
      this.queryParams.assetTypeId = this.assetTypeId;
    }

    this.applyFilter();

    this._assetsService.listAssets$(this.tenantId, this.queryParams)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          this.tableRows?.forEach(row => this._dropdownEventsService.removeSubscriptions(row.id));
          this.tableRows = response.items.map(asset => this._assetUtilsService.setActionsAssets(asset));

          this.onActionTable();

          this.filtersPage.totalRows = response.count;
          this.filtersPage.totalPage = Math.ceil(this.filtersPage.totalRows / this.filtersPage.itemsPerPage);
        }
      });
  }

  private applyFilter() {
    if (this.fitersTable) {
      if (this.fitersTable.value.headerToFilter && this.fitersTable.value.headerToFilter === 'DisplayName') {
        this.inputFilter = this.fitersTable.value.inputFilter;
        this.queryParams.displayNameContains = this.inputFilter;
      }
    }
  }

  getDataForm($event: FormGroup) {
    this.fitersTable = $event;
    this.getAssets();
  }

  onActionTable() {
    this.tableRows?.forEach((row) => {
      this._dropdownEventsService.getSelectedOptionOnClick(row.id)
        .pipe(takeUntil(this._destroy$))
        .subscribe(
          (option) => {

            if (option && option.value === 'edit') {
              let asset: Asset = { id: row.id };
              this._modalUtilsService.openAssetModal(asset).subscribe();
            }

            if (option && option.value === 'delete') {
              let confirmDeleteModal = this._modalService.show(ConfirmModalComponent, {
                class: 'modal-sm',
                animated: true,
                initialState: {
                  data: {
                    content: 'MESSAGES.CONFIRM_DELETE'
                  }
                }
              });

              confirmDeleteModal.content.onConfirm.pipe(
                takeUntil(this._destroy$),
                switchMap(() => this._assetsService.deleteAsset$(this.tenantId, row.id))
              ).subscribe({
                next: (response) => {
                  if (response.status == 204) {
                    this.getAssets();
                    this._toastrService.success(this._translateService.instant('ASSETS.MESSAGES.SUCCESS_DETELED'));
                  }
                }
              });
            }

            if (option && option.value == 'detail') {
              this._router.navigate([`${RoutesUrl.ASSETS_DETAIL}/${row.id}`], { relativeTo: this._activeRoute });
            }
          }
        );
    });
  }

  onMassiveSelections($event: TableRow[]) {
    this.tableRows = $event;
  }

  onMassiveAction($event: string) {
    if ($event === 'delete_selected') {
      let elementsSelected = this.tableRows.filter(element => element.isSelected);
      this.confirmModal = this._modalService.show(ConfirmModalComponent, {
        class: 'modal-sm',
        animated: true,
        initialState: {
          data: {
            content: this._translateService.instant('MODAL_DELETE_CONFIRM.MESSAGE', { x: elementsSelected.length }),
          }
        }
      });

      this.confirmModal.content.onConfirm.pipe(
        takeUntil(this._destroy$),
      )
        .subscribe(() => {
          for (let index = 0; index < elementsSelected.length; index++) {
            this._assetsService.deleteAsset$(this.tenantId, elementsSelected[index].id)
              .pipe(takeUntil(this._destroy$))
              .subscribe({
                next: (response) => {
                  if (response?.status == 204) {
                    if (index === elementsSelected.length - 1) {
                      this.getAssets();
                    }
                    this._toastrService.success(this._translateService.instant('ASSETS.MESSAGES.SUCCESS_DETELED'));
                  }
                }
              });
          }
        });
    }
  }

  onSortColumnHeader(columnField: string) {
    this.filtersPage.orderBy = columnField;
    this.getAssets();
  }

  onPageChange($newPage: number) {
    this.filtersPage.page = $newPage;
    this.filtersPage.firstElement = ($newPage - 1) * this.filtersPage.itemsPerPage;
    this.getAssets();
  }

  onPageSizeChange(newSize: number): void {
    this.filtersPage.itemsPerPage = newSize;
    this.filtersPage.page = 1;
    this.filtersPage.firstElement = 0;
    this.filtersPage.orderBy = null;
    this.getAssets();
  }

  private applyFilterToTable() {
    this._filterModalService.getForm$('filter-asset-modal')
      .pipe(takeUntil(this._destroy$))
      .subscribe(form => {
        if (form) {
          this.assetTypeId = form.value.assetTypeId ?? null;
          this.getAssets();
        }
      });
  }

  getExport() {
    let params: AssetsFilter = {
      assetTypeId: this.queryParams.assetTypeId ?? null
    };
    this._assetsService.listAssetFields$(this.tenantId, params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          let mappings: MappingModel = {
            mappings: [
              { sourceName: 'AssetType.Name', destName: this._translateService.instant('AssetType') },
              { sourceName: 'Headquarter.Name', destName: this._translateService.instant('Headquarter') },
              { sourceName: 'Code', destName: 'Code' },
              { sourceName: 'DisplayName', destName: this._translateService.instant('DisplayName') },
            ]
          };

          if (response.items && response.items.length > 0) {
            response.items.forEach(field => {
              mappings.mappings.push({ sourceName: `ExtraFields.${field.name}`, destName: this._translateService.instant('ExtraFields.' + field.name) });
            });
          }

          this._assetsService.exportAssets$(this.tenantId, mappings, this.queryParams)
            .pipe(takeUntil(this._destroy$))
            .subscribe({
              next: (response) => {
                const filename = 'export_asset.xlsx';
                const blob = new Blob([response.body], { type: response.body.type });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                link.click();
                window.URL.revokeObjectURL(url);
              },
              error: (error) => {
                Sentry.captureEvent(error);
              }
            });
        },
        error: error => {
          Sentry.captureEvent(error);
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }
}
