import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Observer, debounceTime, distinctUntilChanged, from, map, switchMap } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { HeadquartersService } from 'src/app/shared/api-services/headquarters.service';
import { HeadquarterResponse } from 'src/app/shared/models/headquarter/headquarter-response';
import { FilterModalService, GenericFilter } from 'src/app/shared/components/modals/filter-modal/filter-modal.service';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'modal-reports-filter',
  templateUrl: './modal-reports-filter.component.html',
  styleUrls: ['./modal-reports-filter.component.scss']
})

export class ModalReportsFilterComponent {

  @Input() id = 'filter-reports-modal';
  private tenantId = this._tenantService.getTenantId();
  form: FormGroup;

  filterSelected: DropdownOption;
  typeFilter: string;
  listFilterOption: DropdownOption[] = [];

  mainFilter: GenericFilter;

  @Input() autoCompleteParams: any = {};
  headquarterName$: Observable<string[]>;

  constructor(
    private _tenantService: TenantService,
    private _headquartersService: HeadquartersService,
    private _filterModalService: FilterModalService,
    private _translateService: TranslateService,
    public bsModalRef: BsModalRef
  ) {
    this.form = new FormGroup({
      typeMileage: new FormControl(null),

      startMileage: new FormControl(null),
      endMileage: new FormControl(null),

      startDate: new FormControl(null),
      endDate: new FormControl(null),

      startCost: new FormControl(null),
      endCost: new FormControl(null),

      headquarterId: new FormControl(null),
      headquarterName: new FormControl(null),

      vehicleTypeId: new FormControl(null),
      vehicleTypeName: new FormControl(null),
    });
  }

  ngOnInit() {
    this.autoComplete();
    this.initFilterOptions();
  }

  private initFilterOptions() {
    this.listFilterOption = this._translateService.instant('REPORTS.REPORT_FILTER.LIST_FILTERS');
    if (this.listFilterOption?.length > 0) {
      this.filterSelected = JSON.parse(JSON.stringify(this.listFilterOption[0]));
      this.typeFilter = this.filterSelected.value;
    };
  }

  onFilterSelected($event: DropdownOption) {
    this.typeFilter = $event.value;
  }

  private autoComplete() {
    this.headquarterName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['headquarterName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._headquartersService.listHeadquarters$(this.tenantId,
        { nameContains: token, limit: 5, ...this.autoCompleteParams })),
      map((response: HeadquarterResponse) => {
        let displayNames: string[] = [];
        response.items.forEach((headquarter) => {
          displayNames.push(headquarter.name);
        });
        return displayNames;
      }),
    );
  }

  onVehicleTypeSelected($event: DropdownOption) {
    this.form.patchValue({
      vehicleTypeId: $event.value,
      vehicleTypeName: $event.text
    });
  }

  onSelectHeadquarter(match: TypeaheadMatch) {
    const name = match.value;
    this._headquartersService.listHeadquarters$(this.tenantId, { name: name }).subscribe({
      next: (response) => {
        if (response.items.length > 0) {
          this.form.patchValue({
            headquarterId: response.items[0].id,
            headquarterName: response.items[0].name,
          });
        }
      }
    });
  }

  selectRadioMileage(typeMileage: string) {
    if (typeMileage === 'before-100k') {
      this.form.patchValue({
        startMileage: 0,
        endMileage: 100000
      });
    }

    if (typeMileage === 'after-100k') {
      this.form.patchValue({
        startMileage: 100000,
        endMileage: null
      });
    }

    if (typeMileage === 'range-km') {
      this.form.patchValue({
        startMileage: null,
        endMileage: null
      });
    }
  }

  apply() {
    let genericFilter: GenericFilter = {
      startMileage: this.form.controls['startMileage'].value,
      endMileage: this.form.controls['endMileage'].value,
      startDate: this.form.controls['startDate'].value,
      endDate: this.form.controls['endDate'].value,
      headquarter: {
        id: this.form.controls['headquarterId'].value,
        name: this.form.controls['headquarterName'].value,
      },
      vehicleType: {
        id: this.form.controls['vehicleTypeId'].value,
        name: this.form.controls['vehicleTypeName'].value,
      },
    };
    this._filterModalService.applyFilter(this.id, genericFilter)
    this.bsModalRef.hide();
  }

  reset() {
    this.form.reset();
    this.bsModalRef.hide();
  }
}
