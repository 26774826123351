import { Component, EventEmitter, Input, Output, Type } from '@angular/core';
import { CardShowData } from './card-show-data.interface';
import { DropdownOption } from '../../dropdown/dropdown-primary/dropdown.interface';

@Component({
  selector: 'card-show-data',
  templateUrl: './card-show-data.component.html',
  styleUrls: ['./card-show-data.component.scss']
})
export class CardShowDataComponent {
  @Input() cardShowData: CardShowData;
  @Input() isMobile: boolean = false;
  @Input() dynamicComponent: Type<any>;

  @Output() titleSelectedChange = new EventEmitter<DropdownOption>();

  onTitleSelected(titleSelected: DropdownOption) {
    this.titleSelectedChange.emit(titleSelected);
  }
}