import { Injectable } from '@angular/core';
import { BookingModalNewStatus } from './booking-modal.component';
import { BookingModalStep1Component } from './booking-modal-step1/booking-modal-step1.component';
import { BookingModalStep2Component } from './booking-modal-step2/booking-modal-step2.component';
import { BookingModalStep3Component } from './booking-modal-step3/booking-modal-step3.component';
import { Booking, BookingType } from 'src/app/shared/models/booking/booking';
import { BookingRequest } from 'src/app/shared/models/booking/booking-request';
import { BookingService } from 'src/app/shared/api-services/booking.service';
import { Observable, switchMap, tap } from 'rxjs';
import { BookingModalFooter } from './booking-modal-footer/booking-modal-footer.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BookingFilter } from 'src/app/shared/models/booking/booking-filter';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';
import { Step } from './booking-modal-stepper/booking-modal-stepper.component';

@Injectable()
export class BookingModalService {

  bookingModalStatus: BookingModalNewStatus[];
  bookingModalFooter: BookingModalFooter[];
  bookingModalStepper: Step[];

  private _bsModalRef!: BsModalRef;

  private _booking: Booking;
  private _bookingType: BookingType;
  private _bookingRequest: BookingRequest;
  private _canDoBookingForOtherUsers: boolean = false;

  private _step1Component!: BookingModalStep1Component;
  private _step2Component!: BookingModalStep2Component;
  private _step3Component!: BookingModalStep3Component;

  private _stepStates: { [key: number]: any } = {};

  showStepMap: boolean = true;

  currentStep: number = 1;

  constructor(
    private _bookingService: BookingService,
    private _communicationService: CommunicationService
  ) { 
    this.bookingModalStatus = [
      {
        id: 1,
        title: 'CREATE_BOOKING',
        component: BookingModalStep1Component, 
      },
      {
        id: 2,
        title: 'SET_EXPECTED_MILEAGE',
        component: BookingModalStep2Component,
      },
      {
        id: 3,
        title: 'CHOOSE_CAR',
        component: BookingModalStep3Component,
      }
    ];

    this.bookingModalFooter = [
      {
        id: 1,
        primaryButton: 'ACTIONS.CANCEL',
        secondaryButton: 'ACTIONS.PROCEED',
        primaryAction: () => this.closeModal(),
        secondaryAction: () => this.nextStep(),
      },
      {
        id: 2,
        primaryButton: 'ACTIONS.BACK',
        secondaryButton: 'ACTIONS.PROCEED',
        primaryAction: () => this.backStep(),
        secondaryAction: () => this.nextStep(),
      },
      {
        id: 3,
        //primaryButton: 'ACTIONS.BACK',
        secondaryButton: 'ACTIONS.CONFIRM',
        //primaryAction: () => this.backStep(),
        secondaryAction: () => this.nextStep(),
      }
    ];

    this.bookingModalStepper = [
      {
        id: 1,
        label: 'DATA',
        status: 'unchecked',
        show: true,
      },
      {
        id: 2,
        label: 'PATH',
        status: 'unchecked',
        show: true,
      },
      {
        id: 3,
        label: 'VEHICLE',
        status: 'unchecked',
        show: true,
      }
    ];
  }

  hideStepMap() {
    if (!this._bookingType.showMap) {
      this.showStepMap = false;
      this.bookingModalStepper[1].show = false;
    } else {
      this.showStepMap = true;
      this.bookingModalStepper[1].show = true;
    }
  }

  actionBooking(): Observable<Booking> {
    const action$ = this._booking?.id 
      ? this.patchBooking$() 
      : this.createBooking$();
    return action$;
  }

  nextStep() {
    if (this.currentStep === 1 && this.showStepMap && this._step1Component.checkFormValidity()) {
      this.bookingModalStepper[0].status = 'checked';
      this.currentStep = 2;
      return;
    } else if (this.currentStep === 1 && !this.showStepMap && this._step1Component.checkFormValidity()) {
      this.actionBooking().pipe(
        tap(() => {
          this.bookingModalStepper[0].status = 'checked';
          this.currentStep = 3;
        })
      ).subscribe();
      return;
    }

    if (this.currentStep === 2 && this._step2Component.checkFormValidity()) {
      this.actionBooking().pipe(
        tap(() => {
          this.bookingModalStepper[1].status = 'checked';
          this.currentStep = 3;
        })
      ).subscribe();
      return;
    }

    if (this.currentStep === 3 && this._bookingType?.enableAlternatives) {
      this._bsModalRef.hide();
      return;
    }

    if (this.currentStep === 3 && this._step3Component.checkIfAnyVehicleChecked()) {
      this.actionBooking().pipe(
        tap(() => {
          this.bookingModalStepper[2].status = 'checked';
          this._bsModalRef.hide();
        })
      ).subscribe();
      return;
    } else if (this.currentStep === 3 && !this._step3Component.checkIfAnyVehicleChecked()) {
      this.noVehicleAvailable();
      this._bsModalRef.hide();
      return;
    }
  }

  backStep() {
    if (this.currentStep === 3 && this.showStepMap) {
      this.currentStep = 2;
      return;
    } else if (this.currentStep === 3 && !this.showStepMap) {
      this.currentStep = 1;
      return;
    }

    if (this.currentStep === 2) {
      this.currentStep = 1;
      return;
    }
  }

  closeModal() {
    this.clearAll();
    this._bsModalRef.hide();
  }

  setBsModalRef(bsModalRef: BsModalRef) {
    this._bsModalRef = bsModalRef;
  }
  get booking() {
    return this._booking;
  }

  setBooking(booking: Booking) {
    this._booking = JSON.parse(JSON.stringify(booking));
  }

  get canDoBookingForOtherUsers() {
    return this._canDoBookingForOtherUsers;
  }

  setCanDoBookingForOtherUsers(canDoBookingForOtherUsers: boolean) {
    this._canDoBookingForOtherUsers = canDoBookingForOtherUsers;
  }

  get bookingType() {
    return this._bookingType;
  }

  setBookingType(bookingType: BookingType) {
    this._bookingType = bookingType;
  }

  get bookingRequest() {
    return this._bookingRequest;
  }

  setBookingRequest(bookingRequest: BookingRequest) {
    this._bookingRequest = JSON.parse(JSON.stringify(bookingRequest));
  }

  setBookingRequestStep2(bookingRequest: BookingRequest) {
    this._bookingRequest.expectedMileage = bookingRequest.expectedMileage;
    this._bookingRequest.expectedTime = bookingRequest.expectedTime;
    this._bookingRequest.stages = bookingRequest.stages;
  }

  saveInformationStep(step: number, state: any) {
    this._stepStates[step] = state;
  }

  getInformationStep(step: number) {
    return this._stepStates[step];
  }

  setStep1Component(step1Component: BookingModalStep1Component) {
    this._step1Component = step1Component;
  }

  setStep2Component(step2Component: BookingModalStep2Component) {
    this._step2Component = step2Component;
  }

  setStep3Component(step3Component: BookingModalStep3Component) {
    this._step3Component = step3Component;
  }

  noVehicleAvailable() {
    this.bookingModalStepper[2].status = 'warning';
  }

  createBooking$(): Observable<Booking> {
    this._step1Component.updateBookingRequest();
    if (this._step2Component) {
      this._step2Component.updateBookingRequestStep2();
    }
    return this._bookingService.createBooking$(this._bookingRequest)
      .pipe(
        switchMap((booking: Booking) => {
          this._booking = booking;
          let filter: BookingFilter = {
            includeUser: true,
            includeBookingType: true,
          }
          return this._bookingService.getBooking$(booking.id, filter);
        }),
        tap((booking: Booking) => {
          this._booking = booking;
          this._communicationService.sendEvent();
        })
      );
  }

  patchBooking$(): Observable<Booking> {
    if (this._step1Component) {
      this._step1Component.updateBookingRequest();
    }

    if (this._step2Component && this.showStepMap) {
      this._step2Component.updateBookingRequestStep2();
    }

    if (this._step3Component && !this._bookingType?.enableAlternatives) {
      this._bookingRequest.vehicleId = this._step3Component.getVehicleChecked()?.id;
    }

    return this._bookingService.patchBooking$(this._booking.id, this._bookingRequest)
      .pipe(
        switchMap((booking: Booking) => {
          this._booking = booking;
          let filter: BookingFilter = {
            includeUser: true,
            includeBookingType: true,
          }
          return this._bookingService.getBooking$(booking.id, filter);
        }),
        tap((booking: Booking) => {
          this._booking = booking;
          this._communicationService.sendEvent();
        })
      );
  }

  deleteBooking() {
    this._bookingService.deleteBooking$(this._booking.id).pipe(
      tap(() => {
        this._communicationService.sendEvent();
        this.closeModal();
      })
    ).subscribe();
  }

  onInitModal() {
    this.clearAll();
  }

  clearAll() {
    this.currentStep = 1;
    this._booking = null;
    this._bookingType = null;
    this._bookingRequest = null;
    this._stepStates = {};
    this.bookingModalStepper.forEach(step => step.status = 'unchecked');
  }
}
