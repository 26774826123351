import * as Sentry from "@sentry/angular-ivy";
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Task } from 'src/app/shared/models/task/task';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { ExtraFieldsUtilsService } from "src/app/shared/utilities/extra-fields-utils.servic";
import { TypeExtraField } from "src/app/shared/models/common";
import { CreateTaskRequest } from "src/app/shared/models/task/task-request";

@Component({
  selector: 'task-information-edit',
  templateUrl: './task-information-edit.component.html',
  styleUrls: ['./task-information-edit.component.scss']
})
export class TaskInformationEditComponent {
  isEditMode: boolean = false;

  @Input() tenantId: string;
  @Input() task: Task;
  typeExtraFields: TypeExtraField[];
  @Input() taskRequest: CreateTaskRequest;

  form: FormGroup;
  fields: FormlyFieldConfig[];

  objectKeys = Object.keys;

  constructor(
    private _tasksService: TasksService,
    private _extraFieldsUtilsService: ExtraFieldsUtilsService,
  ) {
    this.form = new FormGroup({});
  }

  ngOnInit() {
    this.getExtraFields();
  }

  getExtraFields() {
    this._tasksService.listTaskFields$(this.tenantId, { taskTypeId: this.task.taskTypeId })
      .subscribe({
        next: (response) => {
          this.typeExtraFields = response.items.map(x => x as TypeExtraField);

          this.task.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.task.extraFields);
          this.task.extraFields = this._extraFieldsUtilsService.parseBoolName(this.typeExtraFields, this.task.extraFields);

          this.taskRequest.extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, this.taskRequest.extraFields, false);

          this.setValueForm();
        },
        error: (error) => {
          Sentry.captureEvent(error);
        }
      });
  }

  editMode() {
    this.isEditMode = true;
  }

  cancel() {
    this.isEditMode = false;
  }

  setValueForm() {
    this.fields = this.typeExtraFields.map(field =>
      this._extraFieldsUtilsService.createFieldConfig(field, this.taskRequest.extraFields));
  }

  onSubmit() {

    if (this.typeExtraFields.length > 0) {
      this.taskRequest = this._extraFieldsUtilsService.updateRequestFromForm(this.typeExtraFields, this.form.value, this.taskRequest);
      this.taskRequest.extraFields = this._extraFieldsUtilsService.parseBool(this.typeExtraFields, this.taskRequest.extraFields);
    }

    if (!this.taskRequest.extraFields) {
      this.taskRequest.extraFields = {};
    }

    this.setValueForm();

    let extraFields = JSON.parse(JSON.stringify(this.taskRequest.extraFields));

    extraFields = this._extraFieldsUtilsService.parseDate(this.typeExtraFields, extraFields);
    extraFields = this._extraFieldsUtilsService.parseBoolName(this.typeExtraFields, extraFields);
    this.task.extraFields = extraFields;

    this.isEditMode = false;
  }
}