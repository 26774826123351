<div class="container-table">
    <div class="card border-0 p-3">

        <import-file-filter 
            [titleTable]="'MANAGEMENT_FILE_IMPORTS' | translate" 
            (filterTableHeaderChanged)="getImports($event)"
            (onFilterModalHeaderChanged)="onFilterModalHeaderChanged($event)">
        </import-file-filter>

        <dynamic-table
            [isLoading]="isLoading"
            [columns]="tableColumns" 
            [rows]="tableRows"
            [totalItems]="filtersPage.totalRows" 
            [itemsPerPage]="filtersPage.itemsPerPage" 
            [maxPagesToShowPagination]="filtersPage.maxPagesToShowPagination" 
            (pageChanged)="onPageChange($event)"
            [listRowToShowPerPage]="filtersPage.listRowToShowPerPage" 
            (pageSizeChanged)="onPageSizeChange($event)"
            (sortColumn)="onSortColumnHeader($event)">
        </dynamic-table>

    </div>
</div>