import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Tag } from 'src/app/shared/models/tag/tag';
import { TagRequest } from 'src/app/shared/models/tag/tag-request';
import { TagsService } from 'src/app/shared/api-services/tags.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { CommunicationService } from 'src/app/shared/utilities/comunication.service';

@Component({
  selector: 'tag-modal',
  templateUrl: './tag-modal.component.html'
})
export class TagModalComponent {
  tenantId: string = this._tenantService.getTenantId();
  tag: Tag;

  form: FormGroup;

  modalStatus = {
    isCreate: true,
    isPatch: false,
  }

  constructor(
    private _tenantService: TenantService,
    private _tagsService: TagsService,
    private _communicationService: CommunicationService,
    public bsModalRef: BsModalRef,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit() {
    if (this.tag) {
      this.getHeadquarter();
    }
  }

  getHeadquarter() {
    this._tagsService.getTag$(this.tenantId, this.tag.id).subscribe(response => {
      this.tag = response;
      this.modalStatus.isPatch = true;
      this.modalStatus.isCreate = false;
      this.form.patchValue({
        name: response.name,
      });
    });
  }

  createTag() {
    let tagRequest: TagRequest = {
      name: this.form.value.name,
    }
    this._tagsService.createTag$(this.tenantId, tagRequest).subscribe(response => {
      if (response.id) {
        this.bsModalRef.hide();
        this.form.reset();
        this._communicationService.sendEvent();
      }
    });
  }

  updateTag() {
    let tagRequest: TagRequest = {
      name: this.form.value.name
    }
    this._tagsService.updateTag$(this.tenantId, this.tag.id, tagRequest).subscribe(response => {
      if (response.id) {
        this.bsModalRef.hide();
        this.form.reset();
        this._communicationService.sendEvent();
      }
    });
  }

  deleteTag() {
    this._tagsService.deleteTag$(this.tenantId, this.tag.id).subscribe(response => {
      if (response.status === 204) {
        this._communicationService.sendEvent();
        this.bsModalRef.hide();
      }
    });
  }
}
