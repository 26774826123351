import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentResponse, StorageFile } from 'src/app/shared/models/storage';
import { Task } from 'src/app/shared/models/task/task';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { TaskFileRequest } from 'src/app/shared/models/task/task-request';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'task-section-files',
  template: `
    <div class="container-downloads d-flex flex-column gap-4">
        <input-upload-files (fileInfo)="linkFileToTask($event)" [tenantId]="tenantId" [bucketName]="bucketName"></input-upload-files>                        
        <output-files [documents]="documents" (downloadDocument)="downloadTaskFile($event)" (deleteDocument)="removeTaskFile($event)"></output-files>
    </div>`,
})
export class TaskSectionFilesComponent implements OnInit, OnDestroy {

  @Input() task: Task;
  tenantId = this._tenantService.getTenantId();

  bucketName: string = 'tasks-documents';
  documents: DocumentResponse[];

  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.getLinkFilesTask();
  }

  linkFileToTask($event: StorageFile) {
    let file = $event;

    let request: TaskFileRequest = {
      fileId: file.id,
      name: file.name,
    };

    this._toastrService.info(this._translateService.instant('MESSAGES.LOADING_FILE_IN_PROGRESS'));
    this._taskService.linkTaskFile$(this.tenantId, this.task.id, request).pipe(takeUntil(this._destroy$)).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.getLinkFilesTask();
        }
      }
    });
  }

  downloadTaskFile($event: StorageFile) {
    let file = $event;
    this._taskService.downloadTaskFile$(this.tenantId, this.task.id, file.id).pipe(takeUntil(this._destroy$)).subscribe({
      next: (response) => {
        const url = window.URL.createObjectURL(response.body);

        const a = document.createElement('a');
        a.href = url;
        a.download = file.name;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    });

  }

  removeTaskFile($event: StorageFile) {
    let file = $event;
    this._taskService.deleteTaskFile$(this.tenantId, this.task.id, file.id).pipe(takeUntil(this._destroy$)).subscribe({
      next: (response) => {
        if (response.status === 204 || response.status === 200) {
          this.getLinkFilesTask();
        }
      }
    });
  }

  getLinkFilesTask() {
    this._taskService.listTaskFiles$(this.tenantId, this.task.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response) => {
          this.documents = response.items;
        }
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }
}
