<div class="container-table">
    <div class="card border-0 p-3">

        <persons-table-filters
            [titleTable]="titleTable"
            [hasManageUserPermission]="hasManageUserPermission"
            (filterTableHeaderChanged)="getDataForm($event)" 
            (exportEvent)="getExport()"
            (onMassiveAction)="onMassiveAction($event)">
        </persons-table-filters>

        <dynamic-table
            [hasCheckbox]="hasManageUserPermission"
            [columns]="tableColumns" [rows]="tableRows" (filterTasks)="getTable()"
            [totalItems]="filtersPage.totalRows" [itemsPerPage]="filtersPage.itemsPerPage" 
            [maxPagesToShowPagination]="filtersPage.maxPagesToShowPagination" (pageChanged)="onPageChange($event)"
            [listRowToShowPerPage]="filtersPage.listRowToShowPerPage" (pageSizeChanged)="onPageSizeChange($event)"
            (sortColumn)="onSortColumnHeader($event)"
            (emitTableRows)="onMassiveSelections($event)">
        </dynamic-table>

    </div>
</div>