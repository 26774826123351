import * as Sentry from "@sentry/angular-ivy";
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Task, TaskCost } from 'src/app/shared/models/task/task';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { DateUtilsService } from 'src/app/shared/utilities/date-utils.service';
import { TasksFilter } from "src/app/shared/models/task/task-filter";
import { UpdateTaskCostRequest } from "src/app/shared/models/task/task-request";

@Component({
  selector: 'card-add-costs',
  templateUrl: './card-add-costs.component.html',
  styleUrls: ['./card-add-costs.component.scss']
})
export class CardAddCostsComponent {
  @Input() tenantId: string;
  @Input() task: Task;

  @Input() isMobile: boolean = false;

  form: FormGroup;

  isEditMode: boolean = false;

  constructor(
    private _dateUtilsService: DateUtilsService,
    private _taskService: TasksService,
  ) {
    this.form = new FormGroup({
      netCost: new FormControl(),
      grossCost: new FormControl(),
      accountingDate: new FormControl(),
    });
  }

  ngOnInit() {
    this.getTaskCost();
  }

  editMode() {
    this.isEditMode = true;
  }

  cancel() {
    this.isEditMode = false;
  }

  getTaskCost() {
    let params: TasksFilter = {
      includeTaskType: true,
      includeHeadquarter: true,
      includeUser: true,
      includeVehicle: true,
      includeAsset: true,
      includeAssignedUser: true,
      includeStartUser: true,
      includeTaskCost: true,
    };

    this._taskService.getTask$(this.tenantId, this.task.id, params).subscribe({
      next: (task: Task) => {
        this.task = JSON.parse(JSON.stringify(task));

        if (this.task.taskCost) {
          this.form.get('netCost')!.setValue(this.task.taskCost.netCost);
          this.form.get('grossCost')!.setValue(this.task.taskCost.grossCost);

          if (this.task.taskCost.accountingDate) {
            let accountingDate = new Date(this.task.taskCost.accountingDate);
            this.form.get('accountingDate')!.setValue(accountingDate);
          }
        }
      },
      error: (error) => {
        Sentry.captureEvent(error);
      }
    });
  }

  onSubmit() {
    this.isEditMode = false;
    if (this.form.invalid) {
      return;
    }

    if (this.task.taskCost == null) {
      this.task.taskCost = {};
    }

    this.task.taskCost.netCost = this.form.get('netCost')!.value ?? null;
    this.task.taskCost.grossCost = this.form.get('grossCost')!.value ?? null;
    this.task.taskCost.accountingDate = this.form.get('accountingDate')!.value ?? null;

    let taskCostRequest: UpdateTaskCostRequest = {
      netCost: this.task.taskCost.netCost,
      grossCost: this.task.taskCost.grossCost,
      accountingDate: this._dateUtilsService.parseDataToString(this.task.taskCost.accountingDate),
    };

    this._taskService.updateTaskCost$(this.tenantId, this.task.id, taskCostRequest).subscribe({
      next: (taskCost: TaskCost) => {
        if (taskCost) {
          this.getTaskCost();
        }
      },
      error: (error) => {
        Sentry.captureEvent(error);
      }
    });

  }
}