import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { MappingModel } from '../models/user/users';
import { CommonService } from './common.service';
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from 'ngx-toastr';
import { UserGroupResponse } from '../models/user-group/user-group-response';
import { UserGroupFilter } from '../models/user-group/user-group-filter';
import { PermissionFilter } from '../models/permission/permission-filter';
import { PermissionResponse } from '../models/permission/permission-response';
import { Permission, PermissionNameModel } from '../models/permission/permission';
import { PermissionUpdateRequest } from '../models/permission/permission-request';
import { PermissionCreateRequest } from '../models/permission/permission-request';
import { UserGroupRequest } from '../models/user-group/user-group-request';
import { UserGroup } from '../models/user-group/user-group';

@Injectable({
  providedIn: 'root'
})

export class UserGroupsService {
  private baseUrl = VECTORE_API_BASE_URL;

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
  ) { }

  listUserGroups$(tenantId: string, filter: UserGroupFilter): Observable<UserGroupResponse> {
    return this._http.get<UserGroupResponse>(`${this.baseUrl}/${tenantId}/user-groups`, { params: this._commonService.buildParams(filter) });
  }

  getUserGroup$(tenantId: string, userGroupId: string): Observable<UserGroup> {
    return this._http.get<UserGroup>(`${this.baseUrl}/${tenantId}/user-groups/${userGroupId}`);
  }

  createUserGroup$(tenantId: string, userGroup: UserGroupRequest): Observable<UserGroup> {
    return this._http.post<UserGroup>(`${this.baseUrl}/${tenantId}/user-groups`, userGroup)
      .pipe(
        tap({
          next: () => this._toastrService.success(this._translateService.instant('USER_GROUPS.MESSAGES.SUCCESS_CREATED')),
          error: error => {
            if (error.status === 400) {
              this._toastrService.error(this._translateService.instant(`USER_GROUPS.MESSAGES.${error.error.title}`));
            } else if (error.status === 500) {
              this._toastrService.error(this._translateService.instant(`MESSAGES.${error.error.errorCode}`));
            } else {
              this._commonService.handleError(error);
            }
          }
        })
      );
  }

  updateUserGroup$(tenantId: string, userGroupId: string, userGroup: UserGroupRequest): Observable<UserGroup> {
    return this._http.patch<UserGroup>(`${this.baseUrl}/${tenantId}/user-groups/${userGroupId}`, userGroup)
      .pipe(
        tap({
          next: () => this._toastrService.success(this._translateService.instant('USER_GROUPS.MESSAGES.SUCCESS_UPDATED')),
          error: error => {
            if (error.status === 400) {
              this._toastrService.error(this._translateService.instant(`USER_GROUPS.MESSAGES.${error.error.title}`));
            } else if (error.status === 500) {
              this._toastrService.error(this._translateService.instant(`MESSAGES.${error.error.errorCode}`));
            } else {
              this._commonService.handleError(error);
            }
          }
        })
      );
  }

  deleteUserGroup$(tenantId: string, userGroupId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${this.baseUrl}/${tenantId}/user-groups/${userGroupId}`, { observe: 'response' })
      .pipe(
        tap({
          next: () => this._toastrService.success(this._translateService.instant('USER_GROUPS.MESSAGES.SUCCESS_DELETED')),
          error: error => {
            if (error.status === 400) {
              this._toastrService.error(this._translateService.instant(`USER_GROUPS.MESSAGES.${error.error.title}`));
            } else {
              this._commonService.handleError(error);
            }
          }
        })
      );
  }

  listPermissionNames$(tenantId: string): Observable<PermissionNameModel[]> {
    return this._http.get<PermissionNameModel[]>(`${this.baseUrl}/${tenantId}/permissions/names`);
  }

  listPermissions$(tenantId: string, filter: PermissionFilter): Observable<PermissionResponse> {
    return this._http.get<PermissionResponse>(`${this.baseUrl}/${tenantId}/permissions`, { params: this._commonService.buildParams(filter) });
  }

  getPermission$(tenantId: string, permissionId: string): Observable<Permission> {
    return this._http.get<Permission>(`${this.baseUrl}/${tenantId}/permissions/${permissionId}`);
  }

  createPermission$(tenantId: string, permission: PermissionCreateRequest): Observable<Permission> {
    return this._http.post<Permission>(`${this.baseUrl}/${tenantId}/permissions`, permission)
      .pipe(
        tap({
          next: () => this._toastrService.success(this._translateService.instant('USER_GROUPS.PERMISSIONS.MESSAGES.SUCCESS_CREATED')),
          error: error => {
            if (error.status === 400) {
              this._toastrService.error(this._translateService.instant(`USER_GROUPS.PERMISSIONS.MESSAGES.${error.error.title}`));
            } else if (error.status === 500) {
              this._toastrService.error(this._translateService.instant(`USER_GROUPS.PERMISSIONS.MESSAGES.${error.error.errorCode}`));
            } else {
              this._commonService.handleError(error);
            }
          }
        })
      );
  }

  updatePermission$(tenantId: string, permissionId: string, permission: PermissionUpdateRequest): Observable<Permission> {
    return this._http.patch<Permission>(`${this.baseUrl}/${tenantId}/permissions/${permissionId}`, permission)
      .pipe(
        tap({
          next: () => this._toastrService.success(this._translateService.instant('USER_GROUPS.PERMISSIONS.MESSAGES.SUCCESS_UPDATED')),
          error: error => {
            if (error.status === 400) {
              this._toastrService.error(this._translateService.instant(`USER_GROUPS.PERMISSIONS.MESSAGES.${error.error.title}`));
            } else if (error.status === 500) {
              this._toastrService.error(this._translateService.instant(`USER_GROUPS.PERMISSIONS.MESSAGES.${error.error.errorCode}`));
            } else {
              this._commonService.handleError(error);
            }
          }
        })
      );
  }

  deletePermission$(tenantId: string, permissionId: string): Observable<HttpResponse<Object>> {
    return this._http.delete(`${this.baseUrl}/${tenantId}/permissions/${permissionId}`, { observe: 'response' })
      .pipe(
        tap({
          next: () => this._toastrService.success(this._translateService.instant('USER_GROUPS.PERMISSIONS.MESSAGES.SUCCESS_DELETED')),
          error: error => this._commonService.handleError(error)
        })
      );
  }

  exportPermissions$(tenantId: string, exportData: MappingModel, params?: PermissionFilter): Observable<HttpResponse<Blob>> {
    return this._http.post(`${this.baseUrl}/${tenantId}/export/permissions`, exportData,
      { observe: 'response', responseType: 'blob', params: this._commonService.buildParams(params) })
      .pipe(
        tap({
          error: error => this._commonService.handleError(error)
        })
      );
  }

}
