import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { DropdownOption } from 'src/app/shared/components/dropdown/dropdown-primary/dropdown.interface';
import { ModalUtilsService } from 'src/app/shared/utilities/modal-utils.service';

@Component({
  selector: 'booking-table-filters',
  templateUrl: './booking-table-filters.component.html',
  styleUrls: ['./booking-table-filters.component.scss']
})
export class BookingTableFiltersComponent {

  @Input() titleTable: string;
  @Input() hasManageBookingsPermission: boolean = false;
  @Input() isFleetManager: boolean = false;

  listTableFilterHeaders!: DropdownOption[];
  filterHeader: DropdownOption;

  listMassiveActions: DropdownOption[];

  filterForm: FormGroup = new FormGroup({
    headerToFilter: new FormControl(),
    inputFilter: new FormControl(),
    startDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/)),
    endDate: new FormControl('', Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[012])-(\d{4})$/))
  });

  @Output() filterTableHeaderChanged = new EventEmitter<FormGroup>();
  @Output() exportEvent = new EventEmitter<any>();
  @Output() onMassiveAction = new EventEmitter<string>();

  private _destroy = new Subject<void>();

  constructor(
    private _translateService: TranslateService,
    private _modalUtilsService: ModalUtilsService
  ) { }

  ngOnInit(): void {
    this.listMassiveActions = this._translateService.instant('MASSIVE_ACTIONS');

    this.filterForm.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.filterTableHeaderChanged.emit(this.filterForm);
      });
  }

  selectMassiveOption($event: DropdownOption) {
    this.onMassiveAction.emit($event.value);
  }

  selectedOption($event: DropdownOption) {
    this.filterForm.get('filterHeader').setValue($event.value);
  }

  onFilterClick() {
    this._modalUtilsService.openBookingFilterModal(this.isFleetManager)
      .pipe(takeUntil(this._destroy))
      .subscribe();
  }

  onClickExport() {
    this.exportEvent.emit();
  }
}
