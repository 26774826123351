import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { Task, TaskFields, } from 'src/app/shared/models/task/task';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UsersService } from 'src/app/shared/api-services/users.service';
import { DateUtilsService } from 'src/app/shared/utilities/date-utils.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CardTaskUsageDate } from '../../../cards/card-task-usage-date/card-task-usage-date.component';
import { CommunicationService } from "src/app/shared/utilities/comunication.service";
import { DatePipe } from "@angular/common";
import { SafeUrl } from "@angular/platform-browser";
import { CreateTaskRequest } from 'src/app/shared/models/task/task-request';

@Component({
  selector: 'task-edit-modal',
  templateUrl: './task-edit-modal.component.html',
  styleUrls: ['./task-edit-modal.component.scss']
})
export class TaskEditModalComponent {
  tenantId: string;
  userId: string;
  dropdownId: string;

  @Input() task: Task;
  @Input() taskRequest: CreateTaskRequest;

  statusTabs: {
    info: boolean,
    comments: boolean,
    files: boolean,
    linkInvoices: boolean,
  };

  taskEditForm: FormGroup;
  fields: FormlyFieldConfig[];

  srcUserCreateImage: Observable<SafeUrl>;

  assetNames$: Observable<string[]>;
  vehicleLicensePlate$: Observable<string[]>;

  modalRef?: BsModalRef;

  isMobile: boolean = false;
  isEditMode: boolean = false;

  nameExtraFields: TaskFields[];

  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _communicationService: CommunicationService,
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _usersService: UsersService,
    private _dateUtilsService: DateUtilsService,
    private _datePipe: DatePipe,
    public taskEditModal: BsModalRef
  ) {
    this.tenantId = this._tenantService.getTenantId();

    this.taskEditForm = new FormGroup({
      taskTitle: new FormControl(),
      expirationDate: new FormControl(),
      expirationMileage: new FormControl(),
    });

    this._breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        this.isMobile = result.matches;
      });

  }

  ngOnInit() {
    if (!this.statusTabs) {
      this.statusTabs = {
        info: true,
        comments: false,
        files: false,
        linkInvoices: false,
      };
    }
    this.srcUserCreateImage = this._usersService.getUserImage$(this.tenantId, this.task.startUserId, 20, 20, true);

    this.setValuesFields();
    this.syncRequest();
  }

  syncRequest() {
    this._communicationService.getSpecificEventWithData(this.task.id).subscribe({
      next: (response: Task) => {
        if (response?.taskWatcherIds) {
          this.taskRequest.taskWatcherIds = response.taskWatcherIds;
        }
      }
    })
  }

  setValuesFields() {
    this.taskRequest = this._taskService.mapTaskToTaskRequest(this.task);

    this.taskEditForm.patchValue({
      taskTitle: this.task.title,
      assignedUserName: this.task.assignedUser?.displayName,
      expirationMileage: this.task.expirationMileage,
      assetName: this.task.asset?.displayName,
      userName: this.task.user?.displayName,
      vehicleLicensePlate: this.task.vehicle?.licensePlate,
    });

    if (this.task.expirationDate) {
      let expirationDate = this._dateUtilsService.parseDateStringToDate(this.task.expirationDate);

      let expirationDateMobile;
      if (this.isMobile) {
        expirationDateMobile = this._datePipe.transform(expirationDate, 'yyyy-MM-dd');
      }

      if (this.isMobile) {
        this.taskEditForm.patchValue({
          expirationDate: expirationDateMobile,
        });
      }
      else {
        this.taskEditForm.patchValue({
          expirationDate: expirationDate,
        });
      }
    }
  }

  submitTaskChanges() {
    if (this.taskEditForm.value.taskTitle) {
      this.taskRequest.title = this.taskEditForm.value.taskTitle;
    }
    else {
      this.taskRequest.title = this.task.title;
    }

    if (this.taskEditForm.value.expirationMileage) {
      this.taskRequest.expirationMileage = this.taskEditForm.value.expirationMileage;
    }

    if (this.taskEditForm.value.expirationDate) {
      this.taskRequest.expirationDate = this._dateUtilsService.parseDataToString(this.taskEditForm.value.expirationDate);
    }

    this._taskService.patchTask$(this.tenantId, this.task.id, this.taskRequest).subscribe({
      next: (response) => {
        if (response) {
          this._communicationService.sendEvent();
          this.taskEditForm.reset();
          this.taskEditModal.hide();
        }
      }
    });

  }

  addDateUsage($event: CardTaskUsageDate) {
    this.taskRequest.usageStartDate = this._dateUtilsService.parseDataToString($event.startDateUsage);
    this.taskRequest.usageEndDate = this._dateUtilsService.parseDataToString($event.endDateUsage);
  }

  addDescription($description: string) {
    this.taskRequest.description = $description;
  }

  onAssignedUserChange(assignedUserId: string) {
    this.taskRequest.assignedUserId = assignedUserId;
    this.task.assignedUserId = assignedUserId;
    this.task = JSON.parse(JSON.stringify(this.task)); // only for trigger change detection
  }

  onUserChange(userId: string) {
    this.taskRequest.userId = userId;
  }

  onAssetChange(assetId: string) {
    this.taskRequest.assetId = assetId;
  }

  onVehicleChange(vehicleId: string) {
    this.taskRequest.vehicleId = vehicleId;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}