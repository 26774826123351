import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CellClient, ImportFile, ImportFileClient, ImportRowClient, RowClient } from '../models/import/import';
import { DatePipe } from '@angular/common';
import { ImportStatus } from '../constant/status-import';

@Injectable({
  providedIn: 'root'
})
export class ImportUtilsService {
  constructor(
    private _datePipe: DatePipe,
    private _translate: TranslateService
  ) { }

  setActionsImport(importFile: ImportFileClient): ImportFileClient {
    if (importFile.status === ImportStatus.CREATED || importFile.status === ImportStatus.UPDATED || importFile.status === ImportStatus.ERROR || importFile.status === ImportStatus.INTERRUPTED) {
      importFile.actions = [
        //{ text: this._translate.instant("ACTIONS.EDIT"), value: 'edit' },
        { text: this._translate.instant("ACTIONS.DETAIL"), value: 'detail' },
      ];
    }

    if (importFile.status === ImportStatus.UPDATING) {
      importFile.actions = [
        { text: this._translate.instant("ACTIONS.INTERRUPT"), value: 'interrupt' },
      ];
    }
    return importFile;
  }

  setDateImport(importFile: ImportFile): ImportFile {

    importFile.creationDate = this._datePipe.transform(importFile.creationDate, 'dd MMM yyyy • HH:mm');

    return importFile;
  }

  setStatusBadgeImport(importFile: ImportFileClient): ImportFileClient {
    importFile.statusClient = { text: '', tooltip: '', cssClass: 'custom-badge-bold' };

    if (importFile.status === ImportStatus.CREATED) {
      importFile.statusClient.text = this._translate.instant('CREATED');
      importFile.statusClient.tooltip = importFile.status;
      importFile.statusClient.cssClass = ' bg-info';
    }

    if (importFile.status === ImportStatus.UPDATING) {
      importFile.statusClient.text = this._translate.instant('UPDATING');
      importFile.statusClient.tooltip = importFile.status;
      importFile.statusClient.cssClass = ' bg-warning';
    }

    if (importFile.status === ImportStatus.UPDATED) {
      importFile.statusClient.text = this._translate.instant('UPDATED');
      importFile.statusClient.tooltip = importFile.status;
      importFile.statusClient.cssClass = ' bg-success';
    }

    if (importFile.status === ImportStatus.ERROR) {
      importFile.statusClient.text = this._translate.instant('ERROR');
      importFile.statusClient.tooltip = importFile.status;
      importFile.statusClient.cssClass = ' bg-danger';
    }

    if (importFile.status === ImportStatus.INTERRUPTED) {
      importFile.statusClient.text = this._translate.instant('INTERRUPTED');
      importFile.statusClient.tooltip = importFile.status;
      importFile.statusClient.cssClass = ' bg-black';
    }

    importFile.statusClient.cssClass += ' custom-badge-bold';
    return importFile;
  }

  convertRowToRowClient(importRow: ImportRowClient): RowClient {
    const rowClient: RowClient = {};

    for (const key in importRow.row) {
      if (importRow.row.hasOwnProperty(key)) {
        let cssClass = '';
        let tooltip = '';

        const message = importRow.messages.find(msg => msg.columnName === key && msg.error);
        if (message) {
          cssClass = 'color-danger';
          tooltip = message.code;
        }

        const cellClient: CellClient = {
          header: key,
          text: importRow.row[key],
          tooltip: tooltip,
          cssClass: cssClass,
        };
        rowClient[key] = cellClient; // Directly assign the CellClient object to the RowClient object
      }
    }

    this.setStatusBadgeImportRow(importRow);

    rowClient.status = {
      header: 'status',
      text: importRow.statusClient.text,
      tooltip: importRow.status,
      cssClass: importRow.statusClient.cssClass,
    };

    return rowClient;
  }

  setStatusBadgeImportRow(importRow: ImportRowClient): ImportRowClient {
    importRow.statusClient = { text: '', tooltip: '', cssClass: 'custom-badge-bold' };

    if (importRow.status === 'VALID') {
      importRow.statusClient.text = this._translate.instant('VALID');
      importRow.statusClient.tooltip = importRow.status;
      importRow.statusClient.cssClass = ' bg-success';
    }

    if (importRow.status === 'IMPORTED') {
      importRow.statusClient.text = this._translate.instant('IMPORTED');
      importRow.statusClient.tooltip = importRow.status;
      importRow.statusClient.cssClass = ' bg-info';
    }

    if (importRow.status === 'ERROR') {
      importRow.statusClient.text = this._translate.instant('ERROR');
      importRow.statusClient.tooltip = importRow.status;
      importRow.statusClient.cssClass = ' bg-danger';
    }

    importRow.statusClient.cssClass += ' custom-badge-bold';
    return importRow;
  }
}

