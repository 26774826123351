import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CardShowData } from '../card-show-data.interface';
import { FilterDateService } from 'src/app/shared/utilities/filter-date.service';
import { TasksService } from 'src/app/shared/api-services/tasks.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { DropdownOption } from "../../../dropdown/dropdown-primary/dropdown.interface";
import { TranslateService } from "@ngx-translate/core";
import { DropdownEventsService } from "src/app/shared/utilities/dropdown-events.service";
import { UserModel } from "src/app/shared/models/user/users";
import { Asset } from "src/app/shared/models/assets/asset";
import { Vehicle } from "src/app/shared/models/vehicle/vehicle";
import { Subject, takeUntil } from "rxjs";
import { TaskDataFilter } from "src/app/shared/models/task/task-filter";

@Component({
  selector: 'card-show-data-task-todo',
  templateUrl: './card-show-data-task-todo.component.html',
  styleUrls: ['./card-show-data-task-todo.component.scss']
})
export class CardShowDataTaskTodoComponent implements OnInit, OnDestroy {
  tenantId: string = this._tenantService.getTenantId();

  @Input() isMobile: boolean = false;
  @Input() user: UserModel;
  @Input() vehicle: Vehicle;
  @Input() asset: Asset;
  @Input() isCost: boolean = false;
  @Input() showDropdownCost: boolean = false;

  listFilterTime: DropdownOption[];
  timeFiltersSelected!: DropdownOption[];

  listTypeTask: DropdownOption[];
  typeTaskSelected!: DropdownOption;

  cardTaskToDo: CardShowData;

  @Input() showTabs: boolean = false;

  private _timeOption: DropdownOption;

  private _destroy = new Subject<void>();

  constructor(
    private _filterDateService: FilterDateService,
    private _tenantService: TenantService,
    private _taskService: TasksService,
    private _translateService: TranslateService,
    private _dropdownEventsService: DropdownEventsService,
  ) { }

  ngOnInit(): void {
    this.listFilterTime = this._translateService.instant('TIME_FILTERS');
    if (this.showDropdownCost) {
      this.listTypeTask = this._translateService.instant('TASKS.CARD_TASK_TYPE');
      this.typeTaskSelected = JSON.parse(JSON.stringify(this.listTypeTask[0]));
    }

    this.setCardInfo();

    this._dropdownEventsService.setOptions(this.cardTaskToDo.id, this.listFilterTime);
    this._dropdownEventsService.setSelectedOption(this.cardTaskToDo.id, this.listFilterTime[3]);
    this._dropdownEventsService.getSelectedOption(this.cardTaskToDo.id)
      .pipe(takeUntil(this._destroy))
      .subscribe((timeOption) => {
        this._timeOption = timeOption;

        if (this.user) {
          this.getNumberTaskUser(timeOption.value);
        }
        else {
          this.getNumberTaskTodoAll(timeOption.value);
        }
      });
  }

  private setCardInfo() {
    if (this.user) {
      this.cardTaskToDo = {
        id: 'card-task-user',
        iconType: 'icon-task-vectore',
        iconBg: 'bg-icon-primary-light',
        cardTitle: "TASK",
        cardInfoItems: [
          {
            title: "CREATED",
            value: 0,
          },
          {
            title: "DONED",
            value: 0,
            textColorValue: 'text-success'
          },
          {
            title: "TO_DO",
            value: 0,
            textColorValue: 'text-info'
          },
          {
            title: "LATE",
            value: 0,
            textColorValue: 'text-danger'
          }
        ]
      };
    }
    else {
      this.cardTaskToDo = {
        id: 'card-task-todo',
        iconType: 'icon-task-vectore',
        iconBg: 'bg-icon-primary-light',
        cardTitle: "TASKS.SECTION1.TASKS_TO_DO.CARD_TITLE",
        listTitleDropdown: this.listTypeTask,
        titleSelected: this.typeTaskSelected,
        cardInfoItems: [
          {
            title: "TASKS.SECTION1.TASKS_TO_DO.TOTAL",
            value: 0,
            textColorValue: 'text-info'
          },
          {
            title: "TASKS.SECTION1.TASKS_TO_DO.NOT_ASSIGNED",
            value: 0,
            textColorValue: 'text-warning'
          },
          {
            title: "TASKS.SECTION1.TASKS_TO_DO.LATE",
            value: 0,
            textColorValue: 'text-danger'
          }
        ]
      };
    }
  }

  private getNumberTaskTodoAll(dateRange: string) {
    let params: TaskDataFilter = this.getTimeRange(dateRange);
    params.hasEndDate = false;
    params.hasExpirationDate = true;
    params.hasTCO = this.isCost;

    if (this.asset) {
      params.groupByAssetId = this.asset.id;
    }

    if (this.vehicle) {
      params.groupByVehicleId = this.vehicle.id;
    }

    this._taskService.getTaskData$(this.tenantId, params)
      .pipe(takeUntil(this._destroy))
      .subscribe({
        next: (taskDataResp) => {

          if (this.isMobile) {
            this.cardTaskToDo.badge = taskDataResp.items[0] && taskDataResp.items[0].late > 0 ? " " : '';
          }
          else {
            this.cardTaskToDo.badge = taskDataResp.items[0] && taskDataResp.items[0].late > 0 ? "BADGES.ATTENTION" : '';
          }

          this.cardTaskToDo.cardInfoItems[0].value = taskDataResp.items[0] ? taskDataResp.items[0].items : 0;
          this.cardTaskToDo.cardInfoItems[1].value = taskDataResp.items[0] ? taskDataResp.items[0].notAssigned : 0;
          this.cardTaskToDo.cardInfoItems[2].value = taskDataResp.items[0] ? taskDataResp.items[0].late : 0;
        }
      });
  }

  private getNumberTaskUser(dateRange: string) {
    let params: TaskDataFilter = this.getTimeRange(dateRange);
    params.hasEndDate = false;
    params.hasTCO = this.isCost;

    if (this.user) {
      params.groupByStartUserId = this.user.id;
      params.groupByAssignedUserId = this.user.id;
    }

    this._taskService.getTaskData$(this.tenantId, params)
      .pipe(takeUntil(this._destroy))
      .subscribe({
        next: (taskDataResp) => {

          this.cardTaskToDo.cardInfoItems[0].value = taskDataResp.items[0] ? taskDataResp.items[0].items : 0;
          this.cardTaskToDo.cardInfoItems[1].value = taskDataResp.items[0] ? taskDataResp.items[0].items - taskDataResp.items[0].late : 0;
          this.cardTaskToDo.cardInfoItems[2].value = taskDataResp.items[0] ? taskDataResp.items[0].toDo : 0;
          this.cardTaskToDo.cardInfoItems[3].value = taskDataResp.items[0] ? taskDataResp.items[0].late : 0;
        }
      });
  }

  private getTimeRange(dateRange: string): TaskDataFilter {
    let params: TaskDataFilter = {
      includeTaskData: true,
    };

    let startDate: string;
    let endDate: string;

    ({ startDate, endDate } = this._filterDateService.getStartEndDateByTypeRange(dateRange));

    params.rangeStartExpirationDate = startDate;
    params.rangeEndExpirationDate = endDate;
    return params;
  }

  onTypeTaskChange(typeTask: DropdownOption) {
    if (typeTask.value === 'task_todo') {
      this.isCost = false;
    }
    else {
      this.isCost = true;
    }

    if (this.user) {
      this.getNumberTaskUser(this._timeOption.value);
    }
    else {
      this.getNumberTaskTodoAll(this._timeOption.value);
    }
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }
}
