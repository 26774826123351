import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { distinctUntilChanged, debounceTime, Observable, Observer, switchMap, map } from 'rxjs';
import { HeadquartersService } from 'src/app/shared/api-services/headquarters.service';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { FilterModalService } from '../filter-modal.service';
import { HeadquarterResponse } from 'src/app/shared/models/headquarter/headquarter-response';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'filter-person-modal',
  templateUrl: './filter-person-modal.component.html',
  styleUrl: './filter-person-modal.component.scss'
})
export class FilterPersonModalComponent implements OnInit {
  id = 'filter-person-modal';
  private tenantId = this._tenantService.getTenantId();
  form: FormGroup;

  @Input() autoCompleteParams: any = {};
  headquarterName$: Observable<string[]>;

  constructor(
    private _tenantService: TenantService,
    private _headquartersService: HeadquartersService,
    private _filterTaskModalService: FilterModalService,
    public bsModalRef: BsModalRef
  ) {
    this.form = new FormGroup({
      vehicleTypeId: new FormControl(null),

      headquarterId: new FormControl(null),
      headquarterName: new FormControl(null),

      locationId: new FormControl(null),
      locationName: new FormControl({ value: null, disabled: true }),
    });
  }

  ngOnInit() {
    let form = this._filterTaskModalService.getForm(this.id);
    if (form) {
      this.form = form;
    }
    this.autoComplete();
  }

  private autoComplete() {
    this.headquarterName$ = new Observable((observer: Observer<string | undefined>) => {
      observer.next(this.form.controls['headquarterName'].value);
    }).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((token: string) => this._headquartersService.listHeadquarters$(this.tenantId,
        { nameContains: token, limit: 5, ...this.autoCompleteParams })),
      map((response: HeadquarterResponse) => {
        let displayNames: string[] = [];
        response.items.forEach((headquarter) => {
          displayNames.push(headquarter.name);
        });
        return displayNames;
      }),
    );
  }

  onSelectHeadquarter(match: TypeaheadMatch) {
    const name = match.value;
    this._headquartersService.listHeadquarters$(this.tenantId, { name: name }).subscribe({
      next: (response) => {
        if (response.items.length > 0) {
          this.form.patchValue({
            headquarterId: response.items[0].id,
            headquarterName: response.items[0].name,
          });
          this.form.controls['locationName'].enable();
        }
      }
    });
  }

  apply() {
    this._filterTaskModalService.applyFilterFromForm(this.id, this.form);
    this.bsModalRef.hide();
  }

  reset() {
    this.form.reset();
    this.form.controls['locationName'].disable();
    this._filterTaskModalService.applyFilterFromForm(this.id, this.form);
  }
}