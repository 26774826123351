export const CAN_MANAGE_TENANT = "CAN_MANAGE_TENANT";

export const CAN_READ_ASSET_TYPES = "CAN_READ_ASSET_TYPES";
export const CAN_VIEW_ASSET_PAGE = "CAN_VIEW_ASSET_PAGE";
export const CAN_READ_ASSETS = "CAN_READ_ASSETS";
export const CAN_MANAGE_ASSETS = "CAN_MANAGE_ASSETS";

export const CAN_READ_BOOKING_TYPES = "CAN_READ_BOOKING_TYPES";
export const CAN_READ_BOOKINGS = "CAN_READ_BOOKINGS";
export const CAN_ADMIN_BOOKINGS = "CAN_ADMIN_BOOKINGS";
export const CAN_MANAGE_BOOKINGS = "CAN_MANAGE_BOOKINGS";
export const CAN_APPROVE_BOOKINGS = "CAN_APPROVE_BOOKINGS";

export const CAN_READ_CABINETS = "CAN_READ_CABINETS";
export const CAN_MANAGE_CABINETS = "CAN_MANAGE_CABINETS";
export const CAN_READ_CABINET_KEYS = "CAN_READ_CABINET_KEYS";
export const CAN_MANAGE_CABINET_KEYS = "CAN_MANAGE_CABINET_KEYS";
export const CAN_READ_CABINET_USERS = "CAN_READ_CABINET_USERS";
export const CAN_MANAGE_CABINET_USERS = "CAN_MANAGE_CABINET_USERS";
export const CAN_READ_CABINET_RESERVATIONS = "CAN_READ_CABINET_RESERVATIONS";
export const CAN_MANAGE_CABINET_RESERVATIONS = "CAN_MANAGE_CABINET_RESERVATIONS";
export const CAN_READ_CABINET_EVENTS = "CAN_READ_CABINET_EVENTS";
export const CAN_MANAGE_CABINET_EVENTS = "CAN_MANAGE_CABINET_EVENTS";

export const CAN_READ_IMPORTS = "CAN_READ_IMPORTS";
export const CAN_MANAGE_IMPORTS = "CAN_MANAGE_IMPORTS";

export const CAN_READ_SUPPLIERS = "CAN_READ_SUPPLIERS";
export const CAN_MANAGE_SUPPLIERS = "CAN_MANAGE_SUPPLIERS";
export const CAN_READ_INVOICES = "CAN_READ_INVOICES";
export const CAN_MANAGE_INVOICES = "CAN_MANAGE_INVOICES";

export const CAN_READ_TAGS = "CAN_READ_TAGS";
export const CAN_MANAGE_TAGS = "CAN_MANAGE_TAGS";

export const CAN_READ_TASK_TYPES = "CAN_READ_TASK_TYPES";
export const CAN_VIEW_TASK_PAGE = "CAN_VIEW_TASK_PAGE";
export const CAN_READ_TASKS = "CAN_READ_TASKS";
export const CAN_MANAGE_TASKS = "CAN_MANAGE_TASKS";
export const CAN_VIEW_COSTS_PAGE = "CAN_VIEW_COSTS_PAGE";
export const CAN_READ_COSTS = "CAN_READ_COSTS";
export const CAN_MANAGE_COSTS = "CAN_MANAGE_COSTS";

export const CAN_READ_HEADQUARTERS = "CAN_READ_HEADQUARTERS";
export const CAN_MANAGE_HEADQUARTERS = "CAN_MANAGE_HEADQUARTERS";
export const CAN_READ_LOCATIONS = "CAN_READ_LOCATIONS";
export const CAN_MANAGE_LOCATIONS = "CAN_MANAGE_LOCATIONS";

export const CAN_VIEW_USER_PAGE = "CAN_VIEW_USER_PAGE";
export const CAN_READ_USERS = "CAN_READ_USERS";
export const CAN_MANAGE_USERS = "CAN_MANAGE_USERS";
export const CAN_ADMIN_USERS = "CAN_ADMIN_USERS";

export const CAN_READ_VEHICLE_TYPES = "CAN_READ_VEHICLE_TYPES";
export const CAN_READ_MAKES = "CAN_READ_MAKES";
export const CAN_MANAGE_MAKES = "CAN_MANAGE_MAKES";
export const CAN_READ_MODELS = "CAN_READ_MODELS";
export const CAN_MANAGE_MODELS = "CAN_MANAGE_MODELS";
export const CAN_READ_VERSIONS = "CAN_READ_VERSIONS";
export const CAN_MANAGE_VERSIONS = "CAN_MANAGE_VERSIONS";

export const CAN_VIEW_VEHICLE_PAGE = "CAN_VIEW_VEHICLE_PAGE";
export const CAN_READ_VEHICLES = "CAN_READ_VEHICLES";
export const CAN_MANAGE_VEHICLES = "CAN_MANAGE_VEHICLES";

export const CAN_VIEW_VEHICLE_USAGE_PAGE = "CAN_VIEW_VEHICLE_USAGE_PAGE";
export const CAN_READ_VEHICLE_USAGES = "CAN_READ_VEHICLE_USAGES";
export const CAN_MANAGE_VEHICLE_USAGES = "CAN_MANAGE_VEHICLE_USAGES";

export const CAN_VIEW_REPORT_PAGE = "CAN_VIEW_REPORT_PAGE";
export const CAN_VIEW_FLEET_LOCATION_PAGE = "CAN_VIEW_FLEET_LOCATION_PAGE";