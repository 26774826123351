import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PermissionSwitch } from '../permission-modal.component';
import * as PERMISSIONS from 'src/app/shared/constant/permissions';
import { PermissionModalUtilsService } from '../permission-modal-utils.service';
import { Permission } from 'src/app/shared/models/permission/permission';
import { SharedModule } from 'src/app/shared/shared.module';
import { ListSwitch } from '../../../list-switches/list-switches.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'permission-task-section-modal',
  standalone: true,
  imports: [
    SharedModule,
  ],
  templateUrl: './permission-task-section-modal.component.html',
  styleUrl: './permission-task-section-modal.component.scss'
})
export class PermissionTaskSectionModalComponent implements OnInit, OnDestroy {

  @Input() permissionName: string;
  @Input() permission: Permission;

  taskTypeSwitch: PermissionSwitch = {
    showSwitch: false,
    titleSwitch: 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_TASK_TYPES',
    listSwitch: [],
  }

  headquartersSwitch: PermissionSwitch = {
    showSwitch: false,
    titleSwitch: 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_HEADQUARTERS_OUTSIDE',
    listSwitch: [],
  }

  tagsSwitch: PermissionSwitch = {
    showSwitch: false,
    titleSwitch: 'USER_GROUPS.PERMISSIONS.MODAL.TITLE_TAGS',
    listSwitch: [],
  }

  taskWatcher: {
    isInterest: boolean;
    expr: string;
  } = {
      isInterest: false,
      expr: '',
    }

  taskAssignee: {
    isAssignee: boolean;
    expr: string;
  } = {
      isAssignee: false,
      expr: '',
    }

  expr: string;

  @Output() exprChange = new EventEmitter<string>();
  permissionsConst = PERMISSIONS;

  private _destroy = new Subject<void>();

  constructor(
    private _permissionModalUtilsService: PermissionModalUtilsService
  ) { }

  ngOnInit(): void {
    this.taskTypeSwitch.expr = this.permission?.expr ? this._permissionModalUtilsService.getCustomExpressionFromEq(this.permission.expr, 'TaskTypeId') : '';
    this._permissionModalUtilsService.getTaskTypes$({ expr: this.taskTypeSwitch.expr, name: this.permissionName } as Permission, 'TaskTypeId')
      .pipe(takeUntil(this._destroy))
      .subscribe(taskTypes => {
        this.taskTypeSwitch.listSwitch = taskTypes;
      });

    this.headquartersSwitch.expr = this.permission?.expr ? this._permissionModalUtilsService.getCustomExpressionFromEq(this.permission.expr, 'HeadquarterId') : '';
    this._permissionModalUtilsService.getHeadquarters$({ expr: this.headquartersSwitch.expr } as Permission, true, 'HeadquarterId')
      .pipe(takeUntil(this._destroy))
      .subscribe(headquarters => {
        this.headquartersSwitch.listSwitch = headquarters;
      });

    this.tagsSwitch.expr = this.permission?.expr ? this._permissionModalUtilsService.getCustomExpressionFromEq(this.permission.expr, 'TagId') : '';
    this._permissionModalUtilsService.getTags$({ expr: this.tagsSwitch.expr } as Permission, 'TagId')
      .pipe(takeUntil(this._destroy))
      .subscribe(tags => {
        this.tagsSwitch.listSwitch = tags;
      });

    this.taskWatcher.expr = this.permission?.expr ? this._permissionModalUtilsService.getCustomExpressionFromContains(this.permission.expr, 'TaskWatcherIds') : '';
    if (this.taskWatcher.expr !== null && this.taskWatcher.expr !== '') {
      this.taskWatcher.isInterest = true;
    }

    this.taskAssignee.expr = this.permission?.expr ? this._permissionModalUtilsService.getCustomExpressionFromEq(this.permission.expr, 'AssignedUserId') : '';
    if (this.taskAssignee.expr !== null && this.taskAssignee.expr !== '') {
      this.taskAssignee.isAssignee = true;
    }
  }

  onSwitchTaskTypesSelected(event: ListSwitch[]) {
    let checkIfAllSelected = event.every(sw => sw.checked);
    if (checkIfAllSelected) {
      this.taskTypeSwitch.expr = '';
      this.onExprChange();
      return;
    }

    let ids = event.filter(sw => sw.checked).map(sw => sw.id);
    this.taskTypeSwitch.expr = this._permissionModalUtilsService.createExpressionWithEq('TaskTypeId', ids);
    this.onExprChange();
  }

  onSwitchHeadquartersSelected(event: ListSwitch[]) {
    let checkIfAllSelected = event.every(sw => sw.checked);
    if (checkIfAllSelected) {
      this.headquartersSwitch.expr = '';
      this.onExprChange();
      return;
    }

    let ids = event.filter(sw => sw.checked).map(sw => sw.id);
    this.headquartersSwitch.expr = this._permissionModalUtilsService.createExpressionWithEq('HeadquarterId', ids);
    this.onExprChange();
  }

  onSwitchTagsSelected(event: ListSwitch[]) {
    let checkIfAllSelected = event.every(sw => sw.checked);
    if (checkIfAllSelected) {
      this.tagsSwitch.expr = '';
      this.onExprChange();
      return;
    }

    let ids = event.filter(sw => sw.checked).map(sw => sw.id);
    this.tagsSwitch.expr = this._permissionModalUtilsService.createExpressionWithEq('TagId', ids);
    this.onExprChange();
  }

  onClickTaskWatcherInterest() {
    this.taskWatcher.isInterest = !this.taskWatcher.isInterest;
    this.taskWatcher.expr = 'contains([TaskWatcherIds];"#MEID#")';
    this.onExprChange();
  }

  onClickTaskAssignee() {
    this.taskAssignee.isAssignee = !this.taskAssignee.isAssignee;
    this.taskAssignee.expr = 'eq([AssignedUserId];"#MEID#")';
    this.onExprChange();
  }

  onExprChange() {
    const andExpressions: string[] = [];
    const orExpressions: string[] = [];

    // Aggiungi le espressioni che devono essere incluse in un AND
    if (this.taskTypeSwitch.expr) {
      andExpressions.push(this.taskTypeSwitch.expr);
    }

    if (this.headquartersSwitch.expr) {
      andExpressions.push(this.headquartersSwitch.expr);
    }

    // Aggiungi le altre espressioni che rimangono nell'OR
    if (this.taskWatcher.isInterest) {
      orExpressions.push(this.taskWatcher.expr);
    }

    if (this.taskAssignee.isAssignee) {
      orExpressions.push(this.taskAssignee.expr);
    }

    if (this.tagsSwitch.expr) {
      orExpressions.push(this.tagsSwitch.expr);
    }

    // Costruisci l'espressione finale con controllo minimo due espressioni per AND/OR
    let finalExpr = '';

    const andExpr = andExpressions.length >= 2 ? `and(${andExpressions.join(';')})` : andExpressions[0] || '';
    const orExpr = orExpressions.length >= 2 ? `or(${orExpressions.join(';')})` : orExpressions[0] || '';

    if (andExpr && orExpr) {
      finalExpr = `or(${orExpr};${andExpr})`;
    } else if (andExpr) {
      finalExpr = andExpr;
    } else if (orExpr) {
      finalExpr = orExpr;
    }

    this.exprChange.emit(finalExpr);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

}