import { Injectable } from '@angular/core';
import { Booking } from '../models/booking/booking';
import { Observable, map, of } from 'rxjs';
import { TenantService } from '../api-services/tenant.service';
import { MakesService } from '../api-services/makes.service';

@Injectable({
  providedIn: 'root'
})
export class FringeBenefitUtilsService {

  tenantId = this._tenantService.getTenantId();

  constructor(
    private _tenantService: TenantService,
    private _makesService: MakesService
  ) { }

  calculateFringeBenefitMonth(costAci: number): number {
    let now = new Date();
    let daysOfThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    return (((costAci * 15000 * 0.3) * daysOfThisMonth) / 365);
  }

  calculateFringeBenefitYear(costAci: number): number {
    return (costAci * 15000 * 0.3);
  }

  setBadgeColor(booking: Booking): string {
    if (booking.status === 'OK' || booking.status === 'OK_ACTIVE') {
      return 'bg-azure';
    } else if (booking.status.split('_')[0] === 'CLOSED') {
      return 'bg-success';
    }
    return '';
  }

  getAciCost(versionId: string): Observable<number> {
    if (!versionId) {
      return of(0);
    }

    return this._makesService.getVersion$(this.tenantId, versionId).pipe(
      map(response => {
        return parseFloat(response.extraFields['AciCostoKm']);
      })
    )
  }
}
