export const STATUS_PENDING = "PENDING"; // richiede approvazione

export const STATUS_OK = "OK"; // e' corretta ma deve ancora iniziare data corrente < start date
export const STATUS_OK_ACTIVE = "OK_ACTIVE"; // prenotazione corrente: data corrente >= start date 
export const STATUS_OK_DELAYED = "OK_DELAYED"; // booking aperto in ritardo: data corrente >= start date ma data corrente >= end date e chiave non restituita

export const STATUS_REJECTED = "REJECTED"; // prenotazione rifiutata

export const STATUS_UNAVAILABLE_DISABLED = "UNAVAILABLE_DISABLED"; // veicolo non disponibile per la prenotazione che deve iniziare (major_level, disabled, non ritornato)
export const STATUS_UNAVAILABLE_LOCATION = "UNAVAILABLE_LOCATION";
export const STATUS_UNAVAILABLE_LEVEL = "UNAVAILABLE_LEVEL";
export const STATUS_UNAVAILABLE_EXCLUSIVE = "UNAVAILABLE_EXCLUSIVE";
export const STATUS_UNAVAILABLE_DUPLICATED = "UNAVAILABLE_DUPLICATED";
export const STATUS_UNAVAILABLE_USAGE = "UNAVAILABLE_USAGE"; // il veicolo non e' disponibile al momento della verifica ed eventuale presa
export const STATUS_UNAVAILABLE_FILTER = "UNAVAILABLE_FILTER";
export const STATUS_UNAVAILABLE_INVALID_VEHICLE = "UNAVAILABLE_INVALID_VEHICLE"; // veicolo non prenotabile perche' non piu' in flotta
export const STATUS_UNAVAILABLE_TO_BE_CHECKED = "UNAVAILABLE_TO_BE_CHECKED";

export const STATUS_CLOSED_END_DATE = "CLOSED_END_DATE"; // close when returned, untaken, oltre la data
export const STATUS_CLOSED_UNTAKEN = "CLOSED_UNTAKEN";
export const STATUS_CLOSED_RETURNED = "CLOSED_RETURNED";
export const STATUS_CLOSED_UNIQUE_USER = "CLOSED_UNIQUE_USER";
export const STATUS_CLOSED_INVALID_BOOKING_TYPE = "CLOSED_INVALID_BOOKING_TYPE";
export const STATUS_CLOSED_INVALID_USER = "CLOSED_INVALID_USER";
export const STATUS_CLOSED_DURATION_EXCEEDED = "CLOSED_DURATION_EXCEEDED";
export const STATUS_CLOSED_DELAYED = "CLOSED_DELAYED"; // booking chiuso con chiave restituita in ritardo
export const STATUS_CLOSED_CHECK_EXPR = "CLOSED_CHECK_EXPR"; // regola booking non rispettata
export const STATUS_CLOSED_DELETED = "CLOSED_DELETED"; // booking eliminato